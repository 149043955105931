<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M32.81 38.08V29.3536L31.1875 29.7136V28.8784L33.212 28H34.0304V38.08H32.81Z"
      class="fill-secondary"
    />
    <path
      d="M37.1427 38.08V29.3536L35.5202 29.7136V28.8784L37.5447 28H38.3631V38.08H37.1427Z"
      class="fill-secondary"
    />
    <path
      d="M39.7344 38.08L42.5607 33.993L39.7344 29.9059H41.2297L43.5138 33.2843L45.8143 29.9059H47.2932L44.4669 33.993L47.2932 38.08H45.8143L43.5138 34.7016L41.2297 38.08H39.7344Z"
      class="fill-secondary"
    />
  </svg>
</template>
