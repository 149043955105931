const _cypressEnv = (property) => {
  if (typeof Cypress === "undefined") return undefined;
  // eslint-disable-next-line
  return Cypress.env()[property];
};

const _env = (property) => process.env[property];

const getEnvValue = (property) => _env(property) || _cypressEnv(property);

const CDN_BASE = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com/${process.env.AWS_BASE_PATH}`;

const getBoolean = (property) => {
  const value = getEnvValue(property);
  return [true, "true", "1"].includes(value);
};

const config = {
  NODE_ENV: process.env.NODE_ENV,
  DEBUG: getBoolean("VUE_APP_DEBUG"),
  APP_VERSION: process.env.VUE_APP_VERSION,
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
  CONFIRMATION_TIMEOUT: parseInt(process.env.VUE_APP_CONFIRMATION_TIMEOUT, 10),
  ANCV_CONFIRMATION_TIMEOUT: parseInt(
    process.env.VUE_APP_ANCV_CONFIRMATION_TIMEOUT,
    10
  ),
  CHECK_STATUS_INTERVAL: parseInt(process.env.VUE_APP_CHECK_STATUS_INTERVAL, 10),
  APP_LOCAL: process.env.VUE_APP_LOCAL || false,
  IS_PRODUCTION: process.env.NODE_ENV === "production",
  IS_TEST: process.env.NODE_ENV === "test",
  CDN_BASE,
  THREATMETRIX_DOMAIN_NAME:
    process.env.VUE_THREATMETRIX_DOMAIN_NAME || "h.online-metrix.net",
  THREATMETRIX_ORG_ID: process.env.VUE_THREATMETRIX_ORG_ID || "6w4ciyve",
  THREATMETRIX_SCRIPT_NAME: process.env.VUE_THREATMETRIX_SCRIPT_NAME || "plgassets.js",
  VUE_APP_LOG_LEVEL: process.env.VUE_APP_LOG_LEVEL,
  SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN,
  TEST_CSS_FILENAMES: getEnvValue("TEST_CSS_FILENAMES"),
  TEST_SECRET: getEnvValue("TEST_SECRET"),
  MATOMO_ENABLED: getBoolean("VUE_APP_MATOMO_ENABLED"),
  MATOMO_HOST: process.env.VUE_APP_MATOMO_HOST,
  MATOMO_SITE_ID: process.env.VUE_APP_MATOMO_SITE_ID,
  MATOMO_MIN_TIME_ON_PAGE_IN_MS:
    parseInt(process.env.VUE_APP_MATOMO_MIN_TIME_ON_PAGE_IN_MS, 10) || 100,
  MATOMO_DIMENSION_ID_IFRAME: process.env.VUE_APP_MATOMO_DIMENSION_ID_IFRAME,
  MATOMO_DIMENSION_ID_PAYMENT_SUCCESS:
    process.env.VUE_APP_MATOMO_DIMENSION_ID_PAYMENT_SUCCESS,
  REDIRECT_DELAY_IN_MS: parseInt(process.env.VUE_APP_REDIRECT_DELAY_IN_MS, 10) || 10000,
  REDIRECT_NOT_EXECUTED_DELAY_IN_MS:
    parseInt(process.env.VUE_APP_REDIRECT_NOT_EXECUTED_DELAY_IN_MS, 10) || 15000,
  UPDATE_PAYMENT_METHOD_DISABLED: getBoolean("VUE_APP_UPDATE_PAYMENT_METHOD_DISABLED"),
  JWT_FLASK_SECRET: getEnvValue("JWT_FLASK_SECRET"),
};

export default config;
