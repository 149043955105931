import { Logger } from "../utils/logger";

const unavailableSessionStoragePrefix = "pledg-alternative-storage-";

const SESSION_STORAGE = "sessionStorage";

export const Storage = (storageType) => ({
  set: (key, value) => {
    try {
      window[storageType].setItem(key, value);
    } catch (error) {
      Logger.error({ id: "set-storage-error", key, value, error });
      window[`${unavailableSessionStoragePrefix}${key}`] = value;
    }
  },
  get: (key) => {
    try {
      return window[storageType].getItem(key);
    } catch (error) {
      Logger.error({ id: "get-storage-error", key, error });
      return window[`${unavailableSessionStoragePrefix}${key}`];
    }
  },
});

export const SessionStorage = {
  set: (key, value) => Storage(SESSION_STORAGE).set(key, value),
  get: (key) => Storage(SESSION_STORAGE).get(key),
};

function _key(key, type) {
  return `${key}-${type}`;
}

function getParsedData(storageData) {
  let data = {};
  try {
    data = JSON.parse(storageData);
  } catch (error) {
    data = storageData || {};
  }
  return data;
}

function stringifyData(data) {
  const data_string = typeof data === "string" ? data : JSON.stringify(data);
  return data_string;
}

export function getInformations(key) {
  if (!key) return {};
  const rawData = SessionStorage.get(_key(key, "informations"));
  return getParsedData(rawData);
}

export function setInformations(key, value) {
  SessionStorage.set(_key(key, "informations"), stringifyData(value));
}
