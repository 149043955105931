import { schema } from "normalizr";

const accountSchema = new schema.Entity(
  "accounts",
  {},
  {
    idAttribute: "uid",
  }
);
const companySchema = new schema.Entity(
  "companies",
  {},
  {
    idAttribute: "uid",
  }
);

const merchantSchema = new schema.Entity(
  "merchants",
  {},
  {
    idAttribute: "uid",
  }
);

const purchaseSchema = new schema.Entity(
  "purchases",
  {},
  {
    idAttribute: "uid",
  }
);

const shareSchema = new schema.Entity(
  "shares",
  {},
  {
    idAttribute: "uid",
  }
);

const orderSchema = new schema.Entity(
  "orders",
  {},
  {
    idAttribute: "uid",
  }
);

accountSchema.define({});

merchantSchema.define({});

purchaseSchema.define({
  shares: [shareSchema],
  merchant: merchantSchema,
});

shareSchema.define({
  account: accountSchema,
});

companySchema.define({
  merchants: [merchantSchema],
});

orderSchema.define({
  merchant: merchantSchema,
});

export {
  accountSchema,
  merchantSchema,
  purchaseSchema,
  shareSchema,
  companySchema,
  orderSchema,
};
