import httpService from "./httpService";

const ENDPOINTS = {
  merchant: (merchantUid) => `merchants/${merchantUid}`,
  merchantPurchases: (merchantUid) => `merchants/${merchantUid}/purchases`,
  merchantOrders: (merchantUid) => `merchants/${merchantUid}/orders`,
  merchantFipen: (merchantUid) => `merchants/${merchantUid}/fipen`,
};

async function fetchMerchant(merchantUid, params) {
  const url = ENDPOINTS.merchant(merchantUid);
  return httpService.get(url, params);
}

async function createPurchase(merchantUid, params = {}, bodyData = {}) {
  const url = ENDPOINTS.merchantPurchases(merchantUid);
  return httpService.post(url, params, bodyData);
}

async function createOrder(merchantUid, params = {}, bodyData = {}) {
  const url = ENDPOINTS.merchantOrders(merchantUid);
  return httpService.post(url, params, bodyData);
}

async function fetchFipen(merchantUid, params, options) {
  const url = ENDPOINTS.merchantFipen(merchantUid);
  return httpService.get(url, params, {}, options);
}

const MerchantService = {
  fetchMerchant,
  createPurchase,
  createOrder,
  fetchFipen,
};

export default MerchantService;
