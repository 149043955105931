export const ACCOUNT = "account";
export const MERCHANT = "merchant";
export const PURCHASE = "purchase";
export const SHARE = "share";
export const FUNNEL = "funnel";
export const COMPANY = "company";
export const ORDER = "order";
export const ERROR = "error";

export default {
  ACCOUNT,
  COMPANY,
  MERCHANT,
  PURCHASE,
  SHARE,
  FUNNEL,
  ORDER,
  ERROR,
};
