export const getMessage = (message) => {
  if (Array.isArray(message)) return message.join(" | ");
  if (message instanceof Error) return message.message;
  if (typeof message === "object") return JSON.stringify(message, null, 1);

  const returnMessage = message || "";

  return `${returnMessage}`;
};

export const detectNode =
  Object.prototype.toString.call(typeof process !== "undefined" ? process : 0) ===
    "[object process]" || process.env.APPLICATION_ENV === "production";

export const mountLog = (name, style) => {
  if (detectNode) {
    return [`${name}: `];
  }

  return [`%c${name}%c:`, style, "background: transparent;"];
};
