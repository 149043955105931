import { normalize } from "normalizr";
import _merge from "lodash.merge";
import { companySchema } from "@/store/schemas.js";
import * as types from "@/store/mutation-types";
import { MERCHANT } from "@/store/namespaces";
import CompanyService from "../../api/companyService";

// Initial state
const state = {
  all: {},
};

// Getters
const getters = {
  allCompanies: (companyState) => companyState.all,
  company: (companyState, companyGetters, rootState, rootGetters) => {
    return companyGetters.allCompanies[rootGetters.rawCompanyUid];
  },
  companyOperator: (companyState, companyGetters) =>
    companyGetters.company?.branding?.toUpperCase(),
  companyFolderName: (companyState, companyGetters) =>
    companyGetters.company?.folder_name,
  companyCSSUrl: (companyState, companyGetters) => companyGetters.company?.css_url,
  companyPictureUrl: (companyState, companyGetters) =>
    companyGetters.company?.picture_url,
  enableMissingInformationScreen: (companyState, companyGetters) =>
    companyGetters.company?.enable_front_missing_information_screen,
  paymentFunnelUid: (companyState, companyGetters) =>
    companyGetters.company?.payment_funnel_uid,
  companyDataPolicyUrl: (companyState, companyGetters) =>
    companyGetters.company?.data_policy_url,
};

// Actions
const actions = {
  async fetchCompanyAction({ commit }, { companyUid }) {
    const response = await CompanyService.fetchCompany(companyUid);
    if (response?.data) {
      const { entities, result } = normalize(response.data, companySchema);
      commit(types.COMPANY_GET_SUCCESS, { entities, result });
      commit(types.RAW_SET_COMPANY_UID, { companyUid }, { root: true });
    }
  },

  async fetchCompanySimulationsAction({ commit, rootGetters }, { companyUid }) {
    const response = await CompanyService.fetchCompanySimulations(companyUid, {
      iframe_id: rootGetters.rawIframeId,
      pledg_env: rootGetters.rawPledgEnv,
      amount_cents: rootGetters.rawAmountCents,
      second_installment_payment_date: rootGetters.rawSecondInstallmentPaymentDate,
      deferred_payment_date: rootGetters.rawDeferredPaymentDate,
      payment_funnel_uid: rootGetters.rawPaymentFunnelUid,
    });
    if (response?.data) {
      const { entities, result } = normalize(response.data, companySchema);
      commit(types.COMPANY_GET_SUCCESS, { entities, result });
      commit(
        `${MERCHANT}/${types.MERCHANT_GET_SUCCESS}`,
        { entities, result },
        { root: true }
      );
      commit(types.RAW_SET_COMPANY_UID, { companyUid }, { root: true });
    }
  },
};

// Mutations
const mutations = {
  [types.COMPANY_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.companies);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
