import httpService from "./httpService";
import { OrderStates } from "../constants";

const ENDPOINTS = {
  order: (orderUid) => `orders/${orderUid}`,
  orderDashboard: (orderUid) => `dashboard/orders/${orderUid}`,
  checkout: (orderUid) => `orders/${orderUid}/checkout`,
  status: (orderUid) => `orders/${orderUid}/status`,
  cancelSignature: (orderUid) => `orders/${orderUid}/cancel_signature`,
};

async function fetchOrder(orderUid, isDashboardBuyer) {
  const url = isDashboardBuyer
    ? ENDPOINTS.orderDashboard(orderUid)
    : ENDPOINTS.order(orderUid);
  return httpService.get(url);
}

async function getStatus(orderUid) {
  const url = ENDPOINTS.status(orderUid);
  return httpService.get(url, {});
}

async function cancelSignature(orderUid, data) {
  const url = ENDPOINTS.cancelSignature(orderUid);
  return httpService.post(url, null, data);
}

async function updateToStarted(orderUid) {
  const url = ENDPOINTS.order(orderUid);
  return httpService.put(url, null, { state: OrderStates.STARTED });
}

const OrderService = {
  fetchOrder,
  getStatus,
  cancelSignature,
  updateToStarted,
};

export default OrderService;
