// eslint-disable-next-line no-useless-escape
const emailRegex = /^[a-z0-9_\+\-\.]{2,}@[a-z0-9_\-\.]{2,}\.[a-z]{2,}$/i;
const phoneRegex = /^\+?\d{6,15}$/;
const internationalFrenchNumberRegex = /^(|00|\+?)33[1-9]\d{8}$/;
const nationalFrenchNumberRegex = /^0[1-9]\d{8}$/;

export function validateEmail(email) {
  if (!email || !email.length) {
    return undefined;
  }

  return emailRegex.test(email);
}

export function validatePhoneNumber(inputPhone, country_code) {
  if (!inputPhone || !inputPhone.length) {
    return undefined;
  }
  const phone = inputPhone.replace(/ /g, "");

  // We make a more strict verification for French mobile numbers
  if (internationalFrenchNumberRegex.test(phone)) {
    return true;
  }

  if (phone.startsWith("+33")) {
    return internationalFrenchNumberRegex.test(phone);
  }

  if (phone.startsWith("0") && country_code === "FR") {
    return nationalFrenchNumberRegex.test(phone);
  }

  return phoneRegex.test(phone);
}

export function validateDestination(destination, country_code) {
  return validateEmail(destination) || validatePhoneNumber(destination, country_code);
}

export function validateAmount(amount) {
  if (!amount) {
    return undefined;
  }

  const amountStr = amount.toString();
  if (!amountStr.length) {
    return undefined;
  }

  if (Number.isNaN(Number(+amountStr))) {
    return false;
  }

  if (+amountStr <= 0) {
    return false;
  }
  return true;
}
