import i18n from "@/plugins/i18n";

class DataError extends Error {
  constructor(message, info) {
    super(message);
    this.data = { error: { info } };
    this.errorHuman = info || i18n.global.t("genericError");
  }
}

export default DataError;
