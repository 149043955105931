/* eslint-disable no-console */
import { LogLevelStyle, LogLevel } from "./constants";
import { mountLog } from "./utils";

export function makeMessageStyle(logLevel) {
  switch (logLevel) {
    case LogLevel.ERROR:
      return mountLog("[error]", LogLevelStyle.ERROR);
    case LogLevel.INFO:
      return mountLog("[info]", LogLevelStyle.INFO);
    case LogLevel.WARN:
      return mountLog("[warn]", LogLevelStyle.WARN);
    case LogLevel.DEBUG:
      return mountLog("[debug]", LogLevelStyle.LOG);
    case LogLevel.NONE:
    default:
      return mountLog("[-]", LogLevelStyle.LOG);
  }
}

/**
 * Apply a specific style to display log message in the console depending on log level
 * @param {string} logLevel the defined log level among LogLevel
 * @param {Function} fn the (console) function to update
 * @returns the function updated
 */
function makeMethod(logLevel, fn) {
  return () => {
    return Function.prototype.bind.apply(fn, [console, ...makeMessageStyle(logLevel)]);
  };
}

const defaultLogger = {
  debug: makeMethod(LogLevel.DEBUG, console.debug)(),
  info: makeMethod(LogLevel.INFO, console.info)(),
  warn: makeMethod(LogLevel.WARN, console.warn)(),
  error: makeMethod(LogLevel.ERROR, console.error)(),
};

export default defaultLogger;
