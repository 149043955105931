export const StripeErrors = {
  INTEGRATION_ERROR: "IntegrationError",
};

// https://stripe.com/docs/error-codes
export const StripeErrorCodes = {
  // The card has been declined. When a card is declined,
  // the error returned also includes the decline_code attribute
  // with the reason why the card was declined.
  CARD_DECLINED: "card_declined",
  // The card has expired. Check the expiration date or use a different card.
  EXPIRED_CARD: "expired_card",
  // The card’s security code is incorrect. Check the card’s security code or use a different card.
  INCORRECT_CVC: "incorrect_cvc",
  // An error occurred while processing the card. Try again later or with a different payment method.
  PROCESSING_ERROR: "processing_error",
  // The card number is incorrect. Check the card’s number or use a different card.
  INCORRECT_NUMBER: "incorrect_number",
  // The provided payment method has failed authentication. Provide a new payment method to attempt to fulfill this PaymentIntent again.
  PAYMENT_INTENT_AUTH_FAILURE: "payment_intent_authentication_failure",
  INVALID_OWNER_NAME: "invalid_owner_name",
};

// https://stripe.com/docs/api/errors
export const StripeErrorTypes = {
  /**
   * API errors cover any other type of problem (e.g., a temporary
   * problem with Stripe's servers), and are extremely uncommon.
   */
  API_ERROR: "api_error",
  /**
   * Network error
   */
  API_CONNECTION_ERROR: "api_connection_error",
  /**
   * Card errors are the most common type of error you should expect
   * to handle. They result when the user enters a card that can't be
   * charged for some reason.
   */
  CARD_ERROR: "card_error",
  /**
   * Idempotency errors occur when an Idempotency-Key is re-used on a
   * request that does not match the first request's API endpoint
   * and parameters.
   */
  IDEMPOTENCY_ERROR: "idempotency_error",
  /**
   * Invalid request errors arise when your request has invalid parameters.
   */
  INVALID_REQUEST_ERROR: "invalid_request_error",
  VALIDATION_ERROR: "validation_error",
};

export const CardNetworks = {
  CB: "cartes_bancaires",
  VISA: "visa",
  MASTERCARD: "mastercard",
  AMEX: "amex",
};

export const PREFERRED_NETWORK = CardNetworks.CB;

export const PspPaymentMethods = {
  CARD: "card",
  SEPA_DEBIT: "sepa_debit",
  SOFORT: "sofort",
};
