// module.exports = {
export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Payer avec votre compte Chèque-Vacances Connect",
  try_again: "Le paiement par Chèque-Vacances a échoué. Veuillez réessayer.",
  important: "Important : ",
  ancv_app_mandatory:
    "l'application Chèque-Vacances est nécessaire pour valider ce paiement",
  ancv_id: "Votre identifiant Chèque-Vacances Connect",
  open_ancv_app:
    "Ouvrez votre application ANCV Chèque-Vacances afin de finaliser le processus de paiement.",
  transaction_success: "Votre transaction a bien été enregistrée.",
  transaction_infos_below:
    "Vous trouverez ci-dessous les informations relatives à votre paiement.",
  see_you: "A bientôt,",
  ancv_signature: "L'équipe de l'Agence Nationale pour les Chèque-Vacances.",
  ancv_amount: "Montant reçu",
  total_amount: "Montant demandé",
  ancv_transaction_id: "Référence de la transaction",
  remaining_amount: "Restant dû",
  excluding_fees: "hors frais de financement",
  installment_cta: "Payer le reste dû en {installments_nb}x par CB",
  deferred_cta: "Payer plus tard le reste dû par CB",
  infos_ancv_and_bnpl:
    "Une fois le montant à payer en Chèque-Vacances validé à l’aide de l’application Chèque-Vacances, un échéancier de paiement en plusieurs fois basé sur le montant restant à payer par carte bancaire vous sera proposé.",
  warning_delay_bnpl:
    "Vous disposez de {delayInMinutes} minutes pour finaliser votre paiement. Passé ce délai, le paiement en Chèque-Vacances et votre réservation seront annulés.",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "Je saisis mes coordonnées bancaires",
  amount_to_pay: "Montant à payer",
  total_purchase_amount: "Montant total",
  split_explanation:
    "Si tous les participants paient leur part dans les 48h, je paierai ",
  i_pay_my_share: "Je paye ma part",
  i_pay_my_installment: "Je paye ma mensualité",
  anticipated_payment_warning:
    "Vous vous apprêtez à régler l'échéance du <b>{date}</b> soit <b>{days} jours</b> avant la date initialement convenue",
  i_change_my_payment_method: "Mise à jour de mon moyen de paiement",
  payment_method_fingerprint_amount:
    "Une empreinte de votre carte bancaire d'un montant de {amount} peut apparaître sur votre compte. Celle-ci est temporaire et aucun montant ne vous est prélevé.",
  card_number: "Numéro de la carte",
  expiration_date: "Date d'expiration",
  security_code: "Code de sécurité",
  card_network: "Réseau de carte",
  notice_payment: "la notice d'utilisation du service de paiement",
  accept_cta_installment:
    "En cliquant sur Valider, j'accepte {0}. Enfin, je certifie que la carte utilisée pour payer mon achat est bien rattachée à un compte ouvert à mon nom.",
  split_default_message:
    "Je vais recevoir une demande d'autorisation du montant total. Aucune somme ne sera débitée. Mes amis ont 48h pour payer. Passé ce délai, si l'un d'eux ne paie pas, je serai débité de sa part.",
  secured_payment: "Paiement sécurisé",
  deadline_on: "paiement le",
  with_fees_amount: "(dont {amount} de frais)",
  basket_amount: "Montant panier :",
  ill_pay_later: "Je paierai le {date}",
  ill_pay_later_on_delivery: "Je paierai à la livraison (et au plus tard le {date})",
  penalties_detail: "dont {penalties_percentage}% de pénalités de retard : {amount}",
  not_supported_cards:
    "Les cartes prépayées, à autorisation systématique telles que Electron, Maestro, Compte Nickel, Revolut, et les cartes virtuelles ou ecards ne sont pas acceptées.",
  payment_method_issue:
    "Une erreur s'est produite lors de l'utilisation de votre moyen de paiement. Merci de vous rapprocher du support du site marchand.",
  order_info_multiple_shipment:
    "La commande peut faire l'objet de plusieurs envois. Chaque envoi aura son propre échéancier qui se déclenchera au moment de l'expédition.",
  deposit_deadline_on: "Acompte le",
  balance_deadline_on: "Solde le",
  balance_to_pay_later: "Je paierai le solde le {date}",
  test_another_card: "Essayez une autre carte.",
  entry_error: "Erreur de saisie.",
  card_declined: "Votre carte a été refusée.",
  invalid_iban: "Iban rentré est incorrect.",
  stripe_library_not_loaded: "La librairie Stripe V3 n'a pas été chargée",
  taeg: "Vous souscrivez à un financement sur {num_of_days} jours, qui donnera lieu à {amount} de frais, soit un TAEG fixe de {taeg} %.",
  amount_paid_in_ancv: "Montant payé en Chèque-Vacances",
  advertisement_optin:
    "Je souhaite recevoir les communications commerciales de {optin_advertiser}",
  electronic_optin:
    "J'accepte de recevoir par voie électronique des offres commerciales du prêteur CA Consumer Finance",
  phone_mail_optout:
    "Je ne désire pas être sollicité dans le cadre de prospection et d'animation commerciale par voie postale ou téléphonique par le prêteur CA Consumer Finance",
  contract_message_start: "J’accepte les ",
  contract_message_link_installment:
    "informations pré-contractuelles et contractuelles du paiement en plusieurs fois ",
  contract_message_link_deferred:
    "informations pré-contractuelles et contractuelles du paiement différé ",
  contract_message_end:
    "et je certifie que la carte utilisée pour payer mon achat est bien rattachée à un compte ouvert à mon nom.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Ajouter",
  me: "Moi",
  friend: "Ami",
  submit: "Valider",
  yes: "Oui",
  no: "Non",
  update: "Modifier",
  apply: "Appliquer",
  see_faq: "Consultez notre FAQ pour en savoir +",
  secure: "100% sécurisé (SSL)",
  unknown: "Inconnu",
  on_date: "Le {date}",
  footer_question_to_merchant:
    "Pour toute demande relative à votre achat, merci de contacter directement {merchantName}.",
  footer_question_to_pledg:
    'Pour toute information relative à votre financement, consultez notre <a href="{faqUrl}" target="_blank">FAQ</a> ou écrivez-nous à l’adresse <a href="mailto:{contactEmail}">{contactEmail}</a>.',
  notice_access_link: "Notice d'utilisation du service de paiement",
  data_policy_link:
    "<a href='{dataPolicyUrl}' target='_blank'>Politique de confidentialité des données</a>",
  data_policy:
    "Mes données seront utilisées dans le cadre d'un traitement entièrement automatisé permettant l'octroi d'un financement : <a href='{dataPolicyUrl}' target='_blank'>politique de confidentialité des données</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Contacter le service de paiement</a>",
  no_information: "Aucune information fournie",
  no_psp_source: "Aucun moyen de paiement disponible",
  redirected_from_message:
    "{merchantName} vous a redirigé chez {operatorTeamName} pour finaliser votre paiement.",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Vous pouvez consulter la notice du service de paiement",
  error_invalid_contact: "Le contact est invalide",
  fees_amount: "(Frais: {amount} {currency})",
  here: "ici",
  deferred_intro:
    "Statut de votre achat différé du {date} à {hour} (heure de {timezone_city}) sur {merchantName}.",
  installment_intro:
    "Statut de votre achat fractionné du {date} à {hour} (heure de {timezone_city}) sur {merchantName}.",
  split_intro:
    "Statut de votre achat partagé du {date} à {hour} (heure de {timezone_city}) sur {merchantName}.",
  penalties_amount: "(Pénalités: {amount} {currency})",
  proceed_payment_late: "Régulariser ma situation: {amount} {currency}",
  proceed_payment_in_advance:
    "Payer ma prochaine échéance en avance: {amount} {currency}",
  dashboard_buyer_your_purchase: "Votre achat",
  dashboard_buyer_your_basket: "Votre panier",
  dashboard_buyer_your_fees: "Frais de financement ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "Vos paiements",
  dashboard_buyer_overdue_payment: "Régularisation de votre situation",
  dashboard_buyer_overdue_payment_text:
    "Le paiement du {date} a échoué. Merci de procéder à la régularisation de votre situation.",
  dashboard_buyer_your_payment_method: "Votre moyen de paiement",
  dashboard_buyer_your_payment_method_expired_soon:
    "Votre moyen de paiement arrive bientôt à expiration. Mettez-le à jour pour éviter tout incident de paiement.",
  dashboard_buyer_your_payment_method_text_installment:
    "Vos prélèvements sont réalisés aux dates convenues grâce au moyen de paiement suivant:",
  dashboard_buyer_your_payment_method_text_deferred:
    "Le montant dû sera prélevé à la date convenue, en utilisant le moyen de paiement suivant: ",
  dashboard_buyer_your_payment_method_button: "Modifier mon moyen de paiement",
  dashboard_buyer_products: "Vos produits",
  dashboard_buyer_see_schedule: "Voir l'échéancier",
  dashboard_buyer_overdue: "Défaut de paiement",
  dashboard_buyer_share_captured_amount: "Payé:",
  dashboard_buyer_share_not_charged_amount: "Annulé:",
  dashboard_buyer_share_refunded_amount: "Remboursé:",
  dashboard_buyer_share_outstanding_amount: "Restant à payer:",
  dashboard_buyer_title_installment:
    'Votre <span class="text-secondary">paiement en {installmentsNb} fois</span> chez {merchantName}',
  dashboard_buyer_title_deferred:
    'Votre <span class="text-secondary">paiement différé</span> chez {merchantName}',
  dashboard_buyer_title_down_payment:
    'Votre <span class="text-secondary">paiement avec acompte</span> chez {merchantName}',
  dashboard_buyer_title_split:
    'Votre <span class="text-secondary">paiement partagé</span> chez {merchantName}',
  dashboard_buyer_status_total_refund:
    "Votre achat a été remboursé en accord avec les conditions générales de {merchantName} et du service de paiement.",
  dashboard_buyer_status_split_waiting_payment:
    "Les participants n'ont pas tous réglé leur part.",
  dashboard_buyer_status_fully_paid:
    "Votre achat a été entièrement payé. À bientôt chez {merchantName}.",
  dashboard_buyer_status_overdue:
    "Le paiement a échoué. Régularisez votre situation ou contactez le service de paiement au plus vite.",
  dashboard_buyer_status_ongoing:
    "Le paiement de votre achat se déroule normalement, aucune action de votre part n'est requise.",
  dashboard_buyer_status_psp_source_expired:
    "Votre moyen de paiement arrive à expiration. Mettez-le à jour pour poursuivre le paiement de vos échéances.",
  resend_message: "Renvoyer le message",
  split_leader_label: "Vous",
  state_leader_not_paid: "En attente",
  state_expired_not_paid: "Paiement échoué, à régulariser",
  state_future_not_paid: "Prélèvement automatique à échéance",
  state_preauthorization_ok: "Empreinte bancaire",
  state_sepa_debit_pending: "Débit SEPA en attente de validation",
  state_sofort_pending: "Paiement Sofort en attente",
  state_ancv_pending_transfer: "En attente de validation",
  state_ancv_processing: "En attente de paiement par Chèques-Vacances",
  state_ancv_preauthorization_ok: "Payé par Chèques-Vacances",
  state_payment_ok: "Payé",
  state_paid_by_leader: "Payé par vous",
  state_unknown: "En attente de paiement",
  state_fully_refunded: "Remboursé",
  state_fully_not_charged: "Annulé",
  split_explanation_1:
    "Chaque participant a jusqu'au {date} à {hour} (heure de {timezone_city}) pour payer sa part. Passé ce délai, vous serez débité du montant de votre part ({amount} {currency}) ainsi que des parts non payées.",
  dashboard_order_link: "Retour à la commande",
  header_amount: "Montant",
  header_participants: "Participants",
  header_status: "Statut",
  error_dashboard_title: "Dashboard indisponible",
  error_dashboard_subtitle: "Le récapitulatif de votre achat ne peut être affiché.",
  error_dashboard_reason_title: "Pour quelle raison ?",
  error_dashboard_reason_1: "L’achat en question n’est peut être pas disponible",
  error_dashboard_reason_1_details:
    "Ce dashboard n’est pas disponible pour les achats en cours de création ou annulés.",
  error_dashboard_reason_2: "Cette page a peut-être expiré",
  error_dashboard_reason_2_details:
    "Pour des raisons de sécurité, cette page est soumise à une expiration. Merci de fermer la page et cliquer de nouveau sur le lien d’origine.",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "Aucun achat associé à cette URL.",
  genericError:
    "Une erreur est survenue. Si l'erreur se reproduit contactez-nous sur contact{'@'}pledg.co.",
  error_infra_unavailable:
    "Le service est actuellement indisponible. Merci de recharger la page ou de réessayer plus tard.",
  notAddedValidDestinationWarning:
    "Veuillez cliquer sur le bouton '+ Ajouter' pour ajouter votre ami(e).",
  invalidDestination: "Cet email/numéro de mobile n’est pas valide\u00A0!",
  alreadyUsedDestination: "Cet email/numéro de mobile est déjà utilisé\u00A0!",
  invalidAmount: "Ce montant n’est pas valide\u00A0!",
  closingWarning: "Voulez-vous vraiment fermer cette fenêtre\u00A0?",
  singleShareWarning: "Voulez-vous vraiment payer tout seul\u00A0?",
  Confirmation3DSTimeoutError: "L'authentification a échoué. Merci de réessayer.",
  ancvConfirmationTimeoutError:
    "Erreur lors du paiement avec Chèques-Vacances. Merci de réessayer.",
  requiredPropertyMissingOrInvalid:
    "Le paramètre obligatoire {property} est manquant ou invalide",
  requiredPropertyMissing: "Le paramètre obligatoire {property} est manquant",
  propertyInvalid: "Le paramètre {property} est invalide",
  paymentRefused: "Le paiement a été refusé",
  errorHappened:
    "<strong>Demande de financement refusée, veuillez utiliser un autre moyen de paiement.</strong>",
  errorAmountHold:
    "En fonction de votre banque, un débit peut apparaître sur votre compte. Celui-ci est temporaire et aucun montant ne vous est prélevé.",
  retry_error: "Demande de financement refusée.",
  ineligibilityPaymentMethod: "Veuillez utiliser un autre moyen de paiement.",
  ineligibilityPaymentMethodRedirect:
    "Vous allez être redirigé afin de choisir un autre moyen de paiement.",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "Vous allez être redirigé sur le site de {merchantName} afin de choisir un autre moyen de paiement.",
  ineligibilityErrorContentWithMerchant:
    "Sur la base des informations transmises par {merchantName} ou recueillies directement par le prestataire de paiement et suite à un processus de décision entièrement automatisé, votre demande ne répond pas aux critères d'éligibilité de ce financement. Cette décision ne présage pas du résultat d'éventuelles demandes de financements futures et vous pouvez nous joindre en écrivant à {infoEmail} pour en savoir plus.",
  ineligibilityErrorContent:
    "Sur la base des informations recueillies directement par le prestataire de paiement et suite à un processus de décision entièrement automatisé, votre demande ne répond pas aux critères d'éligibilité de ce financement. Cette décision ne présage pas du résultat d'éventuelles demandes de financements futures et vous pouvez nous joindre en écrivant à {infoEmail} pour en savoir plus.",
  provideMerchantUid: "Merci de fournir l'uid marchand",
  invalidPhoneNumber: "Ce numéro de téléphone n'est pas valide.",
  IdentificationConfirmationTimeoutError:
    "Une erreur est survenue pendant la vérification de l'identité. Si l'erreur se reproduit contactez-nous sur {contactEmail}.",
  splitIncorrectAmounts: "Les montants renseignés semblent incorrects.",
  fipenDownloadError:
    "Une erreur s'est produite lors du téléchargement de vos informations pré-contractuelles. Merci de réessayer plus tard.",
  contractDownloadError:
    "Une erreur s'est produite lors du téléchargement de vos informations contractuelles. Merci de réessayer plus tard.",
  contractSignError:
    "Une erreur s'est produite lors de la signature de votre contrat. Merci de réessayer plus tard.",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Vérification d'identité",
  identification_success: "La vérification est validée",
  identification_failure: "La vérification a échoué",
  identification_processing: "La vérification est en cours",
  identity_check_warning_message:
    "Vous êtes sur le point d'être redirigé pour vérifier votre identité.",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Adresse (Numéro, Rue etc...)",
  address_zipcode: "Code postal",
  civility: "Civilité",
  birth_last_name: "Nom de naissance",
  madam: "Madame",
  mister: "Monsieur",
  address_city: "Ville",
  address_province: "Région",
  address_country: "Pays",
  birth_date_with_format: "Date de naissance (YYYY-MM-DD)",
  birth_city: "Ville de naissance",
  birth_country: "Pays de naissance",
  nationality: "Nationalité",
  phone_number: "Numéro de téléphone",
  missing_information_title: "Nous avons besoin d’informations complémentaires",
  missing_information_subtitle:
    "Pour donner suite à votre demande de financement, nous avons besoin de plus d'informations. ",
  missing_information_subtitle_payer: "Saisissez vos informations de facturation",
  missing_address_section: "Adresse postale",
  missing_phone_number_section: "Informations de contact",
  missing_birthday_section: "Date de naissance",
  missing_civility_explanation:
    "Civilité telle qu'elle apparaît sur votre pièce d'identité (nous avons l'obligation de vous la demander).",
  missing_birthplace_section: "Lieu de naissance",
  firstname: "Prénom",
  lastname: "Nom",
  email: "Email",
  day: "Jour",
  month: "Mois",
  year: "Année",
  fipen_text: "Vous pouvez dès à présent télécharger ",
  fipen_link: "vos informations pré-contractuelles.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "Nous avons besoin de quelques informations complémentaires",
  subtitle_revenue_1: "Pour calculer mon reste à vivre mensuel,",
  subtitle_revenue_2: "je renseigne mes revenus et mes charges.",
  remaining_budget_text: "Après avoir réglé mes charges, il me reste :",
  declared_revenue: "Revenus nets après impôts",
  declared_expenditure: "Charges récurrentes",
  declared_csp: "Catégorie socio-professionnelle",
  tooltip_revenue: "Salaire net, allocations, revenus fonciers...",
  tooltip_expenditure: "Loyer, crédits...",
  bgv_text:
    "Les informations financières renseignées ne nous permettent pas de vous octroyer le financement. En effet, recourir à ce financement risquerait de vous mettre en situation de surendettement. Pour mieux comprendre la situation, connectez-vous à la Web App BGV (Budget Grande Vitesse) proposée par notre partenaire Crésus en cliquant sur le lien : ",
  back_to_merchant: "J'ai compris, retour au site marchand",
  professional_categories: [
    "Agriculteurs",
    "Artisans",
    "Commerçants et assimilés",
    "Chefs d’entreprise de plus de 10 personnes",
    "Professions libérales",
    "Cadres de la fonction publique",
    "Professeurs et professions scientifiques",
    "Professions de l’information, de l’art et des spectacles",
    "Cadres administratifs et commerciaux",
    "Cadres techniques d’entreprise",
    "Professions de l’enseignement primaire et professionnel",
    "Intermédiaires de la santé et du travail social",
    "Religieux",
    "Intermédiaires de la fonction publique",
    "Intermédiaires des entreprises",
    "Techniciens",
    "Agents de maîtrise de production",
    "Employés de la fonction publique",
    "Policiers, militaires et agents de sécurité privée",
    "Employés administratifs d'entreprise",
    "Employés de commerce",
    "Personnels des services aux particuliers",
    "Ouvriers qualifiés de type industriel",
    "Ouvriers qualifiés de type artisanal",
    "Conducteurs du transport",
    "Conducteurs d’engins et magasiniers",
    "Ouvriers peu qualifiés de type industriel",
    "Ouvriers peu qualifiés de type artisanal",
    "Ouvriers agricoles",
    "Retraités",
    "Inactifs divers - autre que retraité",
  ],
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "Quels sont les amis avec qui je partage ma commande\u00A0?",
  subtitle_addfriends: "J'ajoute l'email ou le numéro de mobile de mes amis",
  placeholder_friend_email_phone_number: "Email ou numéro de mobile de mon ami(e)",
  attendees: "Pas de participants | 1 participant | {count} participants",
  pay_alone: "Je veux payer tout seul",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Mes options de paiement",
  payment_solutions_subtitle: "chez {company}",
  payment_solutions_choice_infos: "Sélectionnez le financement qui vous convient",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} puis {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} dans {delay_in_days} jours",
  payment_schedule_deferred_day_of_next_month: "{amount} le {payment_date}",
  payment_solutions_no_merchants_found:
    "Aucune solution de paiement ne répond aux critères d'éligibilité à partir des informations fournies.",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Paiement en cours",
  payment_pending_information_1:
    "Veuillez ne pas rafraîchir la page durant le traitement des informations.",
  payment_pending_information_2: "Cette étape peut durer quelques minutes.",
  payment_cancelled: "Paiement annulé",
  payment_analysis_needed: "Vous y êtes presque !",
  payment_analysis_information_1:
    "Dans le cadre de votre demande de financement, nous souhaitons recueillir auprès de votre établissement bancaire un historique de vos transactions sur 90 jours.",
  payment_analysis_information_2:
    "Ce service est opéré par notre partenaire {providerLabel}, leader du partage sécurisé de données bancaires, agréé par l'ACPR.",
  payment_analysis_information_3:
    "En cliquant sur Valider, je consens à transmettre à {operatorTeamName} les informations extraites de mes relevés de compte bancaires.",
  payment_analysis_information_4:
    "Si vous ne le souhaitez pas, vous pouvez revenir à la sélection des moyens de paiement de {merchantName} en cliquant sur la croix.",
  payment_analysis_in_progress: "Analyse des informations en cours",
  payment_analysis_progress_information_1:
    "Votre requête de demande de financement est en cours d'analyse.",
  payment_analysis_progress_information_2:
    "Veuillez ne pas rafraîchir la page durant le traitement des informations. Cette étape peut durer quelques minutes.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "Félicitations\u00A0!",
  payment_registered: "Votre paiement a été enregistré.",
  payment_method_registered:
    "Votre nouveau moyen de paiement a bien été pris en compte.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Vérification de votre numéro de téléphone",
  phone_number_tooltip:
    "Ceci est le numéro de téléphone que vous avez fourni au marchand",
  wrong_code: "Ce code semble incorrect, merci de réessayer",
  unknown_error: "Une erreur est survenue",
  code_valid: "Code valide",
  please_type_code: "Tapez ce code dans le champ ci dessous",
  you_ll_receive_text: "Nous venons de vous envoyer un code au ",
  did_not_receive: "Je n'ai pas reçu de code",
  send_again: "recommencer",
  sending_sms: "Envoi en cours",
  verify: "Vérifier",
  code_transmission_error:
    "Une erreur est survenue lors de l'envoi du code. Merci de réessayer",
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "Le champ est obligatoire",
    phone_number: "Le numéro de téléphone est invalide",
    date_format: "Le format de la date est invalide ({_value_})",
    invalid_birthdate: "Veuillez renseigner une date de naissance valide",
    date_between: "La date est invalide",
    min: "Le champ doit contenir au moins {length} caractères.",
    positive: "La valeur doit être un nombre positif.",
    email: "Le format de l'email est invalide ({_value_})",
  },
  /** ----------------------------------------------------
   *                        CUSTOM SCREEN
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: "Cliquer sur 'Valider' pour confirmer votre réservation.",
    subtitle:
      "Une fois votre réservation validée, votre voyage sera intégralement payé et vous serez prêt à voyager.",
    text: "Une empreinte bancaire peut apparaître sur votre compte. Ne vous inquiétez pas, nous vous préviendrons avant chaque échéance. Les paiements non effectués peuvent entraîner l'annulation du voyage.",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "La ressource demandée n'est plus disponible",
    error_404: "Erreur 404",
    expired: "L'URL demandée a expiré",
    not_found: "La page que vous cherchez n'a pas été trouvée",
  },
};
