export const RAW_INFORMATION = "RAW_INFORMATION";
export const RAW_INFORMATION_FROM_SERVER = "RAW_INFORMATION_FROM_SERVER";
export const RAW_SET_PAYMENT_FUNNEL_UID = "RAW_SET_PAYMENT_FUNNEL_UID";
export const RAW_SET_MERCHANT_UID = "RAW_SET_MERCHANT_UID";
export const RAW_SET_ORDER_UID = "RAW_SET_ORDER_UID";
export const RAW_SET_COMPANY_UID = "RAW_SET_COMPANY_UID";
export const SET_RAW_SHARE_UID = "SET_RAW_SHARE_UID";
export const APP_SET_PURCHASE_UID = "APP_SET_PURCHASE_UID";
export const APP_ADD_SHARE = "APP_ADD_SHARE";
export const APP_UPDATE_SHARE = "APP_UPDATE_SHARE";
export const APP_REMOVE_SHARE = "APP_REMOVE_SHARE";
export const APP_SET_IS_ALONE = "APP_SET_IS_ALONE";
export const APP_SET_SHARE_HAS_FORCED_AMOUNT = "APP_SET_SHARE_HAS_FORCED_AMOUNT";
export const APP_SET_ADDRESS = "APP_SET_ADDRESS";
export const APP_SET_BIRTH_DATE = "APP_SET_BIRTH_DATE";
export const APP_SET_BIRTH_CITY = "APP_SET_BIRTH_CITY";
export const APP_SET_BIRTH_ZIP_CODE = "APP_SET_BIRTH_ZIP_CODE";
export const APP_SET_BIRTH_COUNTRY = "APP_SET_BIRTH_COUNTRY";
export const APP_SET_PHONE_NUMBER = "APP_SET_PHONE_NUMBER";
export const APP_RESET_FORCED_SHARES = "APP_RESET_FORCED_SHARES";
export const APP_SET_EMAIL = "APP_SET_EMAIL";
export const APP_SET_STARTED = "APP_SET_STARTED";
export const APP_SET_PM_REGISTERED = "APP_SET_PM_REGISTERED";
export const APP_SET_FIRSTNAME = "APP_SET_FIRSTNAME";
export const APP_SET_LASTNAME = "APP_SET_LASTNAME";
export const APP_SET_BIRTHLASTNAME = "APP_SET_BIRTHLASTNAME";
export const APP_SET_CIVILITY = "APP_SET_CIVILITY";
export const APP_SET_NATIONALITY = "APP_SET_NATIONALITY";

export const COMPANY_GET_SUCCESS = "COMPANY_GET_SUCCESS";

export const MERCHANT_GET_SUCCESS = "MERCHANT_GET_SUCCESS";

export const PURCHASE_POST_SUCCESS = "PURCHASE_POST_SUCCESS";
export const PURCHASE_GET_SUCCESS = "PURCHASE_GET_SUCCESS";
export const PURCHASE_POST_SHARES_CREATION = "PURCHASE_POST_SHARES_CREATION";

export const SHARE_POST_BY_PURCHASE_SUCCESS = "SHARE_POST_BY_PURCHASE_SUCCESS";
export const SHARE_POST_BY_PURCHASE_SUCCESS_ANCV =
  "SHARE_POST_BY_PURCHASE_SUCCESS_ANCV";
export const SHARE_GET_SUCCESS = "SHARE_GET_SUCCESS";

export const APP_DELETE_SHARES = "APP_DELETE_SHARES";

export const ACCOUNT_GET_EMAIL = "ACCOUNT_GET_EMAIL";
export const SHARE_ANCV_UPDATE = "SHARE_ANCV_UPDATE";

export const ORDER_GET_SUCCESS = "ORDER_GET_SUCCESS";
export const ORDER_POST_SUCCESS = "ORDER_POST_SUCCESS";

export const SET_FUNNEL_ERROR = "SET_FUNNEL_ERROR";
export const SET_ACCOUNT = "SET_ACCOUNT";

export const UPDATE_SECURITY_TOKEN = "UPDATE_SECURITY_TOKEN";
