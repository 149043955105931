import _merge from "lodash.merge";
import _omit from "lodash.omit";
import { normalize } from "normalizr";
import { shareSchema } from "@/store/schemas.js";
import * as types from "@/store/mutation-types";
import { PURCHASE, ACCOUNT } from "@/store/namespaces";
import {
  ShareTypes,
  SHARE_PRIMARY_TYPES,
  SHARE_SPLIT_TYPES,
  SHARE_INSTALLMENT_TYPES,
} from "@/constants";
import { PspPaymentMethods } from "@/constants/stripe";
import PurchaseService from "../../api/purchaseService";
import ShareService from "../../api/shareService";
import { getSharesOfType, getShareOfType } from "../../helpers/shares";

// Initial state
const state = {
  all: {},
};

// Getters
const getters = {
  allShares: (shareState) => shareState.all,
  purchaseShares: (shareState, shareGetters, rootState, rootGetters) => {
    // Filter allShares on selected purchase uid
    return Object.fromEntries(
      Object.entries(shareGetters.allShares).filter(
        (value) => value[1].purchase_uid === rootGetters.rawPurchaseUid
      )
    );
  },
  share: (shareState, shareGetters, rootState, rootGetters) => {
    return shareGetters.allShares[rootGetters.rawShareUid];
  },
  shareLanguage: (shareState, shareGetters) => shareGetters.share?.language,
  shareUid: (shareState, shareGetters) => shareGetters.share?.uid,
  shareState: (shareState, shareGetters) => shareGetters.share?.state,
  shareIsMoto: (shareState, shareGetters) => shareGetters.share?.is_moto,
  mustVerifyPhoneNumber: (shareState, shareGetters) =>
    shareGetters.share?.must_verify_phone_number || false,
  mustVerifyIdentity: (shareState, shareGetters) =>
    shareGetters.share?.must_verify_identity || false,
  shareIsSkipCard: (shareState, shareGetters) => shareGetters.share?.skip_card || false,
  pspPaymentMethods: (shareState, shareGetters) =>
    shareGetters.share?.psp_payment_methods || [PspPaymentMethods.CARD],
  /**
   * ANCV
   */
  ancvShare: (shareState) =>
    getShareOfType(Object.values(shareState.all), ShareTypes.ANCV),
  /**
   * INSTALLMENT
   */
  isInstallment: (shareState, shareGetters) =>
    SHARE_INSTALLMENT_TYPES.includes(shareGetters.share?.share_type),
  isFirstInstallment: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.FIRST_INSTALLMENT,
  isNextInstallment: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.NEXT_INSTALLMENT,
  firstInstallmentShare: (shareState) =>
    getShareOfType(Object.values(shareState.all), ShareTypes.FIRST_INSTALLMENT),
  nextInstallmentShares: (shareState) =>
    getSharesOfType(Object.values(shareState.all), ShareTypes.NEXT_INSTALLMENT),
  /**
   * DEFERRED
   */
  isDeferred: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.DEFERRED,
  deferredShare: (shareState) =>
    getShareOfType(Object.values(shareState.all), ShareTypes.DEFERRED),
  /**
   * DOWN_PAYMENT
   */
  isDeposit: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.DEPOSIT,
  isBalance: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.BALANCE,
  depositShare: (shareState) =>
    getShareOfType(Object.values(shareState.all), ShareTypes.DEPOSIT),
  balanceShare: (shareState) =>
    getShareOfType(Object.values(shareState.all), ShareTypes.BALANCE),
  /**
   * SPLIT
   */
  isSplit: (shareState, shareGetters) =>
    SHARE_SPLIT_TYPES.includes(shareGetters.share?.share_type),
  leaderShare: (shareState) =>
    getShareOfType(Object.values(shareState.all), ShareTypes.LEADER),
  pledgerShares: (shareState) =>
    getSharesOfType(Object.values(shareState.all), ShareTypes.PLEDGER),
  isLeader: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.LEADER,
  isPledger: (shareState, shareGetters) =>
    shareGetters.share?.share_type === ShareTypes.PLEDGER,
  isAlone: (shareState, shareGetters) => shareGetters.pledgerShares.length === 0,

  /**
   * Common to all payment types
   */
  isPrimary: (shareState, shareGetters) =>
    // For non-primary funnels, the back sends a payment link to the client, where
    // the payment link refers to a specific share. Therefore, getters.share is
    // always defined.
    // A contrario, if getters.share is undefined, it means that we are in a primary
    // funnel.
    // Note: for INSTALLMENT and DEFERRED payments, the shares are created
    // before the front is open. It means that here, the payment type should normally
    // be SPLIT.
    !shareGetters.share ||
    // If there is a share, the funnel is primary only if getters.share.share_type
    // belongs to the primary shares types.
    SHARE_PRIMARY_TYPES.includes(shareGetters.share?.share_type),
  has_penalties: (shareState, shareGetters) =>
    shareGetters.share?.penalties_amount_cents > 0,
  has_fees: (shareState, shareGetters) => shareGetters.share?.fees_amount_cents > 0,
};

// Actions
const actions = {
  appDeleteShares({ commit }) {
    commit(types.APP_DELETE_SHARES);
  },

  shareAncvUpdate({ commit }, { share_uid, ancv_amount_cents, ancv_amount }) {
    commit(types.SHARE_ANCV_UPDATE, {
      share_uid,
      ancv_amount_cents,
      ancv_amount,
    });
  },

  async createSharesAction(
    { commit, rootGetters },
    { purchaseUid, data, is_ancv = false, delete_shares = false }
  ) {
    const modifiedData = data;
    // For split payment type, we need to geve backend share with computed amount for each pledger
    if (data?.shares) {
      // forced_amount is used to compute "manually set" share amount (for SPLIT pledger)
      // this amount has already been updated, furthermore forced_amount is not relevant for backend
      modifiedData.shares = data.shares.map((share) =>
        _omit(share, ["forced_amount", "uid"])
      );
    }

    const { data: resultData } = await PurchaseService.createShares(
      purchaseUid,
      { signature: rootGetters.rawSignature },
      modifiedData,
      is_ancv
    );
    const { entities, result } = normalize(resultData, [shareSchema]);
    if (delete_shares) {
      commit(types.SHARE_POST_BY_PURCHASE_SUCCESS, {
        entities,
        result,
      });
    } else {
      commit(types.SHARE_POST_BY_PURCHASE_SUCCESS_ANCV, {
        entities,
        result,
      });
    }

    commit(
      `${PURCHASE}/${types.PURCHASE_POST_SHARES_CREATION}`,
      { entities, result },
      { root: true }
    );

    return { entities, result };
  },

  async fetchShareAction({ commit }, { shareUid, isPaymentMethodUpdate }) {
    const { data } = await ShareService.fetchShare(shareUid, isPaymentMethodUpdate);

    const { entities, result } = normalize(data, shareSchema);

    commit(types.SHARE_GET_SUCCESS, { entities, result });
    commit(
      `${ACCOUNT}/${types.SHARE_GET_SUCCESS}`,
      { entities, result },
      { root: true }
    );

    commit(types.SET_RAW_SHARE_UID, { shareUid: result }, { root: true });

    const share = entities.shares[result];
    const account = entities.accounts[share.account];
    commit(
      types.APP_SET_PURCHASE_UID,
      { purchaseUid: share.purchase_uid },
      { root: true }
    );
    commit(`${ACCOUNT}/${types.SET_ACCOUNT}`, { account }, { root: true });
  },
};

// Mutations
const mutations = {
  [types.SHARE_POST_BY_PURCHASE_SUCCESS](state, { entities }) {
    state.all = _merge({}, entities.shares);
  },

  [types.SHARE_POST_BY_PURCHASE_SUCCESS_ANCV](state, { entities }) {
    state.all = _merge({}, state.all, entities.shares);
  },

  [types.APP_DELETE_SHARES](state) {
    state.all = {};
  },

  [types.SHARE_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.shares);
  },

  [types.PURCHASE_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.shares);
  },

  [types.SHARE_ANCV_UPDATE](state, { share_uid, ancv_amount_cents, ancv_amount }) {
    state.all = _merge({}, state.all, {
      [share_uid]: Object.assign(state.all[share_uid] || {}, {
        amount_cents: ancv_amount_cents,
        ancv_amount,
      }),
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
