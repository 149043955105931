import httpService from "./httpService";

const ENDPOINTS = {
  sendSMSToken: (targetUid) => `payments/${targetUid}/send_sms_token`,
  verifySMSToken: (targetUid, code) => `payments/${targetUid}/verify_sms_token/${code}`,
};

async function sendSMSToken(targetUid, manualRetry = false) {
  const url = ENDPOINTS.sendSMSToken(targetUid);
  return httpService.post(url, null, { manual_retry: manualRetry });
}

async function verifySMSToken(targetUid, code) {
  const url = ENDPOINTS.verifySMSToken(targetUid, code);
  return httpService.post(url);
}

const TwoFactorService = {
  sendSMSToken,
  verifySMSToken,
};

export default TwoFactorService;
