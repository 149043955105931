export const useFunnelStep = (context) => {
  const funnelStepDone = (data) => {
    context.emit("stepDone", data);
  };

  const funnelStepDoneDelayed = (data, timeout = 2000) => {
    setTimeout(() => {
      funnelStepDone(data);
    }, timeout);
  };

  return { funnelStepDone, funnelStepDoneDelayed };
};

export default useFunnelStep;
