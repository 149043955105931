import { onMounted, ref, watch } from "vue";
import { useStore } from "@/composables/store";

import config from "@/config";
import { MERCHANT } from "@/store/namespaces";

export const checkThreatMetrixAvailableTimeout = 1000;
export const threatMetrixScriptUrl = `${config.CDN_BASE}/static/js/${config.THREATMETRIX_SCRIPT_NAME}`;

/**
 * This composable aims at calling the ThreatMetrix profiling tags
 * The function supplied by ThreatMetrix is tgdq1101, injected
 * through the script hosted on aws.
 */
export const useThreatMetrix = () => {
  const scriptLoaded = ref(false);
  const checkScriptLoadedInterval = ref(undefined);
  const { useGetters } = useStore();

  const checkThreatMetrixAvailable = () => {
    if (typeof tgdq1101 === "undefined") return;
    scriptLoaded.value = true;
  };

  const { rawIsSeminal } = useGetters(["rawIsSeminal"]);
  const { webSessionId } = useGetters(MERCHANT, ["webSessionId"]);

  if (rawIsSeminal.value) {
    const threatMetrixScript = document.createElement("script");
    threatMetrixScript.setAttribute("defer", "");
    threatMetrixScript.setAttribute("src", threatMetrixScriptUrl);
    document.head.appendChild(threatMetrixScript);
  }

  onMounted(() => {
    if (!rawIsSeminal.value || !webSessionId.value) return;

    checkScriptLoadedInterval.value = setInterval(
      checkThreatMetrixAvailable,
      checkThreatMetrixAvailableTimeout
    );
  });

  watch(scriptLoaded, (newValue, oldValue) => {
    if (newValue === oldValue) return;
    if (newValue && checkScriptLoadedInterval.value) {
      clearInterval(checkScriptLoadedInterval.value);
      // eslint-disable-next-line no-undef
      tgdq1101.pfpl(
        config.THREATMETRIX_DOMAIN_NAME,
        config.THREATMETRIX_ORG_ID,
        webSessionId.value
      );
    }
  });

  return { scriptLoaded };
};

export default useThreatMetrix;
