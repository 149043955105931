<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M35.0712 37.9315C34.3198 37.9315 33.6413 37.7997 33.0356 37.536C32.43 37.2613 31.9421 36.8548 31.572 36.3165C31.2131 35.7781 31.0224 35.1079 31 34.3059H32.43C32.4412 34.9651 32.6767 35.5364 33.1366 36.0198C33.5964 36.4923 34.2413 36.7285 35.0712 36.7285C35.9012 36.7285 36.5237 36.5033 36.9386 36.0528C37.3648 35.6023 37.5779 35.0585 37.5779 34.4213C37.5779 33.8829 37.4433 33.4435 37.1742 33.1029C36.9162 32.7623 36.5629 32.5096 36.1143 32.3448C35.6769 32.18 35.1946 32.0976 34.6675 32.0976H33.7927V30.9275H34.6675C35.4301 30.9275 36.0246 30.7572 36.4508 30.4166C36.8882 30.0761 37.1069 29.5981 37.1069 28.9829C37.1069 28.4665 36.933 28.0435 36.5853 27.7139C36.2489 27.3733 35.7442 27.203 35.0712 27.203C34.4207 27.203 33.8992 27.3953 33.5067 27.7798C33.1141 28.1534 32.8954 28.6258 32.8506 29.1971H31.4206C31.4542 28.5709 31.6225 28.0161 31.9253 27.5326C32.2393 27.0492 32.6655 26.6757 33.2039 26.412C33.7422 26.1373 34.3647 26 35.0712 26C35.8339 26 36.4676 26.1318 36.9723 26.3955C37.4882 26.6592 37.8751 27.0108 38.1331 27.4502C38.4023 27.8897 38.5369 28.3731 38.5369 28.9005C38.5369 29.4828 38.3742 30.0156 38.049 30.499C37.7237 30.9715 37.2359 31.2901 36.5853 31.4549C37.2807 31.5977 37.8583 31.9218 38.3181 32.4272C38.778 32.9326 39.0079 33.5973 39.0079 34.4213C39.0079 35.0585 38.8565 35.6463 38.5537 36.1846C38.2621 36.712 37.8247 37.135 37.2415 37.4536C36.6582 37.7722 35.9348 37.9315 35.0712 37.9315Z"
      class="fill-secondary"
    />
    <path
      d="M40.3688 37.7338L43.2624 33.6467L40.3688 29.5597H41.8997L44.2381 32.9381L46.5934 29.5597H48.1075L45.2139 33.6467L48.1075 37.7338H46.5934L44.2381 34.3554L41.8997 37.7338H40.3688Z"
      class="fill-secondary"
    />
  </svg>
</template>
