import "es6-promise/auto";
import { createApp, defineAsyncComponent } from "vue";
import VueMobileDetection from "vue-mobile-detection";
import { GlobalEvents } from "vue-global-events";
import ElementPlus from "element-plus";

import router from "@/router";
import store from "@/store/store";
import { Logger } from "@/utils/logger";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import i18n from "@/plugins/i18n";
import { initMatomo } from "@/plugins/matomo";
import Sentry from "@/plugins/sentry";
import initVeeValidate from "@/plugins/vee-validate";
import httpService from "@/api/httpService";
import errorParser from "@/helpers/errorParser";
import initializeIcons from "@/components/icons";
import { LoaderTypes, LoaderSizes } from "@/constants/loader";
import { ShareStates } from "@/constants";
import { LocalesLabels, ShortLocales } from "@/translations/locales";

// Issue with missing functions from vue-demi when importing from "element-plus" only
import "element-plus/dist/index.css";

import App from "@/App";
import AppContext from "./AppContext";

const BlankLayout = defineAsyncComponent(() => import("@/layouts/BlankLayout"));
const FunnelLayout = defineAsyncComponent(() => import("@/layouts/FunnelLayout"));
const DashboardLayout = defineAsyncComponent(() => import("@/layouts/DashboardLayout"));
const PaymentSolutionsLayout = defineAsyncComponent(() =>
  import("@/layouts/PaymentSolutionsLayout")
);

const app = createApp(App);

app.use(i18n);

Sentry.initSentry(app, router);
initMatomo(app, router);

app.config.warnHandler = (warn, vm, info) => {
  Logger.warn({
    id: "vue-warn-handler",
    warning: JSON.stringify({ info, warn: errorParser(warn) }),
  });
};

app.config.errorHandler = (error, vm, info) => {
  Logger.error({
    id: "vue-error-handler",
    error: JSON.stringify({ info, error: errorParser(error) }),
  });
};

// Set constants as global
app.config.globalProperties.LoaderTypes = LoaderTypes;
app.config.globalProperties.LoaderSizes = LoaderSizes;
app.config.globalProperties.LocalesLabels = LocalesLabels;
app.config.globalProperties.ShortLocales = ShortLocales;
app.config.globalProperties.ShareStates = ShareStates;

initVeeValidate(app);
initializeIcons(app);

// Register layouts
app.component("LayoutBlank", BlankLayout);
app.component("LayoutFunnel", FunnelLayout);
app.component("LayoutDashboard", DashboardLayout);
app.component("LayoutPaymentSolutions", PaymentSolutionsLayout);
app.component("GlobalEvents", GlobalEvents);

app.component("VSelect", vSelect);

app.config.globalProperties.$http = httpService.initialize();

app.use(VueMobileDetection);

app.use(ElementPlus);

app.use(router);
app.use(store);

AppContext.setGlobalProperties(app.config.globalProperties);

router.isReady().then(() => app.mount("#pledg-app"));
