import { computed } from "vue";
import { Operators, DEFAULT_OPERATOR, OPERATOR_TEAM_NAME } from "@/constants";
import { useStore } from "@/composables/store";
import { MERCHANT, COMPANY } from "@/store/namespaces";

export const useOperator = () => {
  const { useGetters } = useStore();

  const { merchantOperator } = useGetters(MERCHANT, ["merchantOperator"]);
  const { companyOperator } = useGetters(COMPANY, ["companyOperator"]);

  const currentOperator = computed(() => {
    return merchantOperator.value || companyOperator.value || DEFAULT_OPERATOR;
  });

  const isCACFOperator = computed(() => {
    return currentOperator.value === Operators.CACF;
  });

  const operatorTeamName = computed(() => {
    return OPERATOR_TEAM_NAME[currentOperator.value];
  });

  return { currentOperator, isCACFOperator, operatorTeamName };
};

export default useOperator;
