import httpService from "./httpService";

export const ENDPOINTS = {
  contact: (shareUid) => `shares/${shareUid}/contact`,
  status: (shareUid) => `shares/${shareUid}/status`,
  share: (shareUid) => `shares/${shareUid}`,
  paymentMethodUpdate: (shareUid) => `payment_method_update/shares/${shareUid}`,
};

async function resendInvitation(shareUid, contact) {
  const url = ENDPOINTS.contact(shareUid);
  return httpService.post(url, null, { contact });
}

async function getStatus(shareUid, isSeminal = 0) {
  const url = ENDPOINTS.status(shareUid);
  const response = await httpService.get(url, { is_seminal: isSeminal });
  return response;
}

async function fetchShare(shareUid, isPaymentMethodUpdate) {
  let url;
  if (isPaymentMethodUpdate) {
    url = ENDPOINTS.paymentMethodUpdate(shareUid);
  } else {
    url = ENDPOINTS.share(shareUid);
  }
  return httpService.get(url);
}

const ShareService = {
  resendInvitation,
  getStatus,
  fetchShare,
};

export default ShareService;
