function timeout(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default async function runWithDelay(delayInMs, fn, ...args) {
  await timeout(delayInMs);
  return fn(...args);
}
