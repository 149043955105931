import config from "@/config";
import httpService from "./httpService";
import { TransactionTypes } from "../constants";
import AppContext from "../AppContext";

export const ENDPOINTS = {
  shareCheckout: (shareUid) => `shares/${shareUid}/checkout`,
  orderCheckout: (orderUid) => `orders/${orderUid}/checkout`,
  sharePaymentIntent: (shareUid) => `shares/${shareUid}/payment_intent`,
  orderPaymentIntent: (orderUid) => `orders/${orderUid}/payment_intent`,
  sharePaymentIntentRedirect: (shareUid) =>
    `shares/${shareUid}/payment_intent/redirect`,
  orderPaymentIntentRedirect: (orderUid) =>
    `orders/${orderUid}/payment_intent/redirect`,
};

async function fetchCheckoutParams(targetUid) {
  let url;
  switch (AppContext.transactionType) {
    case TransactionTypes.ORDER:
      url = ENDPOINTS.orderCheckout(targetUid);
      break;
    default:
      url = ENDPOINTS.shareCheckout(targetUid);
      break;
  }
  const { data } = await httpService.get(url);
  return data.stripe_params;
}

async function createPaymentIntent(targetUid, paymentIntentData) {
  let url;
  switch (AppContext.transactionType) {
    case TransactionTypes.ORDER:
      url = ENDPOINTS.orderPaymentIntent(targetUid);
      break;
    default:
      url = ENDPOINTS.sharePaymentIntent(targetUid);
      break;
  }
  const { data } = await httpService.post(url, null, paymentIntentData);
  return data;
}

function returnURLAfterPaymentIntent(targetUid) {
  let url;
  switch (AppContext.transactionType) {
    case TransactionTypes.ORDER:
      url = ENDPOINTS.orderPaymentIntentRedirect(targetUid);
      break;
    default:
      url = ENDPOINTS.sharePaymentIntentRedirect(targetUid);
      break;
  }
  return `${config.API_BASE_URL}/${url}`;
}

const StripeService = {
  fetchCheckoutParams,
  createPaymentIntent,
  returnURLAfterPaymentIntent,
};

export default StripeService;
