import * as types from "@/store/mutation-types";
import router from "@/router";
import { SECURITY_TOKKEN } from "@/constants";
import AppContext from "../../AppContext";

// Initial state
const state = {};

const getters = {};

// Mutations
const mutations = {
  [types.UPDATE_SECURITY_TOKEN]() {
    /**
     * Update current route with token in case of page reload
     * and avoid import dependency cycle
     */
    const { currentRoute } = router;
    if (
      AppContext.securityToken &&
      AppContext.securityToken !== currentRoute.value?.query?.[SECURITY_TOKKEN]
    ) {
      const query = {
        ...currentRoute.value.query,
        [SECURITY_TOKKEN]: AppContext.securityToken,
      };
      router.replace({ query });
    }
  },
};

export default {
  namespaced: false,
  state,
  getters,
  mutations,
};
