import _merge from "lodash.merge";
import { normalize } from "normalizr";
import * as types from "@/store/mutation-types";
import MerchantService from "@/api/merchantService";
import { orderSchema } from "@/store/schemas.js";
import OrderService from "@/api/orderService";
import { FUNNEL } from "@/store/namespaces";
import { SCORING_OR_FILTERS_KO_STATES } from "@/constants";

const state = {
  all: {},
};

const getters = {
  allOrders: (state) => state.all,
  order: (state, orderGetters, rootState, rootGetters) => {
    return orderGetters.allOrders[rootGetters.rawOrderUid];
  },
  orderUid: (state, orderGetters) => orderGetters.order?.uid,
  orderIsMoto: (state, orderGetters) => orderGetters.order?.is_moto,
  mustVerifyPhoneNumber: (state, orderGetters) =>
    orderGetters.order?.must_verify_phone_number || false,
  amountCentsToCharge: (state, orderGetters) =>
    orderGetters.order?.amount_cents_to_charge,
  currency: (state, orderGetters) => orderGetters.order?.currency,
  orderCountry: (state, orderGetters, _, rootGetters) =>
    rootGetters.rawAddress?.country || orderGetters.order?.address_country,
  orderIsScoringKOState: (state, orderGetters) =>
    SCORING_OR_FILTERS_KO_STATES.includes(orderGetters.order?.state),
};

const mutations = {
  [types.ORDER_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.orders);
  },
  [types.ORDER_POST_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.orders);
  },
};

// Actions
const actions = {
  async createOrderAction(
    { commit, dispatch, rootGetters },
    { merchantUid, payment_parameters }
  ) {
    const { data } = await MerchantService.createOrder(
      merchantUid,
      { iframe_id: rootGetters.rawIframeId, signature: rootGetters.rawSignature },
      payment_parameters
    );
    const { entities, result } = normalize(data, orderSchema);
    commit(types.ORDER_POST_SUCCESS, { entities, result });

    /**
     * On order creation, save all funnel input data
     * on server side. Fetch them in case of reload.
     * Here 'result' corresponds to order_uid
     * */
    dispatch(`${FUNNEL}/createFunnelAction`, { targetUid: result }, { root: true });

    return { entities, result };
  },
  async fetchOrderAction({ commit }, { orderUid, isDashboardBuyer = false }) {
    const response = await OrderService.fetchOrder(orderUid, isDashboardBuyer);
    if (response?.data) {
      const { entities, result } = normalize(response.data, orderSchema);
      commit(types.ORDER_GET_SUCCESS, { entities, result });
      commit(types.RAW_SET_ORDER_UID, { orderUid }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
