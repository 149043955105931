export default function getFormattedPrice(price_to_format, currency, locale) {
  const localeFormatted = locale.replace("_", "-");
  const style = "currency";
  const amount = price_to_format / 100;

  try {
    return new Intl.NumberFormat(localeFormatted, {
      style,
      currency,
      currencyDisplay: "narrowSymbol",
    }).format(amount);
  } catch (e) {
    // currencyDisplay not supported on old browsers
    return new Intl.NumberFormat(localeFormatted, {
      style,
      currency,
    }).format(amount);
  }
}
