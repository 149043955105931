<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M24.0417 35.9167C24.4099 35.9167 24.7083 35.6182 24.7083 35.25C24.7083 34.8818 24.4099 34.5833 24.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM24.0417 34.5833H8V35.9167H24.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M27.81 38.0091V29.0646L26.1875 29.4336V28.5775L28.212 27.6771H29.0304V38.0091H27.81Z"
      class="fill-secondary"
    />
    <path
      d="M35.0718 38.1862C34.1816 38.1862 33.411 37.9648 32.7601 37.522C32.1188 37.0694 31.6258 36.4446 31.2812 35.6475C30.9366 34.8406 30.7643 33.9058 30.7643 32.8431C30.7643 31.7804 30.9366 30.8505 31.2812 30.0535C31.6258 29.2466 32.1188 28.6218 32.7601 28.179C33.411 27.7263 34.1816 27.5 35.0718 27.5C35.962 27.5 36.7277 27.7263 37.3691 28.179C38.0104 28.6218 38.5034 29.2466 38.848 30.0535C39.1926 30.8505 39.3649 31.7804 39.3649 32.8431C39.3649 33.9058 39.1926 34.8406 38.848 35.6475C38.5034 36.4446 38.0104 37.0694 37.3691 37.522C36.7277 37.9648 35.962 38.1862 35.0718 38.1862ZM35.0718 37.094C35.6557 37.094 36.1774 36.9267 36.6368 36.5922C37.1058 36.2478 37.4696 35.7607 37.728 35.1309C37.9961 34.4913 38.1301 33.7287 38.1301 32.8431C38.1301 31.9575 37.9961 31.1998 37.728 30.5701C37.4696 29.9403 37.1058 29.4582 36.6368 29.1236C36.1774 28.7792 35.6557 28.607 35.0718 28.607C34.4783 28.607 33.9518 28.7792 33.4924 29.1236C33.0329 29.4582 32.6692 29.9403 32.4011 30.5701C32.1331 31.1998 31.9991 31.9575 31.9991 32.8431C31.9991 33.7287 32.1331 34.4913 32.4011 35.1309C32.6692 35.7607 33.0329 36.2478 33.4924 36.5922C33.9518 36.9267 34.4783 37.094 35.0718 37.094Z"
      class="fill-secondary"
    />
    <path
      d="M40.3571 38.0091L43.1834 33.8199L40.3571 29.6307H41.8524L44.1365 33.0935L46.437 29.6307H47.9159L45.0896 33.8199L47.9159 38.0091H46.437L44.1365 34.5463L41.8524 38.0091H40.3571Z"
      class="fill-secondary"
    />
  </svg>
</template>
