<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.8921 33.3661C37.7467 33.2184 37.5093 33.2167 37.3618 33.3624C37.2144 33.508 37.2126 33.7458 37.3581 33.8935L37.8921 33.3661ZM38.0001 28.2881C38.0001 28.0807 37.8322 27.9125 37.6251 27.9125C37.418 27.9125 37.2501 28.0807 37.2501 28.2881H38.0001ZM40.2747 36.8565C40.4201 37.0042 40.6576 37.0059 40.8051 36.8603C40.9525 36.7146 40.9542 36.4769 40.8088 36.3291L40.2747 36.8565ZM37.2501 33.5881C37.2501 33.7956 37.418 33.9637 37.6251 33.9637C37.8322 33.9637 38.0001 33.7956 38.0001 33.5881H37.2501ZM37.0266 33.0304C36.8884 33.1688 36.7269 33.3665 36.7269 33.6298C36.7269 33.8932 36.8884 34.0909 37.0266 34.2293L37.5569 33.6981C37.5053 33.6464 37.4827 33.615 37.4738 33.5997C37.4669 33.5878 37.4769 33.6005 37.4769 33.6298C37.4769 33.6591 37.4669 33.6719 37.4738 33.66C37.4827 33.6447 37.5053 33.6132 37.5569 33.5616L37.0266 33.0304ZM38.2236 33.0304C38.0854 32.892 37.888 32.7303 37.6251 32.7303C37.3622 32.7303 37.1648 32.892 37.0266 33.0304L37.5569 33.5616C37.6085 33.5099 37.6399 33.4872 37.6552 33.4783C37.6671 33.4714 37.6544 33.4814 37.6251 33.4814C37.5959 33.4814 37.5831 33.4714 37.595 33.4783C37.6103 33.4872 37.6417 33.5099 37.6933 33.5616L38.2236 33.0304ZM38.2236 34.2293C38.3618 34.0909 38.5233 33.8932 38.5233 33.6298C38.5233 33.3665 38.3618 33.1688 38.2236 33.0304L37.6933 33.5616C37.7449 33.6132 37.7675 33.6447 37.7764 33.66C37.7833 33.6719 37.7733 33.6591 37.7733 33.6298C37.7733 33.6005 37.7833 33.5878 37.7764 33.5997C37.7675 33.615 37.7449 33.6464 37.6933 33.6981L38.2236 34.2293ZM37.6933 33.6981C37.6417 33.7498 37.6103 33.7725 37.595 33.7814C37.5831 33.7883 37.5959 33.7782 37.6251 33.7782C37.6544 33.7782 37.6671 33.7883 37.6552 33.7814C37.6399 33.7725 37.6085 33.7498 37.5569 33.6981L37.0266 34.2293C37.1648 34.3677 37.3622 34.5294 37.6251 34.5294C37.888 34.5294 38.0854 34.3677 38.2236 34.2293L37.6933 33.6981ZM37.3581 33.8935L40.2747 36.8565L40.8088 36.3291L37.8921 33.3661L37.3581 33.8935ZM38.0001 33.5881V28.2881H37.2501V33.5881H38.0001Z"
      class="fill-secondary"
    />
    <path
      d="M37.2084 25.7007H36.5418V26.3684H37.2084V25.7007ZM37.2084 25.2417V24.5739H36.5418V25.2417H37.2084ZM38.0418 25.2417H38.7084V24.5739H38.0418V25.2417ZM38.0418 25.7007V26.3684H38.7084V25.7007H38.0418ZM36.7084 24.5739H36.0418V25.2417H36.7084V24.5739ZM36.7084 24.4905V23.8228H36.0418V24.4905H36.7084ZM38.5418 24.4905H39.2084V23.8228H38.5418V24.4905ZM38.5418 24.5739V25.2417H39.2084V24.5739H38.5418ZM37.8751 25.7007V25.2417H36.5418V25.7007H37.8751ZM37.2084 25.9094H38.0418V24.5739H37.2084V25.9094ZM37.3751 25.2417V25.7007H38.7084V25.2417H37.3751ZM38.0418 25.033H37.2084V26.3684H38.0418V25.033ZM37.3751 24.5739V24.4905H36.0418V24.5739H37.3751ZM36.7084 25.1582H38.5418V23.8228H36.7084V25.1582ZM37.8751 24.4905V24.5739H39.2084V24.4905H37.8751ZM38.5418 23.9062H36.7084V25.2417H38.5418V23.9062ZM33.1382 38.1238C30.6601 35.6419 30.6601 31.6178 33.1382 29.1359L32.1954 28.1915C29.1966 31.1951 29.1966 36.0646 32.1954 39.0681L33.1382 38.1238ZM42.1122 38.1238C39.634 40.6058 35.6162 40.6058 33.1382 38.1238L32.1954 39.0681C35.1941 42.0716 40.0561 42.0716 43.0547 39.0681L42.1122 38.1238ZM42.1122 29.1359C44.5901 31.6178 44.5901 35.6419 42.1122 38.1238L43.0547 39.0681C46.0534 36.0646 46.0534 31.1951 43.0547 28.1915L42.1122 29.1359ZM43.0547 28.1915C40.0561 25.1881 35.1941 25.1881 32.1954 28.1915L33.1382 29.1359C35.6162 26.6539 39.634 26.6539 42.1122 29.1359L43.0547 28.1915Z"
      class="fill-secondary"
    />
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M28.0417 35.9167C28.4099 35.9167 28.7083 35.6182 28.7083 35.25C28.7083 34.8818 28.4099 34.5833 28.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM28.0417 34.5833H8V35.9167H28.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
  </svg>
</template>
