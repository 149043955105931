import i18n from "@/plugins/i18n";
import ERROR_TYPES from "@/constants/errors";

export const INVALID_PROPERTY = "propertyInvalid";
export const MISSING_PROPERTY = "requiredPropertyMissing";
export const MISSING_OR_INVALID_PROPERTY = "requiredPropertyMissingOrInvalid";

export const Props = {
  AMOUNT_CENTS: "amountCents",
  EMAIL: "email",
  REFERENCE: "reference",
  TITLE: "title",
  ADDRESS: "address",
  PHONE_NUMBER: "phoneNumber",
  BIRTH_DATE: "birthDate",
  BIRTH_ZIPCODE: "birthZipcode",
  BIRTH_CITY: "birthCity",
  BIRTH_COUNTRY: "birthCountry",
  MERCHANT_UID: "merchantUid",
  SHARE_UID: "shareUid",
  BALANCE_PAYMENT_DATE: "balancePaymentDate",
  CIVILITY: "civility",
  BIRTH_LAST_NAME: "birthLastName",
  NATIONALITY: "nationality",
};

const PROPERTY_ERROR_MESSAGE_TYPE = {
  /**
   * Technical checks
   */
  [Props.AMOUNT_CENTS]: MISSING_OR_INVALID_PROPERTY,
  [Props.EMAIL]: INVALID_PROPERTY,
  [Props.REFERENCE]: MISSING_PROPERTY,
  [Props.TITLE]: MISSING_PROPERTY,
  [Props.MERCHANT_UID]: MISSING_OR_INVALID_PROPERTY,
  [Props.SHARE_UID]: INVALID_PROPERTY,
  [Props.BALANCE_PAYMENT_DATE]: MISSING_PROPERTY,
  /**
   * Functional checks
   */
  [Props.ADDRESS]: INVALID_PROPERTY,
  [Props.PHONE_NUMBER]: INVALID_PROPERTY,
  [Props.BIRTH_DATE]: INVALID_PROPERTY,
  [Props.BIRTH_CITY]: INVALID_PROPERTY,
  [Props.BIRTH_ZIPCODE]: INVALID_PROPERTY,
  [Props.BIRTH_COUNTRY]: INVALID_PROPERTY,
};

export function ValidationError(type, property, message) {
  const messageType = PROPERTY_ERROR_MESSAGE_TYPE[property];
  return {
    type,
    message: messageType ? i18n.global.t(messageType, { property }) : message,
  };
}

export const ValidationErrors = {
  /**
   * Technical
   */
  ALREADY_EXISTS: ValidationError(
    "purchase_already_exists",
    undefined,
    "Purchase already exists"
  ),
  INVALID_AMOUNT: ValidationError("invalid_amount", Props.AMOUNT_CENTS),
  INVALID_EMAIL: ValidationError("invalid_email", Props.EMAIL),
  NO_REFERENCE: ValidationError("no_reference", Props.REFERENCE),
  NO_TITLE: ValidationError("no_title", Props.TITLE),
  MISSING_MERCHANT_UID: ValidationError("missing_merchant_uid", Props.MERCHANT_UID),
  DISABLED_MERCHANT: ValidationError(ERROR_TYPES.DISABLED_MERCHANT, Props.MERCHANT_UID),
  MISSING_BALANCE_PAYMENT_DATE: ValidationError(
    "missing_balance_payment_date",
    Props.BALANCE_PAYMENT_DATE
  ),
  WRONG_SHARE_UID: ValidationError("wrong_share_uid", Props.SHARE_UID),
  /**
   * Functional
   */
  INVALID_ADDRESS: ValidationError("address_is_incorrect", Props.ADDRESS),
  INVALID_PHONE_NUMBER: ValidationError("phone_is_incorrect", Props.PHONE_NUMBER),
  INVALID_BIRTH_DATE: ValidationError("birthDate_incorrect", Props.BIRTH_DATE),
  INVALID_BIRTH_ZIPCODE: ValidationError("birthZipcode_incorrect", Props.BIRTH_ZIPCODE),
  INVALID_BIRTH_CITY: ValidationError("birthCity_incorrect", Props.BIRTH_CITY),
  INVALID_BIRTH_COUNTRY: ValidationError("birthCountry_incorrect", Props.BIRTH_COUNTRY),
  INVALID_BIRTH_LAST_NAME: ValidationError(
    "birthLastName_incorrect",
    Props.BIRTH_LAST_NAME
  ),
  INVALID_CIVILITY: ValidationError("civility_incorrect", Props.CIVILITY),
  INVALID_NATIONALITY: ValidationError("nationality_incorrect", Props.NATIONALITY),
};
