import _sumBy from "lodash.sumby";
import _findIndex from "lodash.findindex";
import toBoolean from "@/helpers/toBoolean";
import {
  validateAmount,
  validateEmail,
  validatePhoneNumber,
} from "@/helpers/validation";
import { ValidationErrors } from "@/models/ValidationErrorModel";
import { ShareTypes } from "../constants";
import { ORDER, SHARE, MERCHANT, PURCHASE } from "./namespaces";
import {
  isBirthDateValid,
  isBirthZipcodeValid,
  isBirthCityValid,
  isBirthCountryValid,
  isBirthLastNameValid,
  isCivilityValid,
  isNationalityValid,
} from "./helpers";

// Getters
const getters = {
  accessErrorType: (state) => state.accessErrorType,
  rawTitle: (state) => state.rawTitle,
  rawSubtitle: (state) => state.rawSubtitle,
  rawPaymentFunnelUid: (state) => state.rawPaymentFunnelUid,
  rawCompanyUid: (state) => state.rawCompanyUid,
  rawMerchantUid: (state) => state.rawMerchantUid,
  rawOrderUid: (state) => state.rawOrderUid,
  rawPurchaseUid: (state) => state.rawPurchaseUid,
  rawShareUid: (state) => state.rawShareUid,
  rawAmountCents: (state) => state.rawAmountCents,
  rawIframeId: (state) => state.rawIframeId,
  rawSignature: (state) => state.rawSignature,
  rawEmbedded: (state, currentGetters) => !!currentGetters.rawIframeId,
  rawEmail: (state) => state.rawEmail,
  rawPhoneNumber: (state) => state.rawPhoneNumber,
  rawCurrency: (state) => state.rawCurrency,
  rawReference: (state) => state.rawReference,
  rawPledgEnv: (state) => state.rawPledgEnv,
  rawAddress: (state) => state.rawAddress,
  rawShippingAddress: (state) => state.rawShippingAddress,
  rawCivility: (state) => state.rawCivility,
  rawFirstName: (state) => state.rawFirstName,
  rawLastName: (state) => state.rawLastName,
  rawBirthLastName: (state) => state.rawBirthLastName,
  rawCountryCode: (state) => state.rawCountryCode,
  rawBirthDate: (state) => state.rawBirthDate,
  rawBirthCity: (state) => state.rawBirthCity,
  rawBirthZipcode: (state) => state.rawBirthZipcode,
  rawBirthCountry: (state) => state.rawBirthCountry,
  rawNationality: (state) => state.rawNationality,
  rawB2bCompanyName: (state) => state.rawB2bCompanyName,
  rawB2bCompanyNationalId: (state) => state.rawB2bCompanyNationalId,
  rawB2bCompanyNationalIdType: (state) =>
    state.rawB2bCompanyNationalId ? state.rawB2bCompanyNationalIdType : undefined,
  rawOrderSuccessNotificationUrl: (state) => state.rawOrderSuccessNotificationUrl,
  rawOrderErrorNotificationUrl: (state) => state.rawOrderErrorNotificationUrl,
  rawPaymentNotificationUrl: (state) => state.rawPaymentNotificationUrl,
  rawErrorNotificationUrl: (state) => state.rawErrorNotificationUrl,
  rawRedirectUrl: (state, currentGetters) => {
    return currentGetters["purchase/purchase"]
      ? currentGetters["purchase/purchase"].redirect_url
      : state.rawRedirectUrl;
  },
  rawCancelUrl: (state, currentGetters) => {
    return currentGetters["purchase/purchase"]
      ? currentGetters["purchase/purchase"].cancel_url
      : state.rawCancelUrl;
  },
  rawBalancePaymentDate: (state, currentGetters) =>
    currentGetters["purchase/purchase"]
      ? currentGetters["purchase/purchase"].balance_payment_date
      : state.rawBalancePaymentDate,
  rawDeferredPaymentDate: (state, currentGetters) =>
    currentGetters["purchase/purchase"]
      ? currentGetters["purchase/purchase"].deferred_payment_date
      : state.rawDeferredPaymentDate,
  rawSecondInstallmentPaymentDate: (state, currentGetters) =>
    currentGetters["purchase/purchase"]
      ? currentGetters["purchase/purchase"].second_installment_payment_date
      : state.rawSecondInstallmentPaymentDate,
  rawMetadata: (state) => state.rawMetadata,
  started: (state) => state.started,
  paymentMethodRegistered: (state) => state.paymentMethodRegistered,
  rawExternalCheckoutValidation: (state) => state.rawExternalCheckoutValidation,
  // If appIsSeminal is true, it means that we are coming from the merchant website.
  // Indeed:
  // - If state.isSeminal is false: it means that the URL is a 3DS redirection URL,
  //                                and the back set in the arg is_seminal that the
  //                                share is not seminal
  // - Otherwise:
  //   - If window.iframe_id is not null: we are called from the plugin
  //   - Else:
  //     - Direct call of the front: redirectUrl must not be null
  //     - Payment link: we do not retrieve the purchase and redirectUrl must be null.
  // - Purchase Link: redirect_url from parameters OR from purchase
  rawIsSeminal: (state, currentGetters) => {
    // Case when "1" or "true" is sent from back
    if (toBoolean(state.rawIsSeminal)) return true;
    // Case when false is explicitly passed
    if (state.rawIsSeminal === false) return false;
    // Case when undefined
    return (
      !!currentGetters.rawIframeId ||
      !!state.rawRedirectUrl ||
      (currentGetters.rawIsPurchaseLink === true && !!currentGetters.rawRedirectUrl)
    );
  },
  rawSDKMobile: (state) => state.rawSDKMobile,
  rawIsPurchaseLink: (state) => state.rawIsPurchaseLink,
  rawIsAnalysisInProgress: (state) => state.rawIsAnalysisInProgress,
  rawLanguage: (state) => state.rawLanguage,
  rawPaymentMethodId: (state) => state.rawPaymentMethodId,
  // Split use only
  shares: (state) => (amountCents) => {
    if (!state.shares?.length) return [];

    const defaultPledgerAmountCents = Math.floor(amountCents / state.shares.length);

    const shares = state.shares.map((share) => {
      const new_share = { ...share };
      if (!new_share.forced_amount) {
        new_share.amount_cents = ShareTypes.LEADER ? 0 : defaultPledgerAmountCents;
      }
      return new_share;
    });

    const totalPledgerAmountCents = _sumBy(shares, "amount_cents");
    // leader
    const error = amountCents - totalPledgerAmountCents;

    const leaderIndex = _findIndex(shares, ({ share_type }) => {
      return share_type === ShareTypes.LEADER;
    });
    shares[leaderIndex].amount_cents += error;

    return shares;
  },
  getRawObject: (state, currentGetters) => {
    const rawObject = {};
    Object.keys(currentGetters).forEach((key) => {
      if (key.startsWith("raw")) {
        rawObject[key] = currentGetters[key];
      }
    });
    return rawObject;
  },
  mustVerifyPhoneNumber: (state, currentGetters, rootState, rootGetters) => {
    return (
      rootGetters[`${SHARE}/mustVerifyPhoneNumber`] ||
      rootGetters[`${ORDER}/mustVerifyPhoneNumber`]
    );
  },
  rawFullAddressAvailable: (state) => {
    const { rawAddress } = state;
    return !!(
      rawAddress?.street &&
      rawAddress?.zipcode &&
      rawAddress?.city &&
      rawAddress?.country
    );
  },
  isAddressValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    if (merchant?.address_is_mandatory) {
      return currentGetters.rawFullAddressAvailable;
    }
    return true;
  },
  isPhoneNumberValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    if (merchant?.phone_number_is_mandatory) {
      return !!(
        currentGetters.rawPhoneNumber &&
        validatePhoneNumber(
          currentGetters.rawPhoneNumber,
          currentGetters.rawAddress?.country
        )
      );
    }
    return true;
  },
  isBirthZipcodeValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthZipcodeValid(
      merchant,
      currentGetters.rawBirthCountry,
      currentGetters.rawBirthZipcode
    );
  },
  isBirthCityValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthCityValid(merchant, currentGetters.rawBirthCity);
  },
  isBirthCountryValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthCountryValid(merchant, currentGetters.rawBirthCountry);
  },
  isNationalityValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isNationalityValid(merchant, currentGetters.rawNationality);
  },
  isBirthDateValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthDateValid(merchant, currentGetters.rawBirthDate);
  },
  isBirthplaceValid: (state, currentGetters) => {
    return (
      currentGetters.isBirthZipcodeValid &&
      currentGetters.isBirthCityValid &&
      currentGetters.isBirthCountryValid
    );
  },
  isCivilityValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isCivilityValid(merchant, currentGetters.rawCivility);
  },
  isBirthLastNameValid: (state, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthLastNameValid(merchant, currentGetters.rawBirthLastName);
  },
  /**
   * This function aims at validating fields in terms of requirements or formatting
   * which will automatically lead to a server error without update
   * These fields can be updated directly on the client side through the FunnelInformationMissing form
   * @returns {ValidationError} when validation failed
   */
  validateFunctionalFields: (state, currentGetters) => {
    if (!currentGetters.isAddressValid) {
      return ValidationErrors.INVALID_ADDRESS;
    }
    if (!currentGetters.isPhoneNumberValid) {
      return ValidationErrors.INVALID_PHONE_NUMBER;
    }
    if (!currentGetters.isBirthDateValid) {
      return ValidationErrors.INVALID_BIRTH_DATE;
    }
    if (!currentGetters.isBirthCountryValid) {
      return ValidationErrors.INVALID_BIRTH_COUNTRY;
    }
    if (!currentGetters.isBirthZipcodeValid) {
      return ValidationErrors.INVALID_BIRTH_ZIPCODE;
    }
    if (!currentGetters.isBirthCityValid) {
      return ValidationErrors.INVALID_BIRTH_CITY;
    }
    if (!currentGetters.isBirthLastNameValid) {
      return ValidationErrors.INVALID_BIRTH_LAST_NAME;
    }
    if (!currentGetters.isCivilityValid) {
      return ValidationErrors.INVALID_CIVILITY;
    }
    if (!currentGetters.isNationalityValid) {
      return ValidationErrors.INVALID_NATIONALITY;
    }
    return null;
  },
  /**
   * This function aims at validating fields
   * which will automatically lead to a server error
   * These fields cannot be updated directly on the client side
   * @returns {ValidationError} when validation failed
   */
  validateTechnicalFields: (state, currentGetters, rootState, rootGetters) => {
    const purchase = rootGetters[`${PURCHASE}/purchase`];
    const title = rootGetters[`${PURCHASE}/title`];
    const amountCents = currentGetters.rawAmountCents;
    const email = currentGetters.rawEmail;
    const reference = currentGetters.rawReference;

    if (purchase) return ValidationErrors.ALREADY_EXISTS;
    if (!validateAmount(amountCents)) {
      return ValidationErrors.INVALID_AMOUNT;
    }
    if (!validateEmail(email)) {
      return ValidationErrors.INVALID_EMAIL;
    }
    if (!reference) return ValidationErrors.NO_REFERENCE;
    if (!title) return ValidationErrors.NO_TITLE;
    return null;
  },
};

export default getters;
