export default {
  de_DE: [
    {
      code: "AF",
      label: "Afghanistan",
    },
    {
      code: "EG",
      label: "Ägypten",
    },
    {
      code: "AL",
      label: "Albanien",
    },
    {
      code: "DZ",
      label: "Algerien",
    },
    {
      code: "AS",
      label: "Amerikanisch-Samoa",
    },
    {
      code: "AD",
      label: "Andorra",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguilla",
    },
    {
      code: "AQ",
      label: "Antarktis",
    },
    {
      code: "AG",
      label: "Antigua und Barbuda",
    },
    {
      code: "GQ",
      label: "Äquatorialguinea",
    },
    {
      code: "AR",
      label: "Argentinien",
    },
    {
      code: "AM",
      label: "Armenien",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AZ",
      label: "Aserbaidschan",
    },
    {
      code: "ET",
      label: "Äthiopien",
    },
    {
      code: "AU",
      label: "Australien",
    },
    {
      code: "BS",
      label: "Bahamas",
    },
    {
      code: "BH",
      label: "Bahrain",
    },
    {
      code: "BD",
      label: "Bangladesch",
    },
    {
      code: "BB",
      label: "Barbados",
    },
    {
      code: "BE",
      label: "Belgien",
    },
    {
      code: "BZ",
      label: "Belize",
    },
    {
      code: "BJ",
      label: "Benin",
    },
    {
      code: "BM",
      label: "Bermuda",
    },
    {
      code: "BT",
      label: "Bhutan",
    },
    {
      code: "BO",
      label: "Bolivien",
    },
    {
      code: "BA",
      label: "Bosnien und Herzegowina",
    },
    {
      code: "BW",
      label: "Botswana",
    },
    {
      code: "BV",
      label: "Bouvetinsel",
    },
    {
      code: "BR",
      label: "Brasilien",
    },
    {
      code: "IO",
      label: "Britisches Territorium im Indischen Ozean",
    },
    {
      code: "BN",
      label: "Brunei",
    },
    {
      code: "BG",
      label: "Bulgarien",
    },
    {
      code: "BF",
      label: "Burkina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "CL",
      label: "Chile",
    },
    {
      code: "CN",
      label: "China",
    },
    {
      code: "CK",
      label: "Cookinseln",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "DK",
      label: "Dänemark",
    },
    {
      code: "CD",
      label: "Demokratische Republik Kongo",
    },
    {
      code: "DE",
      label: "Deutschland",
    },
    {
      code: "DM",
      label: "Dominica",
    },
    {
      code: "DO",
      label: "Dominikanische Republik",
    },
    {
      code: "DJ",
      label: "Dschibuti",
    },
    {
      code: "EC",
      label: "Ecuador",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "CI",
      label: "Elfenbeinküste",
    },
    {
      code: "ER",
      label: "Eritrea",
    },
    {
      code: "EE",
      label: "Estland",
    },
    {
      code: "FK",
      label: "Falklandinseln",
    },
    {
      code: "FO",
      label: "Färöer",
    },
    {
      code: "FJ",
      label: "Fidschi",
    },
    {
      code: "FI",
      label: "Finnland",
    },
    {
      code: "FR",
      label: "Frankreich",
    },
    {
      code: "GF",
      label: "Französisch-Guayana",
    },
    {
      code: "PF",
      label: "Französisch-Polynesien",
    },
    {
      code: "TF",
      label: "Französische Süd- und Antarktisgebiete",
    },
    {
      code: "GA",
      label: "Gabun",
    },
    {
      code: "GM",
      label: "Gambia",
    },
    {
      code: "GE",
      label: "Georgien",
    },
    {
      code: "GH",
      label: "Ghana",
    },
    {
      code: "GI",
      label: "Gibraltar",
    },
    {
      code: "GD",
      label: "Grenada",
    },
    {
      code: "GR",
      label: "Griechenland",
    },
    {
      code: "GL",
      label: "Grönland",
    },
    {
      code: "GP",
      label: "Guadeloupe",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GN",
      label: "Guinea",
    },
    {
      code: "GW",
      label: "Guinea-Bissau",
    },
    {
      code: "GY",
      label: "Guyana",
    },
    {
      code: "HT",
      label: "Haiti",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "IN",
      label: "Indien",
    },
    {
      code: "ID",
      label: "Indonesien",
    },
    {
      code: "IQ",
      label: "Irak",
    },
    {
      code: "IR",
      label: "Iran",
    },
    {
      code: "IE",
      label: "Irland",
    },
    {
      code: "IS",
      label: "Island",
    },
    {
      code: "IL",
      label: "Israel",
    },
    {
      code: "IT",
      label: "Italien",
    },
    {
      code: "JM",
      label: "Jamaika",
    },
    {
      code: "JP",
      label: "Japan",
    },
    {
      code: "YE",
      label: "Jemen",
    },
    {
      code: "JO",
      label: "Jordanien",
    },
    {
      code: "KY",
      label: "Kaimaninseln",
    },
    {
      code: "KH",
      label: "Kambodscha",
    },
    {
      code: "CM",
      label: "Kamerun",
    },
    {
      code: "CA",
      label: "Kanada",
    },
    {
      code: "CV",
      label: "Kap Verde",
    },
    {
      code: "KZ",
      label: "Kasachstan",
    },
    {
      code: "QA",
      label: "Katar",
    },
    {
      code: "KE",
      label: "Kenia",
    },
    {
      code: "KG",
      label: "Kirgisistan",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "CC",
      label: "Kokosinseln",
    },
    {
      code: "CO",
      label: "Kolumbien",
    },
    {
      code: "KM",
      label: "Komoren",
    },
    {
      code: "CG",
      label: "Kongo",
    },
    {
      code: "HR",
      label: "Kroatien",
    },
    {
      code: "CU",
      label: "Kuba",
    },
    {
      code: "KW",
      label: "Kuwait",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LS",
      label: "Lesotho",
    },
    {
      code: "LV",
      label: "Lettland",
    },
    {
      code: "LB",
      label: "Libanon",
    },
    {
      code: "LR",
      label: "Liberia",
    },
    {
      code: "LY",
      label: "Libyen",
    },
    {
      code: "LI",
      label: "Liechtenstein",
    },
    {
      code: "LT",
      label: "Litauen",
    },
    {
      code: "LU",
      label: "Luxemburg",
    },
    {
      code: "MG",
      label: "Madagaskar",
    },
    {
      code: "MW",
      label: "Malawi",
    },
    {
      code: "MY",
      label: "Malaysia",
    },
    {
      code: "MV",
      label: "Malediven",
    },
    {
      code: "ML",
      label: "Mali",
    },
    {
      code: "MT",
      label: "Malta",
    },
    {
      code: "MA",
      label: "Marokko",
    },
    {
      code: "MH",
      label: "Marshallinseln",
    },
    {
      code: "MQ",
      label: "Martinique",
    },
    {
      code: "MR",
      label: "Mauretanien",
    },
    {
      code: "MU",
      label: "Mauritius",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "Mexiko",
    },
    {
      code: "FM",
      label: "Mikronesien",
    },
    {
      code: "MD",
      label: "Moldawien",
    },
    {
      code: "MC",
      label: "Monaco",
    },
    {
      code: "MN",
      label: "Mongolei",
    },
    {
      code: "ME",
      label: "Montenegro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "MZ",
      label: "Mosambik",
    },
    {
      code: "MM",
      label: "Myanmar",
    },
    {
      code: "NA",
      label: "Namibia",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NP",
      label: "Nepal",
    },
    {
      code: "NC",
      label: "Neukaledonien",
    },
    {
      code: "NZ",
      label: "Neuseeland",
    },
    {
      code: "NI",
      label: "Nicaragua",
    },
    {
      code: "NL",
      label: "Niederlande",
    },
    {
      code: "NE",
      label: "Niger",
    },
    {
      code: "NG",
      label: "Nigeria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "KP",
      label: "Nordkorea",
    },
    {
      code: "MP",
      label: "Nördliche Marianen",
    },
    {
      code: "MK",
      label: "Nordmazedonien",
    },
    {
      code: "NF",
      label: "Norfolkinsel",
    },
    {
      code: "NO",
      label: "Norwegen",
    },
    {
      code: "OM",
      label: "Oman",
    },
    {
      code: "AT",
      label: "Österreich",
    },
    {
      code: "TL",
      label: "Osttimor",
    },
    {
      code: "PK",
      label: "Pakistan",
    },
    {
      code: "PS",
      label: "Palästina",
    },
    {
      code: "PW",
      label: "Palau",
    },
    {
      code: "PA",
      label: "Panama",
    },
    {
      code: "PY",
      label: "Paraguay",
    },
    {
      code: "PE",
      label: "Peru",
    },
    {
      code: "PH",
      label: "Philippinen",
    },
    {
      code: "PL",
      label: "Polen",
    },
    {
      code: "PT",
      label: "Portugal",
    },
    {
      code: "PR",
      label: "Puerto Rico",
    },
    {
      code: "RE",
      label: "Réunion",
    },
    {
      code: "RW",
      label: "Ruanda",
    },
    {
      code: "RO",
      label: "Rumänien",
    },
    {
      code: "RU",
      label: "Russland",
    },
    {
      code: "SB",
      label: "Salomonen",
    },
    {
      code: "ZM",
      label: "Sambia",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "SM",
      label: "San Marino",
    },
    {
      code: "ST",
      label: "São Tomé und Príncipe",
    },
    {
      code: "SA",
      label: "Saudi-Arabien",
    },
    {
      code: "SE",
      label: "Schweden",
    },
    {
      code: "CH",
      label: "Schweiz",
    },
    {
      code: "SN",
      label: "Senegal",
    },
    {
      code: "RS",
      label: "Serbien",
    },
    {
      code: "SC",
      label: "Seychellen",
    },
    {
      code: "SL",
      label: "Sierra Leone",
    },
    {
      code: "ZW",
      label: "Simbabwe",
    },
    {
      code: "SG",
      label: "Singapur",
    },
    {
      code: "SK",
      label: "Slowakei",
    },
    {
      code: "SI",
      label: "Slowenien",
    },
    {
      code: "SO",
      label: "Somalia",
    },
    {
      code: "ES",
      label: "Spanien",
    },
    {
      code: "LK",
      label: "Sri Lanka",
    },
    {
      code: "KN",
      label: "St. Kitts und Nevis",
    },
    {
      code: "LC",
      label: "St. Lucia",
    },
    {
      code: "VC",
      label: "St. Vincent und die Grenadinen",
    },
    {
      code: "ZA",
      label: "Südafrika",
    },
    {
      code: "SD",
      label: "Sudan",
    },
    {
      code: "KR",
      label: "Südkorea",
    },
    {
      code: "SS",
      label: "Südsudan",
    },
    {
      code: "SR",
      label: "Suriname",
    },
    {
      code: "SZ",
      label: "Swasiland",
    },
    {
      code: "SY",
      label: "Syrien",
    },
    {
      code: "TJ",
      label: "Tadschikistan",
    },
    {
      code: "TW",
      label: "Taiwan",
    },
    {
      code: "TZ",
      label: "Tansania",
    },
    {
      code: "TH",
      label: "Thailand",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TK",
      label: "Tokelau",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TT",
      label: "Trinidad und Tobago",
    },
    {
      code: "TD",
      label: "Tschad",
    },
    {
      code: "CZ",
      label: "Tschechische Republik",
    },
    {
      code: "TN",
      label: "Tunesien",
    },
    {
      code: "TR",
      label: "Türkei",
    },
    {
      code: "TM",
      label: "Turkmenistan",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UG",
      label: "Uganda",
    },
    {
      code: "UA",
      label: "Ukraine",
    },
    {
      code: "HU",
      label: "Ungarn",
    },
    {
      code: "UY",
      label: "Uruguay",
    },
    {
      code: "UZ",
      label: "Usbekistan",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VA",
      label: "Vatikanstadt",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "AE",
      label: "Vereinigte Arabische Emirate",
    },
    {
      code: "US",
      label: "Vereinigte Staaten",
    },
    {
      code: "GB",
      label: "Vereinigtes Königreich",
    },
    {
      code: "VN",
      label: "Vietnam",
    },
    {
      code: "CX",
      label: "Weihnachtsinsel",
    },
    {
      code: "BY",
      label: "Weißrussland",
    },
    {
      code: "EH",
      label: "Westsahara",
    },
    {
      code: "CF",
      label: "Zentralafrikanische Republik",
    },
    {
      code: "CY",
      label: "Zypern",
    },
  ],
  en_GB: [
    {
      code: "AF",
      label: "Afghanistan",
    },
    {
      code: "AL",
      label: "Albania",
    },
    {
      code: "DZ",
      label: "Algeria",
    },
    {
      code: "AS",
      label: "American Samoa",
    },
    {
      code: "AD",
      label: "Andorra",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguilla",
    },
    {
      code: "AQ",
      label: "Antarctica",
    },
    {
      code: "AG",
      label: "Antigua and Barbuda",
    },
    {
      code: "AR",
      label: "Argentina",
    },
    {
      code: "AM",
      label: "Armenia",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AU",
      label: "Australia",
    },
    {
      code: "AT",
      label: "Austria",
    },
    {
      code: "AZ",
      label: "Azerbaijan",
    },
    {
      code: "BS",
      label: "Bahamas",
    },
    {
      code: "BH",
      label: "Bahrain",
    },
    {
      code: "BD",
      label: "Bangladesh",
    },
    {
      code: "BB",
      label: "Barbados",
    },
    {
      code: "BY",
      label: "Belarus",
    },
    {
      code: "BE",
      label: "Belgium",
    },
    {
      code: "BZ",
      label: "Belize",
    },
    {
      code: "BJ",
      label: "Benin",
    },
    {
      code: "BM",
      label: "Bermuda",
    },
    {
      code: "BT",
      label: "Bhutan",
    },
    {
      code: "BO",
      label: "Bolivia",
    },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
    },
    {
      code: "BW",
      label: "Botswana",
    },
    {
      code: "BV",
      label: "Bouvet Island",
    },
    {
      code: "BR",
      label: "Brazil",
    },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
    },
    {
      code: "BN",
      label: "Brunei",
    },
    {
      code: "BG",
      label: "Bulgaria",
    },
    {
      code: "BF",
      label: "Burkina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "KH",
      label: "Cambodia",
    },
    {
      code: "CM",
      label: "Cameroon",
    },
    {
      code: "CA",
      label: "Canada",
    },
    {
      code: "CV",
      label: "Cape Verde",
    },
    {
      code: "KY",
      label: "Cayman Islands",
    },
    {
      code: "CF",
      label: "Central African Republic",
    },
    {
      code: "TD",
      label: "Chad",
    },
    {
      code: "CL",
      label: "Chile",
    },
    {
      code: "CN",
      label: "China",
    },
    {
      code: "CX",
      label: "Christmas Island",
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
    },
    {
      code: "CO",
      label: "Colombia",
    },
    {
      code: "KM",
      label: "Comoros",
    },
    {
      code: "CK",
      label: "Cook Islands",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "HR",
      label: "Croatia",
    },
    {
      code: "CU",
      label: "Cuba",
    },
    {
      code: "CY",
      label: "Cyprus",
    },
    {
      code: "CZ",
      label: "Czech Republic",
    },
    {
      code: "CD",
      label: "Democratic Republic of the Congo",
    },
    {
      code: "DK",
      label: "Denmark",
    },
    {
      code: "DJ",
      label: "Djibouti",
    },
    {
      code: "DM",
      label: "Dominica",
    },
    {
      code: "DO",
      label: "Dominican Republic",
    },
    {
      code: "TL",
      label: "East Timor",
    },
    {
      code: "EC",
      label: "Ecuador",
    },
    {
      code: "EG",
      label: "Egypt",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "GQ",
      label: "Equatorial Guinea",
    },
    {
      code: "ER",
      label: "Eritrea",
    },
    {
      code: "EE",
      label: "Estonia",
    },
    {
      code: "SZ",
      label: "Eswatini",
    },
    {
      code: "ET",
      label: "Ethiopia",
    },
    {
      code: "FK",
      label: "Falkland Islands",
    },
    {
      code: "FO",
      label: "Faroe Islands",
    },
    {
      code: "FJ",
      label: "Fiji",
    },
    {
      code: "FI",
      label: "Finland",
    },
    {
      code: "FR",
      label: "France",
    },
    {
      code: "GF",
      label: "French Guiana",
    },
    {
      code: "PF",
      label: "French Polynesia",
    },
    {
      code: "TF",
      label: "French Southern Territories",
    },
    {
      code: "GA",
      label: "Gabon",
    },
    {
      code: "GM",
      label: "Gambia",
    },
    {
      code: "GE",
      label: "Georgia",
    },
    {
      code: "DE",
      label: "Germany",
    },
    {
      code: "GH",
      label: "Ghana",
    },
    {
      code: "GI",
      label: "Gibraltar",
    },
    {
      code: "GR",
      label: "Greece",
    },
    {
      code: "GL",
      label: "Greenland",
    },
    {
      code: "GD",
      label: "Grenada",
    },
    {
      code: "GP",
      label: "Guadeloupe",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GN",
      label: "Guinea",
    },
    {
      code: "GW",
      label: "Guinea-Bissau",
    },
    {
      code: "GY",
      label: "Guyana",
    },
    {
      code: "HT",
      label: "Haiti",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "HU",
      label: "Hungary",
    },
    {
      code: "IS",
      label: "Iceland",
    },
    {
      code: "IN",
      label: "India",
    },
    {
      code: "ID",
      label: "Indonesia",
    },
    {
      code: "IR",
      label: "Iran",
    },
    {
      code: "IQ",
      label: "Iraq",
    },
    {
      code: "IE",
      label: "Ireland",
    },
    {
      code: "IL",
      label: "Israel",
    },
    {
      code: "IT",
      label: "Italy",
    },
    {
      code: "CI",
      label: "Ivory Coast",
    },
    {
      code: "JM",
      label: "Jamaica",
    },
    {
      code: "JP",
      label: "Japan",
    },
    {
      code: "JO",
      label: "Jordan",
    },
    {
      code: "KZ",
      label: "Kazakhstan",
    },
    {
      code: "KE",
      label: "Kenya",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "KW",
      label: "Kuwait",
    },
    {
      code: "KG",
      label: "Kyrgyzstan",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LV",
      label: "Latvia",
    },
    {
      code: "LB",
      label: "Lebanon",
    },
    {
      code: "LS",
      label: "Lesotho",
    },
    {
      code: "LR",
      label: "Liberia",
    },
    {
      code: "LY",
      label: "Libya",
    },
    {
      code: "LI",
      label: "Liechtenstein",
    },
    {
      code: "LT",
      label: "Lithuania",
    },
    {
      code: "LU",
      label: "Luxembourg",
    },
    {
      code: "MG",
      label: "Madagascar",
    },
    {
      code: "MW",
      label: "Malawi",
    },
    {
      code: "MY",
      label: "Malaysia",
    },
    {
      code: "MV",
      label: "Maldives",
    },
    {
      code: "ML",
      label: "Mali",
    },
    {
      code: "MT",
      label: "Malta",
    },
    {
      code: "MH",
      label: "Marshall Islands",
    },
    {
      code: "MQ",
      label: "Martinique",
    },
    {
      code: "MR",
      label: "Mauritania",
    },
    {
      code: "MU",
      label: "Mauritius",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "Mexico",
    },
    {
      code: "FM",
      label: "Micronesia",
    },
    {
      code: "MD",
      label: "Moldova",
    },
    {
      code: "MC",
      label: "Monaco",
    },
    {
      code: "MN",
      label: "Mongolia",
    },
    {
      code: "ME",
      label: "Montenegro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "MA",
      label: "Morocco",
    },
    {
      code: "MZ",
      label: "Mozambique",
    },
    {
      code: "MM",
      label: "Myanmar",
    },
    {
      code: "NA",
      label: "Namibia",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NP",
      label: "Nepal",
    },
    {
      code: "NL",
      label: "Netherlands",
    },
    {
      code: "NC",
      label: "New Caledonia",
    },
    {
      code: "NZ",
      label: "New Zealand",
    },
    {
      code: "NI",
      label: "Nicaragua",
    },
    {
      code: "NE",
      label: "Niger",
    },
    {
      code: "NG",
      label: "Nigeria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "NF",
      label: "Norfolk Island",
    },
    {
      code: "KP",
      label: "North Korea",
    },
    {
      code: "MK",
      label: "North Macedonia",
    },
    {
      code: "MP",
      label: "Northern Mariana Islands",
    },
    {
      code: "NO",
      label: "Norway",
    },
    {
      code: "OM",
      label: "Oman",
    },
    {
      code: "PK",
      label: "Pakistan",
    },
    {
      code: "PW",
      label: "Palau",
    },
    {
      code: "PA",
      label: "Panama",
    },
    {
      code: "PY",
      label: "Paraguay",
    },
    {
      code: "PE",
      label: "Peru",
    },
    {
      code: "PH",
      label: "Philippines",
    },
    {
      code: "PL",
      label: "Poland",
    },
    {
      code: "PT",
      label: "Portugal",
    },
    {
      code: "PR",
      label: "Puerto Rico",
    },
    {
      code: "QA",
      label: "Qatar",
    },
    {
      code: "CG",
      label: "Republic of the Congo",
    },
    {
      code: "RE",
      label: "Réunion",
    },
    {
      code: "RO",
      label: "Romania",
    },
    {
      code: "RU",
      label: "Russia",
    },
    {
      code: "RW",
      label: "Rwanda",
    },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
    },
    {
      code: "LC",
      label: "Saint Lucia",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "SM",
      label: "San Marino",
    },
    {
      code: "ST",
      label: "São Tomé and Príncipe",
    },
    {
      code: "SA",
      label: "Saudi Arabia",
    },
    {
      code: "SN",
      label: "Senegal",
    },
    {
      code: "RS",
      label: "Serbia",
    },
    {
      code: "SC",
      label: "Seychelles",
    },
    {
      code: "SL",
      label: "Sierra Leone",
    },
    {
      code: "SG",
      label: "Singapore",
    },
    {
      code: "SK",
      label: "Slovakia",
    },
    {
      code: "SI",
      label: "Slovenia",
    },
    {
      code: "SB",
      label: "Solomon Islands",
    },
    {
      code: "SO",
      label: "Somalia",
    },
    {
      code: "ZA",
      label: "South Africa",
    },
    {
      code: "KR",
      label: "South Korea",
    },
    {
      code: "SS",
      label: "South Sudan",
    },
    {
      code: "ES",
      label: "Spain",
    },
    {
      code: "LK",
      label: "Sri Lanka",
    },
    {
      code: "PS",
      label: "State of Palestine",
    },
    {
      code: "SD",
      label: "Sudan",
    },
    {
      code: "SR",
      label: "Suriname",
    },
    {
      code: "SE",
      label: "Sweden",
    },
    {
      code: "CH",
      label: "Switzerland",
    },
    {
      code: "SY",
      label: "Syria",
    },
    {
      code: "TW",
      label: "Taiwan",
    },
    {
      code: "TJ",
      label: "Tajikistan",
    },
    {
      code: "TZ",
      label: "Tanzania",
    },
    {
      code: "TH",
      label: "Thailand",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TK",
      label: "Tokelau",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TT",
      label: "Trinidad and Tobago",
    },
    {
      code: "TN",
      label: "Tunisia",
    },
    {
      code: "TR",
      label: "Turkey",
    },
    {
      code: "TM",
      label: "Turkmenistan",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UG",
      label: "Uganda",
    },
    {
      code: "UA",
      label: "Ukraine",
    },
    {
      code: "AE",
      label: "United Arab Emirates",
    },
    {
      code: "GB",
      label: "United Kingdom",
    },
    {
      code: "US",
      label: "United States",
    },
    {
      code: "UY",
      label: "Uruguay",
    },
    {
      code: "UZ",
      label: "Uzbekistan",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VA",
      label: "Vatican City",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "VN",
      label: "Vietnam",
    },
    {
      code: "EH",
      label: "Western Sahara",
    },
    {
      code: "YE",
      label: "Yemen",
    },
    {
      code: "ZM",
      label: "Zambia",
    },
    {
      code: "ZW",
      label: "Zimbabwe",
    },
  ],
  es_ES: [
    {
      code: "AF",
      label: "Afganistán",
    },
    {
      code: "AL",
      label: "Albania",
    },
    {
      code: "DE",
      label: "Alemania",
    },
    {
      code: "AD",
      label: "Andorra",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguila",
    },
    {
      code: "AQ",
      label: "Antártida",
    },
    {
      code: "AG",
      label: "Antigua y Barbuda",
    },
    {
      code: "SA",
      label: "Arabia Saudita",
    },
    {
      code: "DZ",
      label: "Argelia",
    },
    {
      code: "AR",
      label: "Argentina",
    },
    {
      code: "AM",
      label: "Armenia",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AU",
      label: "Australia",
    },
    {
      code: "AT",
      label: "Austria",
    },
    {
      code: "AZ",
      label: "Azerbaiyán",
    },
    {
      code: "BS",
      label: "Bahamas",
    },
    {
      code: "BD",
      label: "Bangladés",
    },
    {
      code: "BB",
      label: "Barbados",
    },
    {
      code: "BH",
      label: "Baréin",
    },
    {
      code: "BE",
      label: "Bélgica",
    },
    {
      code: "BZ",
      label: "Belice",
    },
    {
      code: "BJ",
      label: "Benín",
    },
    {
      code: "BM",
      label: "Bermudas",
    },
    {
      code: "BY",
      label: "Bielorrusia",
    },
    {
      code: "BO",
      label: "Bolivia",
    },
    {
      code: "BA",
      label: "Bosnia y Herzegovina",
    },
    {
      code: "BW",
      label: "Botsuana",
    },
    {
      code: "BR",
      label: "Brasil",
    },
    {
      code: "BN",
      label: "Brunéi",
    },
    {
      code: "BG",
      label: "Bulgaria",
    },
    {
      code: "BF",
      label: "Burkina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "BT",
      label: "Bután",
    },
    {
      code: "CV",
      label: "Cabo Verde",
    },
    {
      code: "KH",
      label: "Camboya",
    },
    {
      code: "CM",
      label: "Camerún",
    },
    {
      code: "CA",
      label: "Canadá",
    },
    {
      code: "QA",
      label: "Catar",
    },
    {
      code: "TD",
      label: "Chad",
    },
    {
      code: "CL",
      label: "Chile",
    },
    {
      code: "CN",
      label: "China",
    },
    {
      code: "CY",
      label: "Chipre",
    },
    {
      code: "VA",
      label: "Ciudad del Vaticano",
    },
    {
      code: "CO",
      label: "Colombia",
    },
    {
      code: "KM",
      label: "Comoras",
    },
    {
      code: "KP",
      label: "Corea del Norte",
    },
    {
      code: "KR",
      label: "Corea del Sur",
    },
    {
      code: "CI",
      label: "Costa de Marfil",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "HR",
      label: "Croacia",
    },
    {
      code: "CU",
      label: "Cuba",
    },
    {
      code: "DK",
      label: "Dinamarca",
    },
    {
      code: "DM",
      label: "Dominica",
    },
    {
      code: "EC",
      label: "Ecuador",
    },
    {
      code: "EG",
      label: "Egipto",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "AE",
      label: "Emiratos Árabes Unidos",
    },
    {
      code: "ER",
      label: "Eritrea",
    },
    {
      code: "SK",
      label: "Eslovaquia",
    },
    {
      code: "SI",
      label: "Eslovenia",
    },
    {
      code: "ES",
      label: "España",
    },
    {
      code: "PS",
      label: "Estado de Palestina",
    },
    {
      code: "US",
      label: "Estados Unidos",
    },
    {
      code: "EE",
      label: "Estonia",
    },
    {
      code: "SZ",
      label: "Esuatini",
    },
    {
      code: "ET",
      label: "Etiopía",
    },
    {
      code: "PH",
      label: "Filipinas",
    },
    {
      code: "FI",
      label: "Finlandia",
    },
    {
      code: "FJ",
      label: "Fiyi",
    },
    {
      code: "FR",
      label: "Francia",
    },
    {
      code: "GA",
      label: "Gabón",
    },
    {
      code: "GM",
      label: "Gambia",
    },
    {
      code: "GE",
      label: "Georgia",
    },
    {
      code: "GH",
      label: "Ghana",
    },
    {
      code: "GI",
      label: "Gibraltar",
    },
    {
      code: "GD",
      label: "Granada",
    },
    {
      code: "GR",
      label: "Grecia",
    },
    {
      code: "GL",
      label: "Groenlandia",
    },
    {
      code: "GP",
      label: "Guadalupe",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GF",
      label: "Guayana Francesa",
    },
    {
      code: "GN",
      label: "Guinea",
    },
    {
      code: "GQ",
      label: "Guinea Ecuatorial",
    },
    {
      code: "GW",
      label: "Guinea-Bisáu",
    },
    {
      code: "GY",
      label: "Guyana",
    },
    {
      code: "HT",
      label: "Haití",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "HU",
      label: "Hungría",
    },
    {
      code: "IN",
      label: "India",
    },
    {
      code: "ID",
      label: "Indonesia",
    },
    {
      code: "IQ",
      label: "Irak",
    },
    {
      code: "IR",
      label: "Irán",
    },
    {
      code: "IE",
      label: "Irlanda",
    },
    {
      code: "BV",
      label: "Isla Bouvet",
    },
    {
      code: "CX",
      label: "Isla de Navidad",
    },
    {
      code: "NF",
      label: "Isla Norfolk",
    },
    {
      code: "IS",
      label: "Islandia",
    },
    {
      code: "KY",
      label: "Islas Caimán",
    },
    {
      code: "CC",
      label: "Islas Cocos",
    },
    {
      code: "CK",
      label: "Islas Cook",
    },
    {
      code: "FO",
      label: "Islas Feroe",
    },
    {
      code: "FK",
      label: "Islas Malvinas",
    },
    {
      code: "MP",
      label: "Islas Marianas del Norte",
    },
    {
      code: "MH",
      label: "Islas Marshall",
    },
    {
      code: "SB",
      label: "Islas Salomón",
    },
    {
      code: "IL",
      label: "Israel",
    },
    {
      code: "IT",
      label: "Italia",
    },
    {
      code: "JM",
      label: "Jamaica",
    },
    {
      code: "JP",
      label: "Japón",
    },
    {
      code: "JO",
      label: "Jordania",
    },
    {
      code: "KZ",
      label: "Kazajistán",
    },
    {
      code: "KE",
      label: "Kenia",
    },
    {
      code: "KG",
      label: "Kirguistán",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "KW",
      label: "Kuwait",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LS",
      label: "Lesoto",
    },
    {
      code: "LV",
      label: "Letonia",
    },
    {
      code: "LB",
      label: "Líbano",
    },
    {
      code: "LR",
      label: "Liberia",
    },
    {
      code: "LY",
      label: "Libia",
    },
    {
      code: "LI",
      label: "Liechtenstein",
    },
    {
      code: "LT",
      label: "Lituania",
    },
    {
      code: "LU",
      label: "Luxemburgo",
    },
    {
      code: "MK",
      label: "Macedonia del Norte",
    },
    {
      code: "MG",
      label: "Madagascar",
    },
    {
      code: "MY",
      label: "Malasia",
    },
    {
      code: "MW",
      label: "Malaui",
    },
    {
      code: "MV",
      label: "Maldivas",
    },
    {
      code: "ML",
      label: "Malí",
    },
    {
      code: "MT",
      label: "Malta",
    },
    {
      code: "MA",
      label: "Marruecos",
    },
    {
      code: "MQ",
      label: "Martinica",
    },
    {
      code: "MU",
      label: "Mauricio",
    },
    {
      code: "MR",
      label: "Mauritania",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "México",
    },
    {
      code: "FM",
      label: "Micronesia",
    },
    {
      code: "MD",
      label: "Moldavia",
    },
    {
      code: "MC",
      label: "Mónaco",
    },
    {
      code: "MN",
      label: "Mongolia",
    },
    {
      code: "ME",
      label: "Montenegro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "MZ",
      label: "Mozambique",
    },
    {
      code: "MM",
      label: "Myanmar",
    },
    {
      code: "NA",
      label: "Namibia",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NP",
      label: "Nepal",
    },
    {
      code: "NI",
      label: "Nicaragua",
    },
    {
      code: "NE",
      label: "Níger",
    },
    {
      code: "NG",
      label: "Nigeria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "NO",
      label: "Noruega",
    },
    {
      code: "NC",
      label: "Nueva Caledonia",
    },
    {
      code: "NZ",
      label: "Nueva Zelanda",
    },
    {
      code: "OM",
      label: "Omán",
    },
    {
      code: "NL",
      label: "Países Bajos",
    },
    {
      code: "PK",
      label: "Pakistán",
    },
    {
      code: "PW",
      label: "Palaos",
    },
    {
      code: "PA",
      label: "Panamá",
    },
    {
      code: "PY",
      label: "Paraguay",
    },
    {
      code: "PE",
      label: "Perú",
    },
    {
      code: "PF",
      label: "Polinesia Francesa",
    },
    {
      code: "PL",
      label: "Polonia",
    },
    {
      code: "PT",
      label: "Portugal",
    },
    {
      code: "PR",
      label: "Puerto Rico",
    },
    {
      code: "GB",
      label: "Reino Unido",
    },
    {
      code: "CF",
      label: "República Centroafricana",
    },
    {
      code: "CZ",
      label: "República Checa",
    },
    {
      code: "CG",
      label: "República del Congo",
    },
    {
      code: "CD",
      label: "República Democrática del Congo",
    },
    {
      code: "DO",
      label: "República Dominicana",
    },
    {
      code: "RE",
      label: "Reunión",
    },
    {
      code: "RW",
      label: "Ruanda",
    },
    {
      code: "RO",
      label: "Rumanía",
    },
    {
      code: "RU",
      label: "Rusia",
    },
    {
      code: "EH",
      label: "Sahara Occidental",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "AS",
      label: "Samoa Americana",
    },
    {
      code: "KN",
      label: "San Cristóbal y Nieves",
    },
    {
      code: "SM",
      label: "San Marino",
    },
    {
      code: "VC",
      label: "San Vicente y las Granadinas",
    },
    {
      code: "LC",
      label: "Santa Lucía",
    },
    {
      code: "ST",
      label: "Santo Tomé y Príncipe",
    },
    {
      code: "SN",
      label: "Senegal",
    },
    {
      code: "RS",
      label: "Serbia",
    },
    {
      code: "SC",
      label: "Seychelles",
    },
    {
      code: "SL",
      label: "Sierra Leona",
    },
    {
      code: "SG",
      label: "Singapur",
    },
    {
      code: "SY",
      label: "Siria",
    },
    {
      code: "SO",
      label: "Somalia",
    },
    {
      code: "LK",
      label: "Sri Lanka",
    },
    {
      code: "ZA",
      label: "Sudáfrica",
    },
    {
      code: "SD",
      label: "Sudán",
    },
    {
      code: "SS",
      label: "Sudán del Sur",
    },
    {
      code: "SE",
      label: "Suecia",
    },
    {
      code: "CH",
      label: "Suiza",
    },
    {
      code: "SR",
      label: "Surinam",
    },
    {
      code: "TH",
      label: "Tailandia",
    },
    {
      code: "TW",
      label: "Taiwán",
    },
    {
      code: "TZ",
      label: "Tanzania",
    },
    {
      code: "TJ",
      label: "Tayikistán",
    },
    {
      code: "IO",
      label: "Territorio Británico del Océano Índico",
    },
    {
      code: "TF",
      label: "Tierras Australes y Antárticas Francesas",
    },
    {
      code: "TL",
      label: "Timor Oriental",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TK",
      label: "Tokelau",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TT",
      label: "Trinidad y Tobago",
    },
    {
      code: "TN",
      label: "Túnez",
    },
    {
      code: "TM",
      label: "Turkmenistán",
    },
    {
      code: "TR",
      label: "Turquía",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UA",
      label: "Ucrania",
    },
    {
      code: "UG",
      label: "Uganda",
    },
    {
      code: "UY",
      label: "Uruguay",
    },
    {
      code: "UZ",
      label: "Uzbekistán",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "VN",
      label: "Vietnam",
    },
    {
      code: "YE",
      label: "Yemen",
    },
    {
      code: "DJ",
      label: "Yibuti",
    },
    {
      code: "ZM",
      label: "Zambia",
    },
    {
      code: "ZW",
      label: "Zimbabue",
    },
  ],
  fr_FR: [
    {
      code: "AF",
      label: "Afghanistan",
    },
    {
      code: "ZA",
      label: "Afrique du Sud",
    },
    {
      code: "AL",
      label: "Albanie",
    },
    {
      code: "DZ",
      label: "Algérie",
    },
    {
      code: "DE",
      label: "Allemagne",
    },
    {
      code: "AD",
      label: "Andorre",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguilla",
    },
    {
      code: "AQ",
      label: "Antarctique",
    },
    {
      code: "AG",
      label: "Antigua-et-Barbuda",
    },
    {
      code: "SA",
      label: "Arabie saoudite",
    },
    {
      code: "AR",
      label: "Argentine",
    },
    {
      code: "AM",
      label: "Arménie",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AU",
      label: "Australie",
    },
    {
      code: "AT",
      label: "Autriche",
    },
    {
      code: "AZ",
      label: "Azerbaïdjan",
    },
    {
      code: "BS",
      label: "Bahamas",
    },
    {
      code: "BH",
      label: "Bahreïn",
    },
    {
      code: "BD",
      label: "Bangladesh",
    },
    {
      code: "BB",
      label: "Barbade",
    },
    {
      code: "BE",
      label: "Belgique",
    },
    {
      code: "BZ",
      label: "Belize",
    },
    {
      code: "BJ",
      label: "Bénin",
    },
    {
      code: "BM",
      label: "Bermudes",
    },
    {
      code: "BT",
      label: "Bhoutan",
    },
    {
      code: "BY",
      label: "Biélorussie",
    },
    {
      code: "MM",
      label: "Birmanie",
    },
    {
      code: "BO",
      label: "Bolivie",
    },
    {
      code: "BA",
      label: "Bosnie-Herzégovine",
    },
    {
      code: "BW",
      label: "Botswana",
    },
    {
      code: "BR",
      label: "Brésil",
    },
    {
      code: "BN",
      label: "Brunei",
    },
    {
      code: "BG",
      label: "Bulgarie",
    },
    {
      code: "BF",
      label: "Burkina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "KH",
      label: "Cambodge",
    },
    {
      code: "CM",
      label: "Cameroun",
    },
    {
      code: "CA",
      label: "Canada",
    },
    {
      code: "CV",
      label: "Cap-Vert",
    },
    {
      code: "CL",
      label: "Chili",
    },
    {
      code: "CN",
      label: "Chine",
    },
    {
      code: "CY",
      label: "Chypre",
    },
    {
      code: "VA",
      label: "Cité du Vatican",
    },
    {
      code: "CO",
      label: "Colombie",
    },
    {
      code: "KM",
      label: "Comores",
    },
    {
      code: "KP",
      label: "Corée du Nord",
    },
    {
      code: "KR",
      label: "Corée du Sud",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "CI",
      label: "Côte d'Ivoire",
    },
    {
      code: "HR",
      label: "Croatie",
    },
    {
      code: "CU",
      label: "Cuba",
    },
    {
      code: "DK",
      label: "Danemark",
    },
    {
      code: "DJ",
      label: "Djibouti",
    },
    {
      code: "DM",
      label: "Dominique",
    },
    {
      code: "EG",
      label: "Égypte",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "AE",
      label: "Émirats arabes unis",
    },
    {
      code: "EC",
      label: "Équateur",
    },
    {
      code: "ER",
      label: "Érythrée",
    },
    {
      code: "ES",
      label: "Espagne",
    },
    {
      code: "EE",
      label: "Estonie",
    },
    {
      code: "SZ",
      label: "Eswatini",
    },
    {
      code: "PS",
      label: "État de Palestine",
    },
    {
      code: "US",
      label: "États-Unis",
    },
    {
      code: "ET",
      label: "Éthiopie",
    },
    {
      code: "FJ",
      label: "Fidji",
    },
    {
      code: "FI",
      label: "Finlande",
    },
    {
      code: "FR",
      label: "France",
    },
    {
      code: "GA",
      label: "Gabon",
    },
    {
      code: "GM",
      label: "Gambie",
    },
    {
      code: "GE",
      label: "Géorgie",
    },
    {
      code: "GH",
      label: "Ghana",
    },
    {
      code: "GI",
      label: "Gibraltar",
    },
    {
      code: "GR",
      label: "Grèce",
    },
    {
      code: "GD",
      label: "Grenade",
    },
    {
      code: "GL",
      label: "Groenland",
    },
    {
      code: "GP",
      label: "Guadeloupe",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GN",
      label: "Guinée",
    },
    {
      code: "GQ",
      label: "Guinée équatoriale",
    },
    {
      code: "GW",
      label: "Guinée-Bissau",
    },
    {
      code: "GY",
      label: "Guyana",
    },
    {
      code: "GF",
      label: "Guyane française",
    },
    {
      code: "HT",
      label: "Haïti",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "HU",
      label: "Hongrie",
    },
    {
      code: "BV",
      label: "Île Bouvet",
    },
    {
      code: "CX",
      label: "Île Christmas",
    },
    {
      code: "NF",
      label: "Île Norfolk",
    },
    {
      code: "KY",
      label: "Îles Caïmans",
    },
    {
      code: "CC",
      label: "Îles Cocos",
    },
    {
      code: "CK",
      label: "Îles Cook",
    },
    {
      code: "FK",
      label: "Îles Falkland",
    },
    {
      code: "FO",
      label: "Îles Féroé",
    },
    {
      code: "MP",
      label: "Îles Mariannes du Nord",
    },
    {
      code: "MH",
      label: "Îles Marshall",
    },
    {
      code: "SB",
      label: "Îles Salomon",
    },
    {
      code: "IN",
      label: "Inde",
    },
    {
      code: "ID",
      label: "Indonésie",
    },
    {
      code: "IQ",
      label: "Irak",
    },
    {
      code: "IR",
      label: "Iran",
    },
    {
      code: "IE",
      label: "Irlande",
    },
    {
      code: "IS",
      label: "Islande",
    },
    {
      code: "IL",
      label: "Israël",
    },
    {
      code: "IT",
      label: "Italie",
    },
    {
      code: "JM",
      label: "Jamaïque",
    },
    {
      code: "JP",
      label: "Japon",
    },
    {
      code: "JO",
      label: "Jordanie",
    },
    {
      code: "KZ",
      label: "Kazakhstan",
    },
    {
      code: "KE",
      label: "Kenya",
    },
    {
      code: "KG",
      label: "Kirghizistan",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "KW",
      label: "Koweït",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LS",
      label: "Lesotho",
    },
    {
      code: "LV",
      label: "Lettonie",
    },
    {
      code: "LB",
      label: "Liban",
    },
    {
      code: "LR",
      label: "Liberia",
    },
    {
      code: "LY",
      label: "Libye",
    },
    {
      code: "LI",
      label: "Liechtenstein",
    },
    {
      code: "LT",
      label: "Lituanie",
    },
    {
      code: "LU",
      label: "Luxembourg",
    },
    {
      code: "MK",
      label: "Macédoine du Nord",
    },
    {
      code: "MG",
      label: "Madagascar",
    },
    {
      code: "MY",
      label: "Malaisie",
    },
    {
      code: "MW",
      label: "Malawi",
    },
    {
      code: "MV",
      label: "Maldives",
    },
    {
      code: "ML",
      label: "Mali",
    },
    {
      code: "MT",
      label: "Malte",
    },
    {
      code: "MA",
      label: "Maroc",
    },
    {
      code: "MQ",
      label: "Martinique",
    },
    {
      code: "MU",
      label: "Maurice",
    },
    {
      code: "MR",
      label: "Mauritanie",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "Mexique",
    },
    {
      code: "FM",
      label: "Micronésie",
    },
    {
      code: "MD",
      label: "Moldavie",
    },
    {
      code: "MC",
      label: "Monaco",
    },
    {
      code: "MN",
      label: "Mongolie",
    },
    {
      code: "ME",
      label: "Monténégro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "MZ",
      label: "Mozambique",
    },
    {
      code: "NA",
      label: "Namibie",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NP",
      label: "Népal",
    },
    {
      code: "NI",
      label: "Nicaragua",
    },
    {
      code: "NE",
      label: "Niger",
    },
    {
      code: "NG",
      label: "Nigéria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "NO",
      label: "Norvège",
    },
    {
      code: "NC",
      label: "Nouvelle-Calédonie",
    },
    {
      code: "NZ",
      label: "Nouvelle-Zélande",
    },
    {
      code: "OM",
      label: "Oman",
    },
    {
      code: "UG",
      label: "Ouganda",
    },
    {
      code: "UZ",
      label: "Ouzbékistan",
    },
    {
      code: "PK",
      label: "Pakistan",
    },
    {
      code: "PW",
      label: "Palaos",
    },
    {
      code: "PA",
      label: "Panama",
    },
    {
      code: "PY",
      label: "Paraguay",
    },
    {
      code: "NL",
      label: "Pays-Bas",
    },
    {
      code: "PE",
      label: "Pérou",
    },
    {
      code: "PH",
      label: "Philippines",
    },
    {
      code: "PL",
      label: "Pologne",
    },
    {
      code: "PF",
      label: "Polynésie française",
    },
    {
      code: "PR",
      label: "Porto Rico",
    },
    {
      code: "PT",
      label: "Portugal",
    },
    {
      code: "QA",
      label: "Qatar",
    },
    {
      code: "CF",
      label: "République centrafricaine",
    },
    {
      code: "CD",
      label: "République démocratique du Congo",
    },
    {
      code: "DO",
      label: "République dominicaine",
    },
    {
      code: "CG",
      label: "République du Congo",
    },
    {
      code: "CZ",
      label: "République tchèque",
    },
    {
      code: "RE",
      label: "Réunion",
    },
    {
      code: "RO",
      label: "Roumanie",
    },
    {
      code: "GB",
      label: "Royaume-Uni",
    },
    {
      code: "RU",
      label: "Russie",
    },
    {
      code: "RW",
      label: "Rwanda",
    },
    {
      code: "EH",
      label: "Sahara occidental",
    },
    {
      code: "KN",
      label: "Saint-Christophe-et-Niévès",
    },
    {
      code: "SM",
      label: "Saint-Marin",
    },
    {
      code: "VC",
      label: "Saint-Vincent-et-les-Grenadines",
    },
    {
      code: "LC",
      label: "Sainte-Lucie",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "AS",
      label: "Samoa Américaines",
    },
    {
      code: "ST",
      label: "Sao Tomé-et-Principe",
    },
    {
      code: "SN",
      label: "Sénégal",
    },
    {
      code: "RS",
      label: "Serbie",
    },
    {
      code: "SC",
      label: "Seychelles",
    },
    {
      code: "SL",
      label: "Sierra Leone",
    },
    {
      code: "SG",
      label: "Singapour",
    },
    {
      code: "SK",
      label: "Slovaquie",
    },
    {
      code: "SI",
      label: "Slovénie",
    },
    {
      code: "SO",
      label: "Somalie",
    },
    {
      code: "SD",
      label: "Soudan",
    },
    {
      code: "SS",
      label: "Soudan du Sud",
    },
    {
      code: "LK",
      label: "Sri Lanka",
    },
    {
      code: "SE",
      label: "Suède",
    },
    {
      code: "CH",
      label: "Suisse",
    },
    {
      code: "SR",
      label: "Suriname",
    },
    {
      code: "SY",
      label: "Syrie",
    },
    {
      code: "TJ",
      label: "Tadjikistan",
    },
    {
      code: "TW",
      label: "Taïwan",
    },
    {
      code: "TZ",
      label: "Tanzanie",
    },
    {
      code: "TD",
      label: "Tchad",
    },
    {
      code: "TF",
      label: "Terres australes françaises",
    },
    {
      code: "IO",
      label: "Territoire britannique de l'océan Indien",
    },
    {
      code: "TH",
      label: "Thaïlande",
    },
    {
      code: "TL",
      label: "Timor oriental",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TK",
      label: "Tokelau",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TT",
      label: "Trinité-et-Tobago",
    },
    {
      code: "TN",
      label: "Tunisie",
    },
    {
      code: "TM",
      label: "Turkménistan",
    },
    {
      code: "TR",
      label: "Turquie",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UA",
      label: "Ukraine",
    },
    {
      code: "UY",
      label: "Uruguay",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "VN",
      label: "Vietnam",
    },
    {
      code: "YE",
      label: "Yémen",
    },
    {
      code: "ZM",
      label: "Zambie",
    },
    {
      code: "ZW",
      label: "Zimbabwe",
    },
  ],
  it_IT: [
    {
      code: "AF",
      label: "Afghanistan",
    },
    {
      code: "AL",
      label: "Albania",
    },
    {
      code: "DZ",
      label: "Algeria",
    },
    {
      code: "AD",
      label: "Andorra",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguilla",
    },
    {
      code: "AQ",
      label: "Antartide",
    },
    {
      code: "AG",
      label: "Antigua e Barbuda",
    },
    {
      code: "SA",
      label: "Arabia Saudita",
    },
    {
      code: "AR",
      label: "Argentina",
    },
    {
      code: "AM",
      label: "Armenia",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AU",
      label: "Australia",
    },
    {
      code: "AT",
      label: "Austria",
    },
    {
      code: "AZ",
      label: "Azerbaigian",
    },
    {
      code: "BS",
      label: "Bahamas",
    },
    {
      code: "BH",
      label: "Bahrein",
    },
    {
      code: "BD",
      label: "Bangladesh",
    },
    {
      code: "BB",
      label: "Barbados",
    },
    {
      code: "BE",
      label: "Belgio",
    },
    {
      code: "BZ",
      label: "Belize",
    },
    {
      code: "BJ",
      label: "Benin",
    },
    {
      code: "BM",
      label: "Bermuda",
    },
    {
      code: "BT",
      label: "Bhutan",
    },
    {
      code: "BY",
      label: "Bielorussia",
    },
    {
      code: "BO",
      label: "Bolivia",
    },
    {
      code: "BA",
      label: "Bosnia ed Erzegovina",
    },
    {
      code: "BW",
      label: "Botswana",
    },
    {
      code: "BR",
      label: "Brasile",
    },
    {
      code: "BN",
      label: "Brunei",
    },
    {
      code: "BG",
      label: "Bulgaria",
    },
    {
      code: "BF",
      label: "Burkina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "KH",
      label: "Cambogia",
    },
    {
      code: "CM",
      label: "Camerun",
    },
    {
      code: "CA",
      label: "Canada",
    },
    {
      code: "CV",
      label: "Capo Verde",
    },
    {
      code: "TD",
      label: "Ciad",
    },
    {
      code: "CL",
      label: "Cile",
    },
    {
      code: "CN",
      label: "Cina",
    },
    {
      code: "CY",
      label: "Cipro",
    },
    {
      code: "VA",
      label: "Città del Vaticano",
    },
    {
      code: "CO",
      label: "Colombia",
    },
    {
      code: "KM",
      label: "Comore",
    },
    {
      code: "KP",
      label: "Corea del Nord",
    },
    {
      code: "KR",
      label: "Corea del Sud",
    },
    {
      code: "CI",
      label: "Costa d'Avorio",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "HR",
      label: "Croazia",
    },
    {
      code: "CU",
      label: "Cuba",
    },
    {
      code: "DK",
      label: "Danimarca",
    },
    {
      code: "DM",
      label: "Dominica",
    },
    {
      code: "EC",
      label: "Ecuador",
    },
    {
      code: "EG",
      label: "Egitto",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "AE",
      label: "Emirati Arabi Uniti",
    },
    {
      code: "ER",
      label: "Eritrea",
    },
    {
      code: "EE",
      label: "Estonia",
    },
    {
      code: "ET",
      label: "Etiopia",
    },
    {
      code: "FJ",
      label: "Figi",
    },
    {
      code: "PH",
      label: "Filippine",
    },
    {
      code: "FI",
      label: "Finlandia",
    },
    {
      code: "FR",
      label: "Francia",
    },
    {
      code: "GA",
      label: "Gabon",
    },
    {
      code: "GM",
      label: "Gambia",
    },
    {
      code: "GE",
      label: "Georgia",
    },
    {
      code: "DE",
      label: "Germania",
    },
    {
      code: "GH",
      label: "Ghana",
    },
    {
      code: "JM",
      label: "Giamaica",
    },
    {
      code: "JP",
      label: "Giappone",
    },
    {
      code: "GI",
      label: "Gibilterra",
    },
    {
      code: "DJ",
      label: "Gibuti",
    },
    {
      code: "JO",
      label: "Giordania",
    },
    {
      code: "GR",
      label: "Grecia",
    },
    {
      code: "GD",
      label: "Grenada",
    },
    {
      code: "GL",
      label: "Groenlandia",
    },
    {
      code: "GP",
      label: "Guadalupa",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GN",
      label: "Guinea",
    },
    {
      code: "GQ",
      label: "Guinea Equatoriale",
    },
    {
      code: "GW",
      label: "Guinea-Bissau",
    },
    {
      code: "GY",
      label: "Guyana",
    },
    {
      code: "GF",
      label: "Guyana francese",
    },
    {
      code: "HT",
      label: "Haiti",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "IN",
      label: "India",
    },
    {
      code: "ID",
      label: "Indonesia",
    },
    {
      code: "IR",
      label: "Iran",
    },
    {
      code: "IQ",
      label: "Iraq",
    },
    {
      code: "IE",
      label: "Irlanda",
    },
    {
      code: "IS",
      label: "Islanda",
    },
    {
      code: "BV",
      label: "Isola Bouvet",
    },
    {
      code: "CX",
      label: "Isola di Natale",
    },
    {
      code: "NF",
      label: "Isola Norfolk",
    },
    {
      code: "KY",
      label: "Isole Cayman",
    },
    {
      code: "CC",
      label: "Isole Cocos",
    },
    {
      code: "CK",
      label: "Isole Cook",
    },
    {
      code: "FO",
      label: "Isole Fær Øer",
    },
    {
      code: "FK",
      label: "Isole Falkland",
    },
    {
      code: "MP",
      label: "Isole Marianne Settentrionali",
    },
    {
      code: "MH",
      label: "Isole Marshall",
    },
    {
      code: "SB",
      label: "Isole Salomone",
    },
    {
      code: "IL",
      label: "Israele",
    },
    {
      code: "IT",
      label: "Italia",
    },
    {
      code: "KZ",
      label: "Kazakistan",
    },
    {
      code: "KE",
      label: "Kenya",
    },
    {
      code: "KG",
      label: "Kirghizistan",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "KW",
      label: "Kuwait",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LS",
      label: "Lesotho",
    },
    {
      code: "LV",
      label: "Lettonia",
    },
    {
      code: "LB",
      label: "Libano",
    },
    {
      code: "LR",
      label: "Liberia",
    },
    {
      code: "LY",
      label: "Libia",
    },
    {
      code: "LI",
      label: "Liechtenstein",
    },
    {
      code: "LT",
      label: "Lituania",
    },
    {
      code: "LU",
      label: "Lussemburgo",
    },
    {
      code: "MK",
      label: "Macedonia del Nord",
    },
    {
      code: "MG",
      label: "Madagascar",
    },
    {
      code: "MW",
      label: "Malawi",
    },
    {
      code: "MV",
      label: "Maldive",
    },
    {
      code: "MY",
      label: "Malesia",
    },
    {
      code: "ML",
      label: "Mali",
    },
    {
      code: "MT",
      label: "Malta",
    },
    {
      code: "MA",
      label: "Marocco",
    },
    {
      code: "MQ",
      label: "Martinica",
    },
    {
      code: "MR",
      label: "Mauritania",
    },
    {
      code: "MU",
      label: "Mauritius",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "Messico",
    },
    {
      code: "FM",
      label: "Micronesia",
    },
    {
      code: "MD",
      label: "Moldavia",
    },
    {
      code: "MC",
      label: "Monaco",
    },
    {
      code: "MN",
      label: "Mongolia",
    },
    {
      code: "ME",
      label: "Montenegro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "MZ",
      label: "Mozambico",
    },
    {
      code: "MM",
      label: "Myanmar",
    },
    {
      code: "NA",
      label: "Namibia",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NP",
      label: "Nepal",
    },
    {
      code: "NI",
      label: "Nicaragua",
    },
    {
      code: "NE",
      label: "Niger",
    },
    {
      code: "NG",
      label: "Nigeria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "NO",
      label: "Norvegia",
    },
    {
      code: "NC",
      label: "Nuova Caledonia",
    },
    {
      code: "NZ",
      label: "Nuova Zelanda",
    },
    {
      code: "OM",
      label: "Oman",
    },
    {
      code: "NL",
      label: "Paesi Bassi",
    },
    {
      code: "PK",
      label: "Pakistan",
    },
    {
      code: "PW",
      label: "Palau",
    },
    {
      code: "PA",
      label: "Panama",
    },
    {
      code: "PY",
      label: "Paraguay",
    },
    {
      code: "PE",
      label: "Perù",
    },
    {
      code: "PF",
      label: "Polinesia francese",
    },
    {
      code: "PL",
      label: "Polonia",
    },
    {
      code: "PR",
      label: "Porto Rico",
    },
    {
      code: "PT",
      label: "Portogallo",
    },
    {
      code: "QA",
      label: "Qatar",
    },
    {
      code: "GB",
      label: "Regno Unito",
    },
    {
      code: "CZ",
      label: "Repubblica Ceca",
    },
    {
      code: "CF",
      label: "Repubblica Centrafricana",
    },
    {
      code: "CG",
      label: "Repubblica del Congo",
    },
    {
      code: "CD",
      label: "Repubblica Democratica del Congo",
    },
    {
      code: "DO",
      label: "Repubblica Dominicana",
    },
    {
      code: "RE",
      label: "Riunione",
    },
    {
      code: "RO",
      label: "Romania",
    },
    {
      code: "RW",
      label: "Ruanda",
    },
    {
      code: "RU",
      label: "Russia",
    },
    {
      code: "EH",
      label: "Sahara Occidentale",
    },
    {
      code: "KN",
      label: "Saint Kitts e Nevis",
    },
    {
      code: "VC",
      label: "Saint Vincent e Grenadine",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "AS",
      label: "Samoa Americane",
    },
    {
      code: "SM",
      label: "San Marino",
    },
    {
      code: "LC",
      label: "Santa Lucia",
    },
    {
      code: "ST",
      label: "São Tomé e Príncipe",
    },
    {
      code: "SN",
      label: "Senegal",
    },
    {
      code: "RS",
      label: "Serbia",
    },
    {
      code: "SC",
      label: "Seychelles",
    },
    {
      code: "SL",
      label: "Sierra Leone",
    },
    {
      code: "SG",
      label: "Singapore",
    },
    {
      code: "SY",
      label: "Siria",
    },
    {
      code: "SK",
      label: "Slovacchia",
    },
    {
      code: "SI",
      label: "Slovenia",
    },
    {
      code: "SO",
      label: "Somalia",
    },
    {
      code: "ES",
      label: "Spagna",
    },
    {
      code: "LK",
      label: "Sri Lanka",
    },
    {
      code: "US",
      label: "Stati Uniti d'America",
    },
    {
      code: "PS",
      label: "Stato di Palestina",
    },
    {
      code: "SS",
      label: "Sud Sudan",
    },
    {
      code: "ZA",
      label: "Sudafrica",
    },
    {
      code: "SD",
      label: "Sudan",
    },
    {
      code: "SR",
      label: "Suriname",
    },
    {
      code: "SE",
      label: "Svezia",
    },
    {
      code: "CH",
      label: "Svizzera",
    },
    {
      code: "SZ",
      label: "Swaziland",
    },
    {
      code: "TJ",
      label: "Tagikistan",
    },
    {
      code: "TW",
      label: "Taiwan",
    },
    {
      code: "TZ",
      label: "Tanzania",
    },
    {
      code: "TF",
      label: "Territori Francesi del Sud",
    },
    {
      code: "IO",
      label: "Territorio britannico dell'Oceano Indiano",
    },
    {
      code: "TH",
      label: "Thailandia",
    },
    {
      code: "TL",
      label: "Timor Est",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TK",
      label: "Tokelau",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TT",
      label: "Trinidad e Tobago",
    },
    {
      code: "TN",
      label: "Tunisia",
    },
    {
      code: "TR",
      label: "Turchia",
    },
    {
      code: "TM",
      label: "Turkmenistan",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UA",
      label: "Ucraina",
    },
    {
      code: "UG",
      label: "Uganda",
    },
    {
      code: "HU",
      label: "Ungheria",
    },
    {
      code: "UY",
      label: "Uruguay",
    },
    {
      code: "UZ",
      label: "Uzbekistan",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "VN",
      label: "Vietnam",
    },
    {
      code: "YE",
      label: "Yemen",
    },
    {
      code: "ZM",
      label: "Zambia",
    },
    {
      code: "ZW",
      label: "Zimbabwe",
    },
  ],
  nl_NL: [
    {
      code: "AF",
      label: "Afghanistan",
    },
    {
      code: "AL",
      label: "Albanië",
    },
    {
      code: "DZ",
      label: "Algerije",
    },
    {
      code: "AS",
      label: "Amerikaans-Samoa",
    },
    {
      code: "AD",
      label: "Andorra",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguilla",
    },
    {
      code: "AQ",
      label: "Antarctica",
    },
    {
      code: "AG",
      label: "Antigua en Barbuda",
    },
    {
      code: "AR",
      label: "Argentinië",
    },
    {
      code: "AM",
      label: "Armenië",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AU",
      label: "Australië",
    },
    {
      code: "AZ",
      label: "Azerbeidzjan",
    },
    {
      code: "BS",
      label: "Bahama's",
    },
    {
      code: "BH",
      label: "Bahrein",
    },
    {
      code: "BD",
      label: "Bangladesh",
    },
    {
      code: "BB",
      label: "Barbados",
    },
    {
      code: "BE",
      label: "België",
    },
    {
      code: "BZ",
      label: "Belize",
    },
    {
      code: "BJ",
      label: "Benin",
    },
    {
      code: "BM",
      label: "Bermuda",
    },
    {
      code: "BT",
      label: "Bhutan",
    },
    {
      code: "BO",
      label: "Bolivië",
    },
    {
      code: "BA",
      label: "Bosnië en Herzegovina",
    },
    {
      code: "BW",
      label: "Botswana",
    },
    {
      code: "BV",
      label: "Bouveteiland",
    },
    {
      code: "BR",
      label: "Brazilië",
    },
    {
      code: "IO",
      label: "Brits Indische Oceaanterritorium",
    },
    {
      code: "BN",
      label: "Brunei",
    },
    {
      code: "BG",
      label: "Bulgarije",
    },
    {
      code: "BF",
      label: "Burkina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "KH",
      label: "Cambodja",
    },
    {
      code: "CA",
      label: "Canada",
    },
    {
      code: "CF",
      label: "Centraal-Afrikaanse Republiek",
    },
    {
      code: "CL",
      label: "Chili",
    },
    {
      code: "CN",
      label: "China",
    },
    {
      code: "CX",
      label: "Christmaseiland",
    },
    {
      code: "CC",
      label: "Cocoseilanden",
    },
    {
      code: "CO",
      label: "Colombia",
    },
    {
      code: "KM",
      label: "Comoren",
    },
    {
      code: "CK",
      label: "Cookeilanden",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "CU",
      label: "Cuba",
    },
    {
      code: "CY",
      label: "Cyprus",
    },
    {
      code: "CD",
      label: "Democratische Republiek Congo",
    },
    {
      code: "DK",
      label: "Denemarken",
    },
    {
      code: "DJ",
      label: "Djibouti",
    },
    {
      code: "DM",
      label: "Dominica",
    },
    {
      code: "DO",
      label: "Dominicaanse Republiek",
    },
    {
      code: "DE",
      label: "Duitsland",
    },
    {
      code: "EC",
      label: "Ecuador",
    },
    {
      code: "EG",
      label: "Egypte",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "GQ",
      label: "Equatoriaal-Guinea",
    },
    {
      code: "ER",
      label: "Eritrea",
    },
    {
      code: "EE",
      label: "Estland",
    },
    {
      code: "SZ",
      label: "Eswatini",
    },
    {
      code: "ET",
      label: "Ethiopië",
    },
    {
      code: "FO",
      label: "Faeröer",
    },
    {
      code: "FK",
      label: "Falklandeilanden",
    },
    {
      code: "FJ",
      label: "Fiji",
    },
    {
      code: "PH",
      label: "Filipijnen",
    },
    {
      code: "FI",
      label: "Finland",
    },
    {
      code: "FR",
      label: "Frankrijk",
    },
    {
      code: "GF",
      label: "Frans-Guyana",
    },
    {
      code: "PF",
      label: "Frans-Polynesië",
    },
    {
      code: "TF",
      label: "Franse Zuidelijke en Antarctische Gebieden",
    },
    {
      code: "GA",
      label: "Gabon",
    },
    {
      code: "GM",
      label: "Gambia",
    },
    {
      code: "GE",
      label: "Georgië",
    },
    {
      code: "GH",
      label: "Ghana",
    },
    {
      code: "GI",
      label: "Gibraltar",
    },
    {
      code: "GD",
      label: "Grenada",
    },
    {
      code: "GR",
      label: "Griekenland",
    },
    {
      code: "GL",
      label: "Groenland",
    },
    {
      code: "GP",
      label: "Guadeloupe",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GN",
      label: "Guinee",
    },
    {
      code: "GW",
      label: "Guinee-Bissau",
    },
    {
      code: "GY",
      label: "Guyana",
    },
    {
      code: "HT",
      label: "Haïti",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "HU",
      label: "Hongarije",
    },
    {
      code: "IE",
      label: "Ierland",
    },
    {
      code: "IS",
      label: "IJsland",
    },
    {
      code: "IN",
      label: "India",
    },
    {
      code: "ID",
      label: "Indonesië",
    },
    {
      code: "IQ",
      label: "Irak",
    },
    {
      code: "IR",
      label: "Iran",
    },
    {
      code: "IL",
      label: "Israël",
    },
    {
      code: "IT",
      label: "Italië",
    },
    {
      code: "CI",
      label: "Ivoorkust",
    },
    {
      code: "JM",
      label: "Jamaica",
    },
    {
      code: "JP",
      label: "Japan",
    },
    {
      code: "YE",
      label: "Jemen",
    },
    {
      code: "JO",
      label: "Jordanië",
    },
    {
      code: "KY",
      label: "Kaaimaneilanden",
    },
    {
      code: "CV",
      label: "Kaapverdië",
    },
    {
      code: "CM",
      label: "Kameroen",
    },
    {
      code: "KZ",
      label: "Kazachstan",
    },
    {
      code: "KE",
      label: "Kenia",
    },
    {
      code: "KG",
      label: "Kirgizië",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "KW",
      label: "Koeweit",
    },
    {
      code: "HR",
      label: "Kroatië",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LS",
      label: "Lesotho",
    },
    {
      code: "LV",
      label: "Letland",
    },
    {
      code: "LB",
      label: "Libanon",
    },
    {
      code: "LR",
      label: "Liberia",
    },
    {
      code: "LY",
      label: "Libië",
    },
    {
      code: "LI",
      label: "Liechtenstein",
    },
    {
      code: "LT",
      label: "Litouwen",
    },
    {
      code: "LU",
      label: "Luxemburg",
    },
    {
      code: "MG",
      label: "Madagaskar",
    },
    {
      code: "MW",
      label: "Malawi",
    },
    {
      code: "MV",
      label: "Maldiven",
    },
    {
      code: "MY",
      label: "Maleisië",
    },
    {
      code: "ML",
      label: "Mali",
    },
    {
      code: "MT",
      label: "Malta",
    },
    {
      code: "MA",
      label: "Marokko",
    },
    {
      code: "MH",
      label: "Marshalleilanden",
    },
    {
      code: "MQ",
      label: "Martinique",
    },
    {
      code: "MR",
      label: "Mauritanië",
    },
    {
      code: "MU",
      label: "Mauritius",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "Mexico",
    },
    {
      code: "FM",
      label: "Micronesië",
    },
    {
      code: "MD",
      label: "Moldavië",
    },
    {
      code: "MC",
      label: "Monaco",
    },
    {
      code: "MN",
      label: "Mongolië",
    },
    {
      code: "ME",
      label: "Montenegro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "MZ",
      label: "Mozambique",
    },
    {
      code: "MM",
      label: "Myanmar",
    },
    {
      code: "NA",
      label: "Namibië",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NL",
      label: "Nederland",
    },
    {
      code: "NP",
      label: "Nepal",
    },
    {
      code: "NI",
      label: "Nicaragua",
    },
    {
      code: "NC",
      label: "Nieuw-Caledonië",
    },
    {
      code: "NZ",
      label: "Nieuw-Zeeland",
    },
    {
      code: "NE",
      label: "Niger",
    },
    {
      code: "NG",
      label: "Nigeria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "KP",
      label: "Noord-Korea",
    },
    {
      code: "MK",
      label: "Noord-Macedonië",
    },
    {
      code: "MP",
      label: "Noordelijke Marianen",
    },
    {
      code: "NO",
      label: "Noorwegen",
    },
    {
      code: "NF",
      label: "Norfolkeiland",
    },
    {
      code: "UG",
      label: "Oeganda",
    },
    {
      code: "UA",
      label: "Oekraïne",
    },
    {
      code: "UZ",
      label: "Oezbekistan",
    },
    {
      code: "OM",
      label: "Oman",
    },
    {
      code: "TL",
      label: "Oost-Timor",
    },
    {
      code: "AT",
      label: "Oostenrijk",
    },
    {
      code: "PK",
      label: "Pakistan",
    },
    {
      code: "PW",
      label: "Palau",
    },
    {
      code: "PA",
      label: "Panama",
    },
    {
      code: "PY",
      label: "Paraguay",
    },
    {
      code: "PE",
      label: "Peru",
    },
    {
      code: "PL",
      label: "Polen",
    },
    {
      code: "PT",
      label: "Portugal",
    },
    {
      code: "PR",
      label: "Puerto Rico",
    },
    {
      code: "QA",
      label: "Qatar",
    },
    {
      code: "CG",
      label: "Republiek Congo",
    },
    {
      code: "RE",
      label: "Réunion",
    },
    {
      code: "RO",
      label: "Roemenië",
    },
    {
      code: "RU",
      label: "Rusland",
    },
    {
      code: "RW",
      label: "Rwanda",
    },
    {
      code: "KN",
      label: "Saint Kitts en Nevis",
    },
    {
      code: "LC",
      label: "Saint Lucia",
    },
    {
      code: "VC",
      label: "Saint Vincent en de Grenadines",
    },
    {
      code: "SB",
      label: "Salomonseilanden",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "SM",
      label: "San Marino",
    },
    {
      code: "ST",
      label: "Sao Tomé en Principe",
    },
    {
      code: "SA",
      label: "Saoedi-Arabië",
    },
    {
      code: "SN",
      label: "Senegal",
    },
    {
      code: "RS",
      label: "Servië",
    },
    {
      code: "SC",
      label: "Seychellen",
    },
    {
      code: "SL",
      label: "Sierra Leone",
    },
    {
      code: "SG",
      label: "Singapore",
    },
    {
      code: "SI",
      label: "Slovenië",
    },
    {
      code: "SK",
      label: "Slowakije",
    },
    {
      code: "SD",
      label: "Soedan",
    },
    {
      code: "SO",
      label: "Somalië",
    },
    {
      code: "ES",
      label: "Spanje",
    },
    {
      code: "LK",
      label: "Sri Lanka",
    },
    {
      code: "PS",
      label: "Staat Palestina",
    },
    {
      code: "SR",
      label: "Suriname",
    },
    {
      code: "SY",
      label: "Syrië",
    },
    {
      code: "TJ",
      label: "Tadzjikistan",
    },
    {
      code: "TW",
      label: "Taiwan",
    },
    {
      code: "TZ",
      label: "Tanzania",
    },
    {
      code: "TH",
      label: "Thailand",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TK",
      label: "Tokelau",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TT",
      label: "Trinidad en Tobago",
    },
    {
      code: "TD",
      label: "Tsjaad",
    },
    {
      code: "CZ",
      label: "Tsjechië",
    },
    {
      code: "TN",
      label: "Tunesië",
    },
    {
      code: "TR",
      label: "Turkije",
    },
    {
      code: "TM",
      label: "Turkmenistan",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UY",
      label: "Uruguay",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VA",
      label: "Vaticaanstad",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "GB",
      label: "Verenigd Koninkrijk",
    },
    {
      code: "AE",
      label: "Verenigde Arabische Emiraten",
    },
    {
      code: "US",
      label: "Verenigde Staten",
    },
    {
      code: "VN",
      label: "Vietnam",
    },
    {
      code: "EH",
      label: "Westelijke Sahara",
    },
    {
      code: "BY",
      label: "Wit-Rusland",
    },
    {
      code: "ZM",
      label: "Zambia",
    },
    {
      code: "ZW",
      label: "Zimbabwe",
    },
    {
      code: "ZA",
      label: "Zuid-Afrika",
    },
    {
      code: "KR",
      label: "Zuid-Korea",
    },
    {
      code: "SS",
      label: "Zuid-Soedan",
    },
    {
      code: "SE",
      label: "Zweden",
    },
    {
      code: "CH",
      label: "Zwitserland",
    },
  ],
  pt_PT: [
    {
      code: "AF",
      label: "Afeganistão",
    },
    {
      code: "ZA",
      label: "África do Sul",
    },
    {
      code: "AL",
      label: "Albânia",
    },
    {
      code: "DE",
      label: "Alemanha",
    },
    {
      code: "AD",
      label: "Andorra",
    },
    {
      code: "AO",
      label: "Angola",
    },
    {
      code: "AI",
      label: "Anguila",
    },
    {
      code: "AQ",
      label: "Antártica",
    },
    {
      code: "AG",
      label: "Antígua e Barbuda",
    },
    {
      code: "SA",
      label: "Arábia Saudita",
    },
    {
      code: "DZ",
      label: "Argélia",
    },
    {
      code: "AR",
      label: "Argentina",
    },
    {
      code: "AM",
      label: "Arménia",
    },
    {
      code: "AW",
      label: "Aruba",
    },
    {
      code: "AU",
      label: "Austrália",
    },
    {
      code: "AT",
      label: "Áustria",
    },
    {
      code: "AZ",
      label: "Azerbaijão",
    },
    {
      code: "BS",
      label: "Bahamas",
    },
    {
      code: "BH",
      label: "Bahrein",
    },
    {
      code: "BD",
      label: "Bangladeche",
    },
    {
      code: "BB",
      label: "Barbados",
    },
    {
      code: "BE",
      label: "Bélgica",
    },
    {
      code: "BZ",
      label: "Belize",
    },
    {
      code: "BJ",
      label: "Benim",
    },
    {
      code: "BM",
      label: "Bermudas",
    },
    {
      code: "BY",
      label: "Bielorrússia",
    },
    {
      code: "BO",
      label: "Bolívia",
    },
    {
      code: "BA",
      label: "Bósnia e Herzegovina",
    },
    {
      code: "BW",
      label: "Botsuana",
    },
    {
      code: "BR",
      label: "Brasil",
    },
    {
      code: "BN",
      label: "Brunei",
    },
    {
      code: "BG",
      label: "Bulgária",
    },
    {
      code: "BF",
      label: "Burquina Faso",
    },
    {
      code: "BI",
      label: "Burundi",
    },
    {
      code: "BT",
      label: "Butão",
    },
    {
      code: "CV",
      label: "Cabo Verde",
    },
    {
      code: "CM",
      label: "Camarões",
    },
    {
      code: "KH",
      label: "Camboja",
    },
    {
      code: "CA",
      label: "Canadá",
    },
    {
      code: "QA",
      label: "Catar",
    },
    {
      code: "KZ",
      label: "Cazaquistão",
    },
    {
      code: "TD",
      label: "Chade",
    },
    {
      code: "CL",
      label: "Chile",
    },
    {
      code: "CN",
      label: "China",
    },
    {
      code: "CY",
      label: "Chipre",
    },
    {
      code: "VA",
      label: "Cidade do Vaticano",
    },
    {
      code: "CO",
      label: "Colômbia",
    },
    {
      code: "KM",
      label: "Comores",
    },
    {
      code: "KP",
      label: "Coreia do Norte",
    },
    {
      code: "KR",
      label: "Coreia do Sul",
    },
    {
      code: "CI",
      label: "Costa do Marfim",
    },
    {
      code: "CR",
      label: "Costa Rica",
    },
    {
      code: "HR",
      label: "Croácia",
    },
    {
      code: "CU",
      label: "Cuba",
    },
    {
      code: "DK",
      label: "Dinamarca",
    },
    {
      code: "DM",
      label: "Domínica",
    },
    {
      code: "EG",
      label: "Egito",
    },
    {
      code: "SV",
      label: "El Salvador",
    },
    {
      code: "AE",
      label: "Emirados Árabes Unidos",
    },
    {
      code: "EC",
      label: "Equador",
    },
    {
      code: "ER",
      label: "Eritreia",
    },
    {
      code: "SK",
      label: "Eslováquia",
    },
    {
      code: "SI",
      label: "Eslovénia",
    },
    {
      code: "ES",
      label: "Espanha",
    },
    {
      code: "SZ",
      label: "Essuatíni",
    },
    {
      code: "PS",
      label: "Estado da Palestina",
    },
    {
      code: "US",
      label: "Estados Unidos",
    },
    {
      code: "EE",
      label: "Estónia",
    },
    {
      code: "ET",
      label: "Etiópia",
    },
    {
      code: "FJ",
      label: "Fiji",
    },
    {
      code: "PH",
      label: "Filipinas",
    },
    {
      code: "FI",
      label: "Finlândia",
    },
    {
      code: "FR",
      label: "França",
    },
    {
      code: "GA",
      label: "Gabão",
    },
    {
      code: "GM",
      label: "Gâmbia",
    },
    {
      code: "GH",
      label: "Gana",
    },
    {
      code: "GE",
      label: "Geórgia",
    },
    {
      code: "GI",
      label: "Gibraltar",
    },
    {
      code: "GD",
      label: "Granada",
    },
    {
      code: "GR",
      label: "Grécia",
    },
    {
      code: "GL",
      label: "Gronelândia",
    },
    {
      code: "GP",
      label: "Guadalupe",
    },
    {
      code: "GU",
      label: "Guam",
    },
    {
      code: "GT",
      label: "Guatemala",
    },
    {
      code: "GY",
      label: "Guiana",
    },
    {
      code: "GF",
      label: "Guiana Francesa",
    },
    {
      code: "GN",
      label: "Guiné",
    },
    {
      code: "GQ",
      label: "Guiné Equatorial",
    },
    {
      code: "GW",
      label: "Guiné-Bissau",
    },
    {
      code: "HT",
      label: "Haiti",
    },
    {
      code: "HN",
      label: "Honduras",
    },
    {
      code: "HU",
      label: "Hungria",
    },
    {
      code: "YE",
      label: "Iémen",
    },
    {
      code: "BV",
      label: "Ilha Bouvet",
    },
    {
      code: "CX",
      label: "Ilha do Natal",
    },
    {
      code: "NF",
      label: "Ilha Norfolk",
    },
    {
      code: "KY",
      label: "Ilhas Caimão",
    },
    {
      code: "CC",
      label: "Ilhas Cocos",
    },
    {
      code: "CK",
      label: "Ilhas Cook",
    },
    {
      code: "FK",
      label: "Ilhas Falkland",
    },
    {
      code: "FO",
      label: "Ilhas Feroé",
    },
    {
      code: "MP",
      label: "Ilhas Marianas do Norte",
    },
    {
      code: "MH",
      label: "Ilhas Marshall",
    },
    {
      code: "SB",
      label: "Ilhas Salomão",
    },
    {
      code: "IN",
      label: "Índia",
    },
    {
      code: "ID",
      label: "Indonésia",
    },
    {
      code: "IR",
      label: "Irão",
    },
    {
      code: "IQ",
      label: "Iraque",
    },
    {
      code: "IE",
      label: "Irlanda",
    },
    {
      code: "IS",
      label: "Islândia",
    },
    {
      code: "IL",
      label: "Israel",
    },
    {
      code: "IT",
      label: "Itália",
    },
    {
      code: "JM",
      label: "Jamaica",
    },
    {
      code: "JP",
      label: "Japão",
    },
    {
      code: "DJ",
      label: "Jibuti",
    },
    {
      code: "JO",
      label: "Jordânia",
    },
    {
      code: "KI",
      label: "Kiribati",
    },
    {
      code: "KW",
      label: "Kuwait",
    },
    {
      code: "LA",
      label: "Laos",
    },
    {
      code: "LS",
      label: "Lesoto",
    },
    {
      code: "LV",
      label: "Letónia",
    },
    {
      code: "LB",
      label: "Líbano",
    },
    {
      code: "LR",
      label: "Libéria",
    },
    {
      code: "LY",
      label: "Líbia",
    },
    {
      code: "LI",
      label: "Listenstaine",
    },
    {
      code: "LT",
      label: "Lituânia",
    },
    {
      code: "LU",
      label: "Luxemburgo",
    },
    {
      code: "MK",
      label: "Macedónia do Norte",
    },
    {
      code: "MG",
      label: "Madagáscar",
    },
    {
      code: "MY",
      label: "Malásia",
    },
    {
      code: "MW",
      label: "Maláui",
    },
    {
      code: "MV",
      label: "Maldivas",
    },
    {
      code: "ML",
      label: "Mali",
    },
    {
      code: "MT",
      label: "Malta",
    },
    {
      code: "MA",
      label: "Marrocos",
    },
    {
      code: "MQ",
      label: "Martinica",
    },
    {
      code: "MU",
      label: "Maurícia",
    },
    {
      code: "MR",
      label: "Mauritânia",
    },
    {
      code: "YT",
      label: "Mayotte",
    },
    {
      code: "MX",
      label: "México",
    },
    {
      code: "MM",
      label: "Mianmar",
    },
    {
      code: "FM",
      label: "Micronésia",
    },
    {
      code: "MZ",
      label: "Moçambique",
    },
    {
      code: "MD",
      label: "Moldávia",
    },
    {
      code: "MC",
      label: "Mónaco",
    },
    {
      code: "MN",
      label: "Mongólia",
    },
    {
      code: "ME",
      label: "Montenegro",
    },
    {
      code: "MS",
      label: "Montserrat",
    },
    {
      code: "NA",
      label: "Namíbia",
    },
    {
      code: "NR",
      label: "Nauru",
    },
    {
      code: "NP",
      label: "Nepal",
    },
    {
      code: "NI",
      label: "Nicarágua",
    },
    {
      code: "NE",
      label: "Níger",
    },
    {
      code: "NG",
      label: "Nigéria",
    },
    {
      code: "NU",
      label: "Niue",
    },
    {
      code: "NO",
      label: "Noruega",
    },
    {
      code: "NC",
      label: "Nova Caledónia",
    },
    {
      code: "NZ",
      label: "Nova Zelândia",
    },
    {
      code: "OM",
      label: "Omã",
    },
    {
      code: "NL",
      label: "Países Baixos",
    },
    {
      code: "PW",
      label: "Palau",
    },
    {
      code: "PA",
      label: "Panamá",
    },
    {
      code: "PK",
      label: "Paquistão",
    },
    {
      code: "PY",
      label: "Paraguai",
    },
    {
      code: "PE",
      label: "Peru",
    },
    {
      code: "PF",
      label: "Polinésia Francesa",
    },
    {
      code: "PL",
      label: "Polónia",
    },
    {
      code: "PR",
      label: "Porto Rico",
    },
    {
      code: "PT",
      label: "Portugal",
    },
    {
      code: "KE",
      label: "Quénia",
    },
    {
      code: "KG",
      label: "Quirguistão",
    },
    {
      code: "GB",
      label: "Reino Unido",
    },
    {
      code: "CF",
      label: "República Centro-Africana",
    },
    {
      code: "CZ",
      label: "República Checa",
    },
    {
      code: "CD",
      label: "República Democrática do Congo",
    },
    {
      code: "CG",
      label: "República do Congo",
    },
    {
      code: "DO",
      label: "República Dominicana",
    },
    {
      code: "RE",
      label: "Reunião",
    },
    {
      code: "RO",
      label: "Roménia",
    },
    {
      code: "RW",
      label: "Ruanda",
    },
    {
      code: "RU",
      label: "Rússia",
    },
    {
      code: "EH",
      label: "Saara Ocidental",
    },
    {
      code: "WS",
      label: "Samoa",
    },
    {
      code: "AS",
      label: "Samoa Americana",
    },
    {
      code: "LC",
      label: "Santa Lúcia",
    },
    {
      code: "KN",
      label: "São Cristóvão e Neves",
    },
    {
      code: "SM",
      label: "São Marinho",
    },
    {
      code: "ST",
      label: "São Tomé e Príncipe",
    },
    {
      code: "VC",
      label: "São Vicente e Granadinas",
    },
    {
      code: "SC",
      label: "Seicheles",
    },
    {
      code: "SN",
      label: "Senegal",
    },
    {
      code: "SL",
      label: "Serra Leoa",
    },
    {
      code: "RS",
      label: "Sérvia",
    },
    {
      code: "SG",
      label: "Singapura",
    },
    {
      code: "SY",
      label: "Síria",
    },
    {
      code: "SO",
      label: "Somália",
    },
    {
      code: "LK",
      label: "Sri Lanca",
    },
    {
      code: "SD",
      label: "Sudão",
    },
    {
      code: "SS",
      label: "Sudão do Sul",
    },
    {
      code: "SE",
      label: "Suécia",
    },
    {
      code: "CH",
      label: "Suíça",
    },
    {
      code: "SR",
      label: "Suriname",
    },
    {
      code: "TH",
      label: "Tailândia",
    },
    {
      code: "TW",
      label: "Taiwan",
    },
    {
      code: "TJ",
      label: "Tajiquistão",
    },
    {
      code: "TZ",
      label: "Tanzânia",
    },
    {
      code: "TF",
      label: "Terras Austrais e Antárticas Francesas",
    },
    {
      code: "IO",
      label: "Território Britânico do Oceano Índico",
    },
    {
      code: "TL",
      label: "Timor-Leste",
    },
    {
      code: "TG",
      label: "Togo",
    },
    {
      code: "TO",
      label: "Tonga",
    },
    {
      code: "TK",
      label: "Toquelau",
    },
    {
      code: "TT",
      label: "Trindade e Tobago",
    },
    {
      code: "TN",
      label: "Tunísia",
    },
    {
      code: "TM",
      label: "Turquemenistão",
    },
    {
      code: "TR",
      label: "Turquia",
    },
    {
      code: "TV",
      label: "Tuvalu",
    },
    {
      code: "UA",
      label: "Ucrânia",
    },
    {
      code: "UG",
      label: "Uganda",
    },
    {
      code: "UY",
      label: "Uruguai",
    },
    {
      code: "UZ",
      label: "Usbequistão",
    },
    {
      code: "VU",
      label: "Vanuatu",
    },
    {
      code: "VE",
      label: "Venezuela",
    },
    {
      code: "VN",
      label: "Vietname",
    },
    {
      code: "ZM",
      label: "Zâmbia",
    },
    {
      code: "ZW",
      label: "Zimbabué",
    },
  ],
};
