import { createI18n } from "vue-i18n";
import { DATETIME_LOCALES, LOCALES } from "@/translations/locales";

function loadLocaleMessages() {
  const locales = require.context("../translations", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};
  Object.values(LOCALES).forEach((key) => {
    messages[key] = {
      ...locales(`./${key}.js`).default,
    };
  });
  return messages;
}

const defaultDatetimeFormatOptions = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  month: {
    month: "long",
  },
};

export const datetimeFormats = Object.values(DATETIME_LOCALES).reduce((acc, locale) => {
  acc[locale] = { ...defaultDatetimeFormatOptions }; // eslint-disable-line no-param-reassign
  return acc;
}, {});

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  // This default value is normally used only if the plugin is not called
  // with a 'lang' parameter and if the back is not available
  locale: LOCALES.FR,
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  warnHtmlInMessage: "off", // disable of the Detected HTML in message (legacy API mode)
  warnHtmlMessage: false, // disable of the Detected HTML in message (composition API mode)
  datetimeFormats,
});

export default i18n;
