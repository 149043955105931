<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M34.866 38.2316C34.1408 38.2316 33.5201 38.0887 33.0037 37.8031C32.4874 37.5174 32.0754 37.1384 31.7677 36.666C31.4711 36.1826 31.2898 35.6497 31.2239 35.0674H32.5093C32.6192 35.6936 32.8829 36.1771 33.3004 36.5176C33.7179 36.8582 34.2452 37.0285 34.8824 37.0285C35.7614 37.0285 36.492 36.622 37.0743 35.809C37.6566 34.985 37.9697 33.7051 38.0136 31.9692C37.75 32.4855 37.327 32.936 36.7447 33.3205C36.1734 33.6941 35.4812 33.8808 34.6682 33.8808C34.02 33.8808 33.4047 33.727 32.8224 33.4194C32.2402 33.1118 31.7677 32.6833 31.4052 32.134C31.0426 31.5736 30.8613 30.9199 30.8613 30.1728C30.8613 29.5246 31.0206 28.9039 31.3392 28.3106C31.6688 27.7173 32.1303 27.2339 32.7236 26.8604C33.3168 26.4868 34.0255 26.3 34.8495 26.3C35.6845 26.3 36.3876 26.4594 36.9589 26.778C37.5302 27.0856 37.9862 27.5031 38.3268 28.0304C38.6783 28.5578 38.931 29.1511 39.0849 29.8103C39.2497 30.4585 39.3321 31.1232 39.3321 31.8044C39.3321 33.0788 39.1563 34.1995 38.8047 35.1663C38.4531 36.1221 37.9422 36.8747 37.272 37.424C36.6128 37.9624 35.8108 38.2316 34.866 38.2316ZM34.9484 32.6778C35.4647 32.6778 35.9262 32.5624 36.3327 32.3317C36.7392 32.09 37.0633 31.7714 37.305 31.3759C37.5467 30.9804 37.6676 30.5519 37.6676 30.0904C37.6676 29.596 37.5467 29.1566 37.305 28.772C37.0633 28.3765 36.7392 28.0689 36.3327 27.8492C35.9262 27.6294 35.4647 27.5196 34.9484 27.5196C34.432 27.5196 33.9706 27.6294 33.564 27.8492C33.1575 28.0689 32.8389 28.3765 32.6082 28.772C32.3775 29.1566 32.2621 29.6015 32.2621 30.1069C32.2621 30.887 32.5148 31.5132 33.0202 31.9856C33.5366 32.4471 34.1793 32.6778 34.9484 32.6778Z"
      class="fill-secondary"
    />
    <path
      d="M40.5332 38.0338L43.3677 33.9468L40.5332 29.8597H42.0328L44.3236 33.2381L46.6308 29.8597H48.114L45.2794 33.9468L48.114 38.0338H46.6308L44.3236 34.6554L42.0328 38.0338H40.5332Z"
      class="fill-secondary"
    />
  </svg>
</template>
