import {
  isValid,
  parse,
  format,
  isAfter,
  isBefore,
  differenceInDays,
  startOfDay,
  subYears,
} from "date-fns";
import {
  DMY_DATE_FORMAT,
  YMD_DATE_FORMAT,
  MINIMUM_VALID_AGE_YEARS,
  MAXIMUM_VALID_AGE_YEARS,
} from "../constants";
import { Logger } from "../utils/logger";
import { dateFnsLocale, DEFAULT_DATE_FNS_LOCALE } from "../translations/locales";

const defaultOptions = {
  parseDateFormat: YMD_DATE_FORMAT,
  parseLocale: DEFAULT_DATE_FNS_LOCALE,
  formatDateFormat: YMD_DATE_FORMAT,
  formatLocale: DEFAULT_DATE_FNS_LOCALE,
};
export const formatDate = (
  inputDate,
  dateFormat = YMD_DATE_FORMAT,
  inputLocale = DEFAULT_DATE_FNS_LOCALE
) => {
  try {
    if (typeof inputDate === "string") return undefined;
    const locale =
      typeof inputLocale === "string" ? dateFnsLocale(inputLocale) : inputLocale;

    return format(inputDate, dateFormat, { locale });
  } catch (error) {
    Logger.error({ id: "format-date-error", inputDate, dateFormat, error });
    return undefined;
  }
};
export const isValidDate = (inputDate) => {
  return isValid(inputDate);
};
export const parseDate = (
  inputDateStr,
  dateFormat = YMD_DATE_FORMAT,
  locale = DEFAULT_DATE_FNS_LOCALE
) => {
  try {
    const result = parse(inputDateStr, dateFormat, new Date(), { locale });
    return isValidDate(result) ? result : undefined;
  } catch (error) {
    Logger.error({ id: "parse-date-error", inputDateStr, dateFormat, error });
    return undefined;
  }
};
export const parseAndFormatDate = (inputDateStr, inputOptions = defaultOptions) => {
  const options = { ...defaultOptions, ...inputOptions };
  const input_date = parseDate(
    inputDateStr,
    options.parseDateFormat,
    options.parseLocale
  );
  return formatDate(
    input_date || inputDateStr,
    options.formatDateFormat,
    options.formatLocale
  );
};

export const isBetween = (inputDate, minDate, maxDate) => {
  return isAfter(maxDate, inputDate) && isBefore(minDate, inputDate);
};

export const ordinal = (number, locale) => {
  if (!number) return "";
  if (locale === "fr_FR" && number === 1) {
    return "er";
  }
  const ordinalNumber = parseAndFormatDate(number, {
    parseDateFormat: "y",
    formatDateFormat: "yo",
    formatLocale: dateFnsLocale(locale),
  });
  return ordinalNumber.replace(number, "");
};

export const daysOfAnticipation = (
  date,
  { parseDateFormat = DMY_DATE_FORMAT } = {}
) => {
  const formattedDate = parseAndFormatDate(date, { parseDateFormat });

  // Extract only the date portion without modifying time because input date
  // does not include this information.
  const todayDate = startOfDay(new Date());
  return differenceInDays(new Date(formattedDate), todayDate);
};

export const isBirthdayAgeValid = (date) => {
  // You can pick dates between 18 and 110 years old
  const minBirthDate = subYears(new Date(), MAXIMUM_VALID_AGE_YEARS);
  const maxBirthDate = subYears(new Date(), MINIMUM_VALID_AGE_YEARS);
  return isValidDate(date) && isBetween(date, minBirthDate, maxBirthDate);
};
