/**
 * Events supported by the Pledg plugin
 */
export const PLUGIN_EVENTS = {
  PLEDG_ASK_TO_CLOSE: "PLEDG_ASK_TO_CLOSE",
  PLEDG_BUILD_PARAMS_ERROR: "PLEDG_BUILD_PARAMS_ERROR",
  PLEDG_CANCEL: "PLEDG_CANCEL",
  PLEDG_CANCEL_CLOSE: "PLEDG_CANCEL_CLOSE",
  PLEDG_CHECKOUT_FORM_READINESS: "PLEDG_CHECKOUT_FORM_READINESS",
  PLEDG_CHECKOUT_SUBMIT_START: "PLEDG_CHECKOUT_SUBMIT_START",
  PLEDG_CHECKOUT_SUBMIT_END: "PLEDG_CHECKOUT_SUBMIT_END",
  PLEDG_ERROR: "PLEDG_ERROR",
  PLEDG_EXTERNAL_CHECKOUT_VALIDATION: "PLEDG_EXTERNAL_CHECKOUT_VALIDATION",
  PLEDG_IS_VALID: "PLEDG_IS_VALID",
  PLEDG_PAYMENT_SOLUTIONS_ACCESS: "PLEDG_PAYMENT_SOLUTIONS_ACCESS",
  PLEDG_REDIRECT: "PLEDG_REDIRECT",
  PLEDG_SCROLL_TO_TOP: "PLEDG_SCROLL_TO_TOP",
  PLEDG_START: "PLEDG_START",
  PLEDG_START_ACK: "PLEDG_START_ACK",
  PLEDG_SUCCESS: "PLEDG_SUCCESS",
  PLEDG_WAIT: "PLEDG_WAIT",
  PLEDG_SETBACK: "PLEDG_SETBACK",
};

export const FRONT_URL_PARAMS = {
  PLEDG_SUCCESS: "pledg_result",
  PLEDG_ERROR: "pledg_error",
};

export const PLUGIN_SETBACK_TYPES = {
  SPLIT_DESTINATION: "split_validation_failure",
  CHECKOUT: "checkout_completion_failure",
  OTP: "otp_validation_failure",
};

export const DEFAULT_CONTACT_EMAIL = "contact@pledg.co";
export const DEFAULT_INFO_EMAIL = "info@pledg.co";
export const YMD_DATE_FORMAT = "yyyy-MM-dd";
export const DMY_DATE_FORMAT = "dd-MM-yyyy";

export const MINIMUM_VALID_AGE_YEARS = 18;
export const MAXIMUM_VALID_AGE_YEARS = 110;

export const BI_PROVIDER_LABELS = {
  bridge: "Bridge by Bankin'",
  budget_insight: "Powens",
};

export const ShareTypes = {
  LEADER: 0,
  PLEDGER: 1,
  FIRST_INSTALLMENT: 2,
  NEXT_INSTALLMENT: 3,
  REDIRECTION: 6,
  DEFERRED: 9,
  DEPOSIT: 10,
  BALANCE: 11,
  ANCV: 12,
};

export const SHARE_PRIMARY_TYPES = [
  ShareTypes.FIRST_INSTALLMENT,
  ShareTypes.LEADER,
  ShareTypes.DEFERRED,
  ShareTypes.REDIRECTION,
  ShareTypes.DEPOSIT,
];

export const SHARE_INSTALLMENT_TYPES = [
  ShareTypes.FIRST_INSTALLMENT,
  ShareTypes.NEXT_INSTALLMENT,
];

export const SHARE_SPLIT_TYPES = [ShareTypes.LEADER, ShareTypes.PLEDGER];

export const RECEIVABLE_SHARE_TYPES = [
  ShareTypes.NEXT_INSTALLMENT,
  ShareTypes.DEFERRED,
  ShareTypes.BALANCE,
];

export const ShareStates = {
  CREATED: "CREATED",
  PAYMENT_OK: "PAYMENT_OK",
  PAYMENT_KO: "PAYMENT_KO",
  PREAUTHORIZATION_OK: "PREAUTHORIZATION_OK",
  WAITING_CONFIRMATION: "WAITING_CONFIRMATION",
  SEPA_DEBIT_PENDING: "SEPA_DEBIT_PENDING",
  SOFORT_PENDING: "SOFORT_PENDING",
  PAID_BY_LEADER: "PAID_BY_LEADER",
  ANCV_PENDING_TRANSFER: "ANCV_PENDING_TRANSFER",
};

export const SHARE_STATES_PAID = [
  ShareStates.PAYMENT_OK,
  ShareStates.SEPA_DEBIT_PENDING,
  ShareStates.SOFORT_PENDING,
  ShareStates.ANCV_PENDING_TRANSFER,
  ShareStates.PAID_BY_LEADER,
];

export const SHARE_STATES_OK = [...SHARE_STATES_PAID, ShareStates.PREAUTHORIZATION_OK];

export const PurchaseStates = {
  CREATED: "CREATED",
  PAYMENT_OK: "PAYMENT_OK",
  SHARED: "SHARED",
  PROCESSING_ECARD: "PROCESSING_ECARD",
  PROCESSING_TRANSFER: "PROCESSING_TRANSFER",
  PRIMARY_OK: "PRIMARY_OK",
  PRIMARY_KO: "PRIMARY_KO",
  DUPLICATED_KO: "DUPLICATED_KO",
  SCORING_KO: "SCORING_KO",
  VCP_KO: "VCP_KO",
  CONNECTOR_KO: "CONNECTOR_KO",
  COLLECTING: "COLLECTING",
  RELEASED: "RELEASED",
  CANCELLED: "CANCELLED",
  REDIRECTION_LINK_OK: "REDIRECTION_LINK_OK",
  REDIRECTION_LINK_KO: "REDIRECTION_LINK_KO",
  BI_CANCELLED: "BI_CANCELLED",
  BI_KO: "BI_KO",
  BI_OK: "BI_OK",
  BI_PENDING: "BI_PENDING",
  BI_SCORING_OK: "BI_SCORING_OK",
  BI_SCORING_KO: "BI_SCORING_KO",
  CARD_FILTER_KO: "CARD_FILTER_KO",
  TMX_SCORING_KO: "TMX_SCORING_KO",
  WORLDCHECK_FILTER_KO: "WORLDCHECK_FILTER_KO",
  REVENUE_KO: "REVENUE_KO",
  COVERAGE_KO: "COVERAGE_KO",
};

export const OrderStates = {
  INITIATED: "INITIATED",
  STARTED: "STARTED",
  WAITING_CONFIRMATION: "WAITING_CONFIRMATION",
  PREAUTHORIZATION_OK: "PREAUTHORIZATION_OK",
  PREAUTHORIZATION_KO: "PREAUTHORIZATION_KO",
  DUPLICATED_KO: "DUPLICATED_KO",
  SCORING_KO: "SCORING_KO",
  ACCEPTED: "ACCEPTED",
  CLOSED: "CLOSED",
};

export const SCORING_OR_FILTERS_KO_STATES = [
  PurchaseStates.SCORING_KO,
  OrderStates.SCORING_KO,
  PurchaseStates.BI_SCORING_KO,
  PurchaseStates.TMX_SCORING_KO,
  PurchaseStates.WORLDCHECK_FILTER_KO,
  PurchaseStates.CARD_FILTER_KO,
  PurchaseStates.REVENUE_KO,
  PurchaseStates.COVERAGE_KO,
];

export const PaymentTypes = {
  DEFERRED: "DEFERRED",
  DOWN_PAYMENT: "DOWN_PAYMENT",
  INSTALLMENT: "INSTALLMENT",
  SPLIT: "SPLIT",
};

export const TransactionTypes = {
  PURCHASE: "purchase",
  ORDER: "order",
};

export const SECURITY_TOKKEN = "security_tokken";

export const Operators = {
  PLEDG: "PLEDG",
  CACF: "CACF",
};

export const DEFAULT_OPERATOR = Operators.PLEDG;

export const OPERATOR_PAGE_TITLE = {
  [Operators.PLEDG]: "Pledg",
  [Operators.CACF]: "Sofinco",
};

export const OPERATOR_TEAM_NAME = OPERATOR_PAGE_TITLE;

export const OPERATOR_FOLDER_NAME = {
  [Operators.PLEDG]: "pledg",
  [Operators.CACF]: "cacf",
};

export const AccessErrorCodes = {
  ERROR_404: "error_404",
  ERROR_410: "error_410",
};

export const AccessErrorTypes = {
  EXPIRED: "expired",
  NOT_FOUND: "not_found",
};

export const Civilities = {
  MR: "mr",
  MRS: "mrs",
};
