import * as types from "@/store/mutation-types";
import FunnelService from "@/api/funnelService";

const state = {};
const getters = {};
const mutations = {};

// Actions
const actions = {
  async fetchFunnelAction(
    { commit },
    { purchaseUid, shareUid, paymentFunnelUid, orderUid }
  ) {
    const response = await FunnelService.fetchParams(
      purchaseUid,
      shareUid,
      paymentFunnelUid,
      orderUid
    );
    if (response?.data?.params) {
      commit(
        types.RAW_INFORMATION_FROM_SERVER,
        { informations: response.data.params },
        { root: true }
      );
    }
  },
  async createFunnelAction({ rootGetters }, { targetUid }) {
    const params = rootGetters.getRawObject;
    // Send also webSessionId for ThreatMetrix data retrieval
    params.webSessionId = rootGetters["merchant/webSessionId"];
    await FunnelService.createParams(targetUid, params);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
