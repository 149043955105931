import { computed } from "vue";
import { useRoute as useVueRoute, useRouter as useVueRouter } from "vue-router";
import { SECURITY_TOKKEN } from "@/constants";
import {
  DefaultFunnelSteps,
  PaymentMethodUpdateSteps,
  ROUTE_PARAMS,
  ROUTE_NAMES,
  OrderFunnelSteps,
  PAYMENT_SOLUTIONS_ROUTE_NAMES,
  DASHBOARD_BUYER_ROUTE_NAMES,
  SplitFunnelSteps,
  stepFunnelCheckout,
} from "@/constants/routes";
import { MERCHANT, ORDER, PURCHASE, SHARE } from "@/store/namespaces";
import { useStore } from "@/composables/store";

const { ORDER_UID, PURCHASE_UID, SHARE_UID } = ROUTE_PARAMS;

export const useRouter = () => {
  const { useGetters } = useStore();
  const route = useVueRoute();
  const {
    currentRoute,
    go: routerGo,
    push: routerPush,
    replace: routerReplace,
  } = useVueRouter();

  /* Computed. */
  const { isSplitPayment } = useGetters(MERCHANT, ["isSplitPayment"]);
  const { purchaseUid } = useGetters(PURCHASE, ["purchaseUid"]);
  const { shareUid } = useGetters(SHARE, ["shareUid"]);
  const { orderUid } = useGetters(ORDER, ["orderUid"]);

  const isPaymentLink = computed(() => {
    return Object.keys(route.query).length > 0;
  });
  const isDashboardBuyerRoute = computed(() => {
    return Object.values(DASHBOARD_BUYER_ROUTE_NAMES).includes(route.name);
  });
  const isPaymentSolutionsRoute = computed(() => {
    return Object.values(PAYMENT_SOLUTIONS_ROUTE_NAMES).includes(route.name);
  });
  const isPaymentMethodUpdateRoute = computed(() => {
    return route.name === ROUTE_NAMES.FUNNEL_PAYMENT_METHOD_UPDATE;
  });
  const queryError = computed(() => {
    return currentRoute.value.query.error;
  });
  const isAccessErrorRoute = computed(() => {
    return route.name === ROUTE_NAMES.ACCESS_ERROR;
  });

  /* Methods. */
  const goBackToLastRoute = () => {
    routerGo(-1);
  };
  const goTo = (routeParams, pushToHistory = false) => {
    if (pushToHistory) {
      routerPush(routeParams);
    } else {
      routerReplace(routeParams);
    }
  };
  const selectFunnelSteps = () => {
    if (isSplitPayment.value) return [...SplitFunnelSteps];
    if (orderUid.value) return [...OrderFunnelSteps];
    if (isPaymentMethodUpdateRoute.value) {
      return [...PaymentMethodUpdateSteps];
    }
    return [...DefaultFunnelSteps];
  };
  const getCurrentFunnelStep = () => {
    const steps = selectFunnelSteps();
    const currentStepRouteName = currentRoute.value.name;
    const i = steps.findIndex((step) => step.routeName === currentStepRouteName);
    if (i >= 0) return steps[i];
    return undefined;
  };
  const getNextFunnelStep = () => {
    const currentStep = getCurrentFunnelStep();
    if (!currentStep) return undefined;

    const steps = selectFunnelSteps();
    const i = steps.findIndex((step) => step.routeName === currentStep.routeName);
    if (i >= 0) return steps[i + 1];
    return undefined;
  };
  const getStepParams = (step) => {
    const params = {};
    if (step.routeParams.includes(PURCHASE_UID)) {
      if (!purchaseUid.value) throw new Error("Error Missing Purchase");
      params[PURCHASE_UID] = purchaseUid.value;
    }
    if (step.routeParams.includes(SHARE_UID)) {
      if (!shareUid.value) throw new Error("Missing Share");
      params[SHARE_UID] = shareUid.value;
    }
    if (step.routeParams.includes(ORDER_UID)) {
      if (!orderUid.value) throw new Error("Missing Order");
      params[ORDER_UID] = orderUid.value;
    }
    return params;
  };
  const goToNextStep = (payload, forceStep) => {
    const nextStep = forceStep || getNextFunnelStep();

    // pass down payload parameters as query
    const { pushToRouterHistory, ...queryData } = payload || {};
    let query = queryData;

    // The purchase is not created yet
    // Let the FunnelInformationMissing step make sure data is valid and create the purchase
    // Pass down initial params to FunnelInformationMissing to allow page reload
    if (
      !purchaseUid.value &&
      nextStep.routeName === ROUTE_NAMES.FUNNEL_INFORMATION_MISSING_PRE_CREATION
    ) {
      query = Object.assign(query, currentRoute.value.query);
    }
    // Allow to pass down the token parameter
    const query_token = currentRoute.value.query[SECURITY_TOKKEN];
    if (query_token) {
      query = Object.assign(query, { [SECURITY_TOKKEN]: query_token });
    }

    const routeParams = {
      name: nextStep.routeName,
      params: getStepParams(nextStep),
      query,
    };
    goTo(routeParams, payload && pushToRouterHistory);
  };
  const goToCheckoutStep = (payload) => {
    goToNextStep(payload, stepFunnelCheckout);
  };

  return {
    /* Computed. */
    isPaymentLink,
    isDashboardBuyerRoute,
    isPaymentSolutionsRoute,
    isPaymentMethodUpdateRoute,
    queryError,
    isAccessErrorRoute,

    /* Methods. */
    goBackToLastRoute,
    goToNextStep,
    goToCheckoutStep,
  };
};

export default useRouter;
