import { Operators, TransactionTypes } from "@/constants";

class AppContext {
  transactionType = TransactionTypes.PURCHASE;

  operator;

  securityToken;

  globalProperties;

  setTransactionType(transactionType) {
    this.transactionType = transactionType;
  }

  setOperator(operator) {
    this.operator = operator?.toUpperCase();
  }

  getOperator() {
    return this.operator || Operators.PLEDG;
  }

  setSecurityToken(securityToken) {
    this.securityToken = securityToken;
  }

  setGlobalProperties(globalProperties) {
    this.globalProperties = globalProperties;
  }
}

export default new AppContext();
