export const LoaderTypes = {
  PRIMARY: "primary",
  PRIMARY_REVERSE: "primary-reverse",
  ANCV: "ancv",
};

export const LoaderSizes = {
  SMALL: "sm",
  MEDIUM: "md",
};
