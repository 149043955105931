/** @type {import('tailwindcss').Config} */
const defaultConfig = require("tailwindcss/defaultConfig");
const colors = require("tailwindcss/colors");

module.exports = {
  content: ["./src/**/*.{vue,js}"],
  theme: {
    fontFamily: {
      sans: ["var(--font-family)", ...defaultConfig.theme.fontFamily.sans],
    },
    colors: {
      transparent: "transparent",
      black: colors.black,
      white: colors.white,
      gray: colors.slate,
      neutral: colors.neutral,
      //
      primary: "rgba(var(--primary), <alpha-value>)",
      secondary: "rgba(var(--secondary), <alpha-value>)",
      tertiary: "rgba(var(--tertiary), <alpha-value>)",
      //
      failure: "rgba(var(--failure), <alpha-value>)",
      "failure-light": "rgba(var(--failure-light), <alpha-value>)",
      success: "rgba(var(--success), <alpha-value>)",
      warning: "rgba(var(--warning), <alpha-value>)",
      info: "rgba(var(--info), <alpha-value>)",
    },
    extend: {
      content: {
        space: '""',
      },
      flex: {
        2: "2 2 0%",
        3: "3 3 0%",
      },
      fontSize: {
        "2xs": "0.6rem",
      },
      lineHeight: {
        "extra-loose": "2.5",
        12: "3rem",
      },
      minHeight: {
        96: "24rem",
        164: "41rem",
      },
      minWidth: {
        128: "32rem",
      },
      width: {
        120: "30rem",
      },
      zIndex: {
        1000: "1000",
      },
    },
  },
  plugins: [],
};
