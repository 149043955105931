<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M35.9816 38.1338C35.1905 38.1338 34.5039 37.9909 33.9216 37.7053C33.3393 37.4196 32.8723 37.0296 32.5208 36.5352C32.1802 36.0298 31.9714 35.4585 31.8945 34.8213H33.2459C33.3777 35.4585 33.6854 35.9749 34.1688 36.3704C34.6522 36.7549 35.2619 36.9472 35.9981 36.9472C36.5474 36.9472 37.0198 36.8209 37.4153 36.5682C37.8109 36.3045 38.113 35.9529 38.3217 35.5134C38.5415 35.074 38.6513 34.5851 38.6513 34.0467C38.6513 33.1898 38.4096 32.4976 37.9262 31.9702C37.4428 31.4429 36.8166 31.1792 36.0475 31.1792C35.4103 31.1792 34.8664 31.322 34.416 31.6077C33.9655 31.8933 33.6359 32.2669 33.4272 32.7283H32.1088L33.0976 26.4H39.1457V27.603H34.1688L33.4931 31.1133C33.7568 30.7947 34.1193 30.531 34.5808 30.3222C35.0532 30.1135 35.5915 30.0091 36.1958 30.0091C36.9539 30.0091 37.6131 30.1849 38.1734 30.5365C38.7447 30.8881 39.1897 31.366 39.5083 31.9702C39.8269 32.5745 39.9862 33.2612 39.9862 34.0302C39.9862 34.7663 39.8269 35.4475 39.5083 36.0738C39.2007 36.7 38.7447 37.1999 38.1405 37.5734C37.5472 37.947 36.8275 38.1338 35.9816 38.1338Z"
      class="fill-secondary"
    />
    <path
      d="M41.2437 37.936L44.0783 33.849L41.2437 29.7619H42.7434L45.0341 33.1403L47.3413 29.7619H48.8245L45.99 33.849L48.8245 37.936H47.3413L45.0341 34.5576L42.7434 37.936H41.2437Z"
      class="fill-secondary"
    />
  </svg>
</template>
