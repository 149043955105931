import httpService from "./httpService";

const ENDPOINTS = {
  identification: (shareUid) => `shares/${shareUid}/identification`,
  identificationStatus: (shareUid) => `shares/${shareUid}/identification/status`,
};

export const getIdentification = async (shareUid) => {
  const url = ENDPOINTS.identification(shareUid);
  return httpService.post(url);
};

export const getStatus = async (shareUid) => {
  const url = ENDPOINTS.identificationStatus(shareUid);
  return httpService.get(url);
};

const IdentificationService = {
  getIdentification,
  getStatus,
};

export default IdentificationService;
