import ERROR_TYPES from "@/constants/errors";
import BackErrorModel from "./BackErrorModel";

export const FUNCTIONAL_ERROR_TYPES = [
  ERROR_TYPES.ANCV_ERROR,
  ERROR_TYPES.ERROR_TOKEN,
  ERROR_TYPES.REFUSED_PAYMENT,
  ERROR_TYPES.UNEXPECTED_OTP_REQUEST,
];
/**
 * Create an ApiResponseError
 * We do not use the 'extends Error' method here because of
 * an inheritance issue when transpiling with babel
 *
 * @param {Error} error The original error
 * @returns {Error} The custom created error.
 */
function ApiResponseError(error) {
  Error.call(this);
  this.message = error.message;
  this.name = "ApiResponseError";
  this.fromError = error;
  this.statusCode = error?.response?.status || 0;
  this.isInfraError = true;
  this.backError = new BackErrorModel(error?.response?.data?.error);
  this.errorHuman = this.backError?.info;
  this.errorType = ERROR_TYPES.INVALID_REQUEST;

  switch (this.statusCode) {
    case 0:
      this.errorType = ERROR_TYPES.NETWORK_ERROR;
      break;
    case 400:
    case 403:
    case 404:
    case 409:
      if (FUNCTIONAL_ERROR_TYPES.includes(this.backError?.app_error)) {
        this.errorType = this.backError.app_error;
      }
      this.isInfraError = false;
      break;
    case 500:
      this.errorType = ERROR_TYPES.INTERNAL_ERROR;
      this.isInfraError = false;
      break;
    case 502:
      this.errorType = ERROR_TYPES.BAD_GATEWAY;
      break;
    case 503:
      this.errorType = ERROR_TYPES.MAINTENANCE;
      break;
    case 504:
      /* Could be a heroku H12 */
      this.errorType = ERROR_TYPES.GATEWAY_TIMEOUT;
      break;
    default:
      this.isInfraError = false;
      break;
  }
}

ApiResponseError.prototype = Object.create(Error.prototype, {});
ApiResponseError.prototype.constructor = ApiResponseError;

export default ApiResponseError;
