import jwt_decode from "jwt-decode";
import { Logger } from "@/utils/logger";

class Signature {
  constructor(encodedSignature) {
    this.encodedSignature = encodedSignature;
    this.decodedSignature = undefined;
    this.data = undefined;
    //
    this.decode();
    //
    this.purchase_uid = this.data?.purchase_uid;
    this.share_uid = this.data?.share_uid;
    this.transaction_type = this.data?.transactionType;
  }

  decode() {
    if (!this.encodedSignature) return;

    try {
      this.decodedSignature = jwt_decode(this.encodedSignature);
      this.data = this.decodedSignature?.data || {};
    } catch (error) {
      Logger.error({
        id: "invalid-jwt-signature",
        signature: this.encodedSignature,
        error,
      });
    }
  }
}

export default Signature;
