import { computed, unref } from "vue";
import { useI18n as useVueI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/composables/store";
import { PURCHASE, SHARE } from "@/store/namespaces";

import getFormattedPrice from "@/helpers/getFormattedPrice";
import { YMD_DATE_FORMAT, DMY_DATE_FORMAT } from "@/constants";
import { parseAndFormatDate } from "@/helpers/date";

export const useShare = () => {
  const { useGetters, useActions } = useStore();
  const { go: routerGo } = useRouter();
  const { locale } = useVueI18n({ useScope: "global" });

  const {
    share,
    firstInstallmentShare,
    nextInstallmentShares,
    deferredShare,
    balanceShare,
    ancvShare,
    isAlone,
  } = useGetters(SHARE, [
    "share",
    "firstInstallmentShare",
    "nextInstallmentShares",
    "deferredShare",
    "balanceShare",
    "ancvShare",
    "isAlone",
  ]);
  const { rawBalancePaymentDate } = useGetters(["rawBalancePaymentDate"]);
  const { purchase } = useGetters(PURCHASE, ["purchase"]);
  const { appSetShareHasForcedAmount } = useActions(["appSetShareHasForcedAmount"]);

  /**
   *
   *
   * PAYMENT DATES
   *
   *
   */
  const balancePaymentDate = computed(() => {
    return parseAndFormatDate(rawBalancePaymentDate.value, {
      parseDateFormat: YMD_DATE_FORMAT,
      formatDateFormat: "P",
    });
  });
  const depositPaymentDate = computed(() => {
    return parseAndFormatDate(purchase.value?.created, {
      parseDateFormat: DMY_DATE_FORMAT,
      formatDateFormat: "P",
    });
  });
  const firstInstallmentPaymentDate = computed(() => {
    return parseAndFormatDate(firstInstallmentShare.value?.expiration_date, {
      parseDateFormat: DMY_DATE_FORMAT,
      formatDateFormat: "P",
    });
  });
  const deferredPaymentDate = computed(() => {
    return parseAndFormatDate(deferredShare.value?.expiration_date, {
      parseDateFormat: DMY_DATE_FORMAT,
      formatDateFormat: "P",
    });
  });

  /**
   *
   *
   * AMOUNTS
   *
   *
   */
  const formattedAmountToCharge = computed(() => {
    const shareValue = unref(share);
    return getFormattedPrice(
      shareValue.amount_cents_to_charge,
      shareValue.currency,
      locale.value
    );
  });
  const formattedAmount = computed(() => {
    const shareValue = unref(share);
    return getFormattedPrice(
      shareValue.amount_cents,
      shareValue.currency,
      locale.value
    );
  });
  const ancvFormattedAmount = computed(() => {
    const ancvShareValue = unref(ancvShare);
    if (!ancvShareValue) return undefined;
    return getFormattedPrice(
      ancvShareValue.amount_cents,
      ancvShareValue.currency,
      locale.value
    );
  });
  const balanceFormattedAmount = computed(() => {
    const balanceShareValue = unref(balanceShare);
    if (!balanceShareValue) return undefined;
    return getFormattedPrice(
      balanceShareValue.amount_cents_to_charge,
      balanceShareValue.currency,
      locale.value
    );
  });
  const downPaymentFormattedAmount = computed(() => {
    const balanceShareValue = unref(balanceShare);
    if (!balanceShareValue) return undefined;
    return getFormattedPrice(
      unref(share).amount_cents_to_charge + balanceShareValue.amount_cents_to_charge,
      balanceShareValue.currency,
      locale.value
    );
  });
  const formattedFeesAmount = computed(() => {
    const shareValue = unref(share);
    return getFormattedPrice(
      shareValue.fees_amount_cents,
      shareValue.currency,
      locale.value
    );
  });
  const formattedPenaltiesAmount = computed(() => {
    const shareValue = unref(share);
    return getFormattedPrice(
      shareValue.penalties_amount_cents,
      shareValue.currency,
      locale.value
    );
  });

  /**
   *
   *
   * OTHER
   *
   *
   */
  const nextInstallmentsDetails = computed(() => {
    return nextInstallmentShares.value.map((nextInstallmentShare) => {
      const deadline = parseAndFormatDate(nextInstallmentShare.expiration_date, {
        parseDateFormat: DMY_DATE_FORMAT,
        formatDateFormat: "P",
      });

      return {
        id: nextInstallmentShare.uid,
        deadline,
        amount: getFormattedPrice(
          nextInstallmentShare.amount_cents_to_charge,
          nextInstallmentShare.currency,
          locale.value
        ),
      };
    });
  });

  const handleBackClick = () => {
    if (isAlone.value) {
      appSetShareHasForcedAmount({
        index: 0,
        forced_amount: false,
      });
    }
    routerGo(-1);
  };

  return {
    balancePaymentDate,
    depositPaymentDate,
    firstInstallmentPaymentDate,
    deferredPaymentDate,
    formattedAmountToCharge,
    formattedAmount,
    ancvFormattedAmount,
    balanceFormattedAmount,
    downPaymentFormattedAmount,
    formattedFeesAmount,
    formattedPenaltiesAmount,
    nextInstallmentsDetails,
    handleBackClick,
  };
};

export default useShare;
