import { computed } from "vue";
import { parseDate, daysOfAnticipation } from "@/helpers/date";
import { SHARE } from "@/store/namespaces";
import { useStore } from "@/composables/store";
import { useI18n } from "@/composables/i18n";
import { useRouter } from "@/composables/router";
import { DMY_DATE_FORMAT } from "@/constants";

export const useAnticipatedPayment = () => {
  const { useGetters } = useStore();
  const { isPaymentMethodUpdateRoute } = useRouter();
  const { tdate } = useI18n();

  const { nextInstallmentShares, deferredShare, isNextInstallment, isDeferred } =
    useGetters(SHARE, [
      "nextInstallmentShares",
      "deferredShare",
      "isNextInstallment",
      "isDeferred",
    ]);
  const { rawIsSeminal } = useGetters(["rawIsSeminal"]);

  const computeAnticipatedDetails = (expirationDate) => {
    const deadlineLong = tdate(parseDate(expirationDate, DMY_DATE_FORMAT), "long");
    const days = daysOfAnticipation(expirationDate);

    return {
      deadlineLong,
      days,
    };
  };

  const anticipatedPaymentDetails = computed(() => {
    if (isNextInstallment.value && nextInstallmentShares.value[0]) {
      return computeAnticipatedDetails(nextInstallmentShares.value[0].expiration_date);
    }
    if (isDeferred.value) {
      return computeAnticipatedDetails(deferredShare.value.expiration_date);
    }
    return undefined;
  });

  const isAnticipatedPayment = computed(() => {
    return (
      !rawIsSeminal.value &&
      anticipatedPaymentDetails.value?.days > 0 &&
      !isPaymentMethodUpdateRoute.value
    );
  });

  return {
    anticipatedPaymentDetails,
    isAnticipatedPayment,
  };
};

export default useAnticipatedPayment;
