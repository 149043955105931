/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
import axios from "axios";
import config from "@/config";
import { SECURITY_TOKKEN } from "@/constants";
import ApiResponseError from "@/models/ApiResponseError";
import { Logger } from "../utils/logger";
import AppContext from "../AppContext";

const getHeaders = () => {
  if (AppContext.securityToken) {
    return {
      Authorization: `Bearer ${AppContext.securityToken}`,
    };
  }
  return {};
};
/**
 * HttpService
 * @param {string} method GET, POST, PATCH, DELETE
 * @param {string} url the endpoint to call
 * @param {dict} params are the URL parameters to be sent with the request
 * @param {dict} bodyData is the data to be sent as the request body ('PUT', 'POST', 'DELETE', and 'PATCH')
 * @param {dict} options additional options to pass to the axios config
 * @returns {*} the response data
 */
const httpHandler =
  (method) =>
  async (url, params, bodyData, options = {}) => {
    try {
      const { $http } = AppContext.globalProperties;

      const response = await $http.request({
        url,
        method,
        params,
        data: bodyData,
        headers: getHeaders(),
        ...options,
      });
      const { data, status, headers } = response;
      return { data, statusCode: parseInt(status, 10), headers };
    } catch (error) {
      throw new ApiResponseError(error);
    }
  };

function initializeHttp() {
  Logger.info({ id: "httpService.initialize", baseUrl: config.API_BASE_URL });
  let http;

  // https://github.com/axios/axios/blob/main/lib/defaults/index.js
  const axiosParameters = {
    baseURL: config.API_BASE_URL,
    withCredentials: true,
  };
  if (config.IS_TEST) {
    const { wrapper } = require("axios-cookiejar-support");
    const { CookieJar } = require("tough-cookie");
    const jar = new CookieJar();
    axiosParameters.jar = jar;
    http = wrapper(axios.create(axiosParameters));
  } else {
    http = axios.create(axiosParameters);
  }
  return http;
}

function initialize() {
  const http = initializeHttp();

  http.defaults.headers.common["front-version"] = config.APP_VERSION;

  http.interceptors.request.use(
    (request) => {
      Logger.info({
        id: "axios-request",
        method: request?.method,
        url: request?.url,
        request,
      });
      return request;
    },
    (error) => {
      Logger.error({
        id: "axios-request-error",
        method: error?.config?.method,
        url: error?.config?.url,
        error,
      });
    }
  );
  // If we catch an HTTP error with a status greater than or equal to 500,
  // we close the iframe and we display the error to the client
  http.interceptors.response.use(
    (response) => {
      Logger.info({
        id: "axios-response",
        method: response?.config?.method,
        url: response?.config?.url,
        response,
      });

      if (response?.data?.[SECURITY_TOKKEN]) {
        const securityToken = response?.data?.[SECURITY_TOKKEN];
        AppContext.setSecurityToken(securityToken);
      }

      return response;
    },
    (error) => {
      Logger.error({
        id: "axios-response-error",
        method: error?.config?.method,
        url: error?.config?.url,
        error,
      });
      throw error;
    }
  );

  return http;
}

export default {
  initialize,
  initializeHttp,
  get: httpHandler("get"),
  post: httpHandler("post"),
  put: httpHandler("put"),
  patch: httpHandler("patch"),
};
