import { ShareTypes } from "../constants";

export function getSharesOfType(shares, shareType) {
  return shares.filter(({ share_type }) => share_type === shareType);
}

export function getShareOfType(shares, shareType) {
  const shares_filtered = getSharesOfType(shares, shareType);
  return shares_filtered.length ? shares_filtered[0] : undefined;
}

export function isLeader(share) {
  return parseInt(share?.share_type, 10) === ShareTypes.LEADER;
}
