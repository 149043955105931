import IconDeferred from "./IconDeferred";
import IconInstallment1x from "./IconInstallment1x";
import IconInstallment2x from "./IconInstallment2x";
import IconInstallment3x from "./IconInstallment3x";
import IconInstallment4x from "./IconInstallment4x";
import IconInstallment5x from "./IconInstallment5x";
import IconInstallment6x from "./IconInstallment6x";
import IconInstallment7x from "./IconInstallment7x";
import IconInstallment8x from "./IconInstallment8x";
import IconInstallment9x from "./IconInstallment9x";
import IconInstallment10x from "./IconInstallment10x";
import IconInstallment11x from "./IconInstallment11x";
import IconInstallment12x from "./IconInstallment12x";
import IconWarning from "./IconWarning";
import IconInfo from "./IconInfo";
import IconTime from "./IconTime";
import IconCheck from "./IconCheck";
import IconCircleWarning from "./IconCircleWarning";
import IconCircleInfo from "./IconCircleInfo";
import IconCircleCheck from "./IconCircleCheck";
import IconSourceTypeSepaDebit from "./IconSourceTypeSepaDebit";
import IconSourceTypeSofort from "./IconSourceTypeSofort";
import IconSourceTypeCard from "./IconSourceTypeCard";
import IconSourceTypeBancontact from "./IconSourceTypeBancontact";
import IconSourceTypeIdeal from "./IconSourceTypeIdeal";

function initializeIcons(app) {
  app.component("IconInstallment1x", IconInstallment1x);
  app.component("IconInstallment2x", IconInstallment2x);
  app.component("IconInstallment3x", IconInstallment3x);
  app.component("IconInstallment4x", IconInstallment4x);
  app.component("IconInstallment5x", IconInstallment5x);
  app.component("IconInstallment6x", IconInstallment6x);
  app.component("IconInstallment7x", IconInstallment7x);
  app.component("IconInstallment8x", IconInstallment8x);
  app.component("IconInstallment9x", IconInstallment9x);
  app.component("IconInstallment10x", IconInstallment10x);
  app.component("IconInstallment11x", IconInstallment11x);
  app.component("IconInstallment12x", IconInstallment12x);
  app.component("IconDeferred", IconDeferred);
  app.component("IconWarning", IconWarning);
  app.component("IconInfo", IconInfo);
  app.component("IconTime", IconTime);
  app.component("IconCheck", IconCheck);
  app.component("IconCircleCheck", IconCircleCheck);
  app.component("IconCircleInfo", IconCircleInfo);
  app.component("IconCircleWarning", IconCircleWarning);
  // eslint-disable-next-line
  app.component("IconSourceTypeSepa_debit", IconSourceTypeSepaDebit);
  app.component("IconSourceTypeCard", IconSourceTypeCard);
  app.component("IconSourceTypeSofort", IconSourceTypeSofort);
  app.component("IconSourceTypeBancontact", IconSourceTypeBancontact);
  app.component("IconSourceTypeIdeal", IconSourceTypeIdeal);
}

export default initializeIcons;
