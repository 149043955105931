import httpService from "./httpService";

const ENDPOINTS = {
  company: (companyUid) => `companies/${companyUid}`,
  companySimulations: (companyUid) => `companies/${companyUid}/simulations`,
};

async function fetchCompany(companyUid) {
  return httpService.get(ENDPOINTS.company(companyUid));
}

/**
 * Retrieve all merchants and simulations for a given company
 * @param {*} companyUid the company uid
 * @param {*} params
 * - iframe_id
 * - pledg_env
 * - amount_cents
 * - deferred_payment_date
 * - second_installment_payment_date
 * @returns response
 */
async function fetchCompanySimulations(companyUid, params = {}) {
  return httpService.get(ENDPOINTS.companySimulations(companyUid), params);
}

const CompanyService = {
  fetchCompany,
  fetchCompanySimulations,
};

export default CompanyService;
