import ERROR_TYPES from "@/constants/errors";

class FunnelErrorModel {
  constructor({ type, message, displayedError } = {}) {
    // Error type and message send back to user in URL or event payload
    this.type = type || ERROR_TYPES.INVALID_REQUEST;
    this.message = message;
    // Message displayed to the user
    this.displayedError = displayedError;
  }

  get merchantError() {
    return {
      type: this.type,
      message: this.message,
    };
  }
}

export default FunnelErrorModel;
