import * as types from "@/store/mutation-types";

const state = {
  funnelError: undefined,
};

const getters = {
  funnelError: (errorState) => errorState.funnelError,
};
const mutations = {
  [types.SET_FUNNEL_ERROR](state, { funnelError }) {
    state.funnelError = funnelError;
  },
};

// Actions
const actions = {
  setFunnelErrorAction({ commit }, funnelError) {
    commit(types.SET_FUNNEL_ERROR, { funnelError });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
