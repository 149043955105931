<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M25.0417 35.9167C25.4099 35.9167 25.7083 35.6182 25.7083 35.25C25.7083 34.8818 25.4099 34.5833 25.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM25.0417 34.5833H8V35.9167H25.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M29.81 37.9965V29.4882L28.1875 29.8392V29.0249L30.212 28.1685H31.0304V37.9965H29.81Z"
      class="fill-secondary"
    />
    <path
      d="M32.994 37.9965V37.1681C33.6737 36.644 34.315 36.1151 34.918 35.5816C35.5307 35.0387 36.0715 34.5052 36.5405 33.981C37.0191 33.4569 37.3924 32.9421 37.6605 32.4366C37.938 31.9312 38.0768 31.4445 38.0768 30.9765C38.0768 30.6302 38.0146 30.3072 37.8902 30.0077C37.7753 29.7082 37.5839 29.4695 37.3159 29.2917C37.0478 29.1045 36.6841 29.0109 36.2246 29.0109C35.7843 29.0109 35.4158 29.1092 35.119 29.3057C34.8223 29.4929 34.5974 29.7456 34.4442 30.0639C34.3006 30.3821 34.2288 30.7238 34.2288 31.0888H33.0658C33.0658 30.443 33.2046 29.8907 33.4822 29.4321C33.7598 28.9641 34.1379 28.6084 34.6165 28.365C35.0951 28.1217 35.6359 28 36.239 28C37.1292 28 37.8567 28.248 38.4214 28.7441C38.9958 29.2308 39.2829 29.9609 39.2829 30.9344C39.2829 31.5147 39.1346 32.0903 38.8378 32.6613C38.5411 33.2229 38.163 33.7704 37.7035 34.304C37.2441 34.8281 36.7511 35.3195 36.2246 35.7782C35.7077 36.2368 35.2243 36.6486 34.7745 37.0137H39.6275V37.9965H32.994Z"
      class="fill-secondary"
    />
    <path
      d="M40.6184 37.9965L43.4447 34.0116L40.6184 30.0268H42.1137L44.3978 33.3207L46.6983 30.0268H48.1772L45.3509 34.0116L48.1772 37.9965H46.6983L44.3978 34.7025L42.1137 37.9965H40.6184Z"
      class="fill-secondary"
    />
  </svg>
</template>
