import ErrorPayload from "./ErrorPayload";

class ValidityPayload {
  constructor(isValid, errorType, errorMessage) {
    this.isValid = isValid;

    if (this.isValid) {
      this.error = null;
    } else {
      this.error = new ErrorPayload(errorType, errorMessage);
    }
  }
}

export default ValidityPayload;
