import { useI18n as useVueI18n } from "vue-i18n";
import { setDefaultOptions as setDefaultDateFnsOptions } from "date-fns";
import { useRoute } from "vue-router";
import { Logger } from "@/utils/logger";
import { dateFnsLocale } from "@/translations/locales";

import AppContext from "@/AppContext";
import Sentry from "@/plugins/sentry";

const { SentryKeys, SentryLevels } = Sentry;

export const useI18n = (_context) => {
  const { locale, d, t, te } = useVueI18n({ useScope: "global" });
  const { path: routePath } = useRoute();

  const changeLocale = (newLocale, oldLocale) => {
    if (newLocale && newLocale !== oldLocale) {
      locale.value = newLocale;
      setDefaultDateFnsOptions({ locale: dateFnsLocale(newLocale) });
      Logger.info({ id: "i18nChangeLocale", newLocale });
    }
  };

  const to = (messageKey) => {
    const operator = AppContext.getOperator();
    if (te(`${messageKey}.${operator}`)) return t(`${messageKey}.${operator}`);

    Sentry.captureMessage(SentryKeys.MISSING_I18N_KEY, SentryLevels.WARNING, {
      key: messageKey,
      operator,
      route: routePath,
    });

    return undefined;
  };

  const tdate = (date, format) => {
    // https://github.com/kazupon/vue-i18n/issues/312
    const datetimeLocaleFormat = locale.value.replace("_", "-");
    return d(date, format, datetimeLocaleFormat);
  };

  return { changeLocale, to, tdate };
};

export default useI18n;
