import VueMatomo from "vue-matomo";
import config from "@/config";

function isMatomoEnabled() {
  return config.MATOMO_ENABLED && !!config.MATOMO_HOST && !!config.MATOMO_SITE_ID;
}

function pushToMatomo(action, ...params) {
  if (!isMatomoEnabled()) return;

  window._paq = window._paq || [];
  window._paq.push([action, ...params]);
}

function sendMatomoPageView(pageUrl, pageTitle) {
  pushToMatomo("setCustomUrl", pageUrl);
  pushToMatomo("trackPageView", pageTitle);
}

function sendMatomoCustomDimension(dimensionId, value) {
  pushToMatomo("setCustomDimension", dimensionId, value);
  // Ensure the dimension's last value is saved, even if no other tracking requests (events, pageviews, etc.) are triggered
  // See https://developer.matomo.org/guides/tracking-javascript-guide#custom-dimensions
  pushToMatomo("ping");
}

function sendRouteToMatomo(route) {
  sendMatomoPageView(route.path, route.matched[0].path);
}

function initRouterTracker(router) {
  let timeoutId = null;

  function cancelTimer() {
    clearTimeout(timeoutId);
  }

  function startTimer(route) {
    timeoutId = setTimeout(() => {
      sendRouteToMatomo(route);
    }, config.MATOMO_MIN_TIME_ON_PAGE_IN_MS);
  }

  router.afterEach((to, from) => {
    if (from.name !== to.name) {
      cancelTimer();
      startTimer(to);
    }
  });

  router.beforeEach((to, from, next) => {
    cancelTimer();
    next();
  });
}

function sendMatomoInIframeDimension(value) {
  sendMatomoCustomDimension(config.MATOMO_DIMENSION_ID_IFRAME, value);
}

function sendMatomoPaymentSuccessfulDimension(value) {
  sendMatomoCustomDimension(config.MATOMO_DIMENSION_ID_PAYMENT_SUCCESS, value);
}

function initMatomo(app, router) {
  if (!isMatomoEnabled()) return;

  app.use(VueMatomo, {
    host: config.MATOMO_HOST,
    siteId: config.MATOMO_SITE_ID,
    enableLinkTracking: false,
    disableCookies: true,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 5,
  });

  initRouterTracker(router);
  sendMatomoPaymentSuccessfulDimension(false);
}

export {
  initMatomo,
  sendMatomoInIframeDimension,
  sendMatomoPaymentSuccessfulDimension,
};
