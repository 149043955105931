export const LogLevelStyle = {
  LOG: "background:#5ece7b; padding: 2px; border-radius: 0 2px 2px 0;  color: #fff;",
  INFO: "background:#0468DB; padding: 2px; border-radius: 0 2px 2px 0;  color: #fff;",
  WARN: "background:#ecc713; padding: 2px; border-radius: 0 2px 2px 0;  color: #000;",
  ERROR: "background:#d12727; padding: 2px; border-radius: 0 2px 2px 0;  color: #fff",
};

export const LogLevel = {
  DEBUG: "debug",
  INFO: "info",
  WARN: "warn",
  ERROR: "error",
  NONE: "none",
};
