import _omit from "lodash.omit";
import { ROUTE_PARAMS } from "@/constants/routes";
import * as Storage from "@/helpers/storage";
import { TransactionTypes, SECURITY_TOKKEN } from "@/constants";
import Signature from "./Signature";

const { PURCHASE_UID, SHARE_UID, PAYMENT_FUNNEL_UID, ORDER_UID } = ROUTE_PARAMS;

/*
    Information sum up every raw informations we have:
    - from plugin (directly or saved in db)
    - from query params
    - from router url parameters
*/
class RouteInformations {
  constructor(route) {
    this.queryParams = route.query;
    this.routeParams = route.params;
    this.data = undefined;
    this.showCloseButton = undefined;
    this.purchase_uid = undefined;
    this.share_uid = undefined;
    this.transaction_type = undefined;
    // computed
    this.signature = new Signature(this.queryParams.signature);
    this.aggregateData();
  }

  aggregateData() {
    this.purchase_uid =
      this.signature.purchase_uid ||
      this.queryParams.purchase_uid ||
      this.routeParams[PURCHASE_UID];
    this.share_uid =
      this.signature.share_uid ||
      this.queryParams.share_uid ||
      this.routeParams[SHARE_UID];
    this.order_uid =
      this.signature.order_uid ||
      this.queryParams.order_uid ||
      this.routeParams[ORDER_UID];

    this.payment_funnel_uid = this.routeParams[PAYMENT_FUNNEL_UID];

    const storageKey =
      this.purchase_uid || this.share_uid || this.payment_funnel_uid || this.order_uid;
    const storedInformations = Storage.getInformations(storageKey);

    this.data = {
      ...storedInformations,
      ...this.signature.data,
      ...this.queryParams,
      ...this.routeParams,
    };
    this.showCloseButton = ["true", true, undefined].includes(
      this.data.showCloseButton
    );
    this.security_token = this.data[SECURITY_TOKKEN];

    this.save(this.order_uid);
    this.save(this.purchase_uid);
    this.save(this.share_uid);

    if (
      this.order_uid ||
      this.signature.transaction_type?.toLowerCase() === TransactionTypes.ORDER
    ) {
      this.transaction_type = TransactionTypes.ORDER;
    } else {
      this.transaction_type = TransactionTypes.PURCHASE;
    }
  }

  save(targetUid) {
    if (!targetUid) return;
    // In case of SPLIT payment, shares can be deleted
    const informationsToStore = _omit(this.data, ["shareUid"]);
    Storage.setInformations(targetUid, informationsToStore);
  }
}

export default RouteInformations;
