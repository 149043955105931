import * as Sentry from "@sentry/vue";
import config from "@/config";
import { Logger } from "@/utils/logger";
import ERROR_TYPES from "../constants/errors";

const SentryKeys = {
  STRIPE_ERROR: "stripe_error",
  PAYMENT_SOLUTIONS_ERROR_CAPTURED: "payment_solutions_error_captured",
  MISSING_I18N_KEY: "missing_i18n_key",
  FUNNEL_API_ERROR: "funnel_api_error",
  MISSING_PAYMENT_METHOD_NETWORKS: "missing_payment_method_networks",
};

const SentryLevels = {
  DEBUG: "debug",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
  FATAL: "fatal",
};

const SENTRY_IGNORE_ERROR_TYPES = [ERROR_TYPES.NETWORK_ERROR];

const MAX_TAG_VALUE_LENGTH = 200;

function isSentryEnabled() {
  return !!config.IS_PRODUCTION && !!config.SENTRY_DSN;
}

function initSentry(app, router) {
  if (!isSentryEnabled()) return;

  Sentry.init({
    app,
    environment: window.location.hostname,
    dsn: config.SENTRY_DSN,
    release: config.APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "jigsaw is not defined",
      // Facebook borked
      "fb_xd_fragment",
      // https://github.com/juggle/resize-observer/issues/103
      // element use ResizeObserver but resize and fail
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^safari-extension:\/\//i,
      /^webkit-masked-url:\/\//i,
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
  });
}

function configureScope(userPayload) {
  if (!isSentryEnabled()) return;

  Sentry.configureScope((scope) => {
    scope.setUser(userPayload);
  });
}

/**
 * Send to Sentry a specific message to detect
 * @param {string} messageKey the message key used in sentry
 * @param {string} level the alert level
 * @param {dict} tags more details about the message { key: value }
 * @returns void
 */
function captureMessage(messageKey, level, inputTags, otherParams = {}) {
  if (!isSentryEnabled()) {
    Logger.warn({ id: "sentry-capture-message", messageKey, level, tags: inputTags });
    return;
  }

  if (
    SENTRY_IGNORE_ERROR_TYPES.includes(inputTags?.error_type || inputTags?.errorType)
  ) {
    Logger.info({ id: "sentry-ignore-error", messageKey, tags: inputTags });
    return;
  }

  const tags = Object.entries(inputTags).reduce((acc, [key, value]) => {
    let valueStr = value;
    switch (typeof value) {
      case "object":
        valueStr = JSON.stringify(valueStr);
        break;
      case "number":
      case "boolean":
        valueStr = `${valueStr}`;
        break;
      default:
        break;
    }
    // Maximum length authorized by Sentry is MAX_TAG_VALUE_LENGTH chars
    const limitedString = valueStr?.slice(0, MAX_TAG_VALUE_LENGTH);
    return { ...acc, [key]: limitedString };
  }, {});

  Sentry.captureMessage(messageKey, (scope) => {
    scope.setLevel(level);
    scope.setTags(tags);
    scope.setExtras(otherParams);
  });
}

export default {
  SentryKeys,
  SentryLevels,
  initSentry,
  configureScope,
  captureMessage,
};
