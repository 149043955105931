<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.4826 38.2298C34.6476 38.2298 33.9444 38.0722 33.3731 37.7568C32.8018 37.4303 32.3404 36.9967 31.9888 36.4562C31.6482 35.9156 31.3955 35.3131 31.2307 34.6487C31.0769 33.973 31 33.2861 31 32.5879C31 31.2816 31.1758 30.1385 31.5274 29.1588C31.8789 28.1678 32.3843 27.3964 33.0435 26.8446C33.7137 26.2815 34.5212 26 35.4661 26C36.1912 26 36.8119 26.1464 37.3283 26.4392C37.8447 26.732 38.2512 27.1261 38.5478 27.6216C38.8555 28.1059 39.0477 28.6464 39.1246 29.2433H37.8392C37.7183 28.6014 37.4492 28.1059 37.0317 27.7568C36.6142 27.4077 36.0868 27.2331 35.4496 27.2331C34.5817 27.2331 33.851 27.6554 33.2578 28.5C32.6755 29.3334 32.3623 30.6397 32.3184 32.419C32.5821 31.8897 32.9996 31.4336 33.5709 31.0507C34.1532 30.6566 34.8508 30.4595 35.6638 30.4595C36.323 30.4595 36.9383 30.6171 37.5096 30.9325C38.0919 31.2478 38.5643 31.6926 38.9269 32.2669C39.2894 32.83 39.4707 33.4944 39.4707 34.2602C39.4707 34.9246 39.3114 35.5609 38.9928 36.169C38.6742 36.7771 38.2127 37.2726 37.6085 37.6555C37.0152 38.0384 36.3066 38.2298 35.4826 38.2298ZM35.3837 36.9798C35.9001 36.9798 36.3615 36.8672 36.768 36.642C37.1745 36.4167 37.4931 36.107 37.7238 35.7129C37.9546 35.3075 38.0699 34.8458 38.0699 34.3278C38.0699 33.5282 37.8117 32.8919 37.2954 32.419C36.79 31.9347 36.1527 31.6926 35.3837 31.6926C34.8673 31.6926 34.4059 31.8165 33.9994 32.0642C33.5929 32.3007 33.2687 32.6217 33.027 33.0271C32.7963 33.4325 32.681 33.8717 32.681 34.3446C32.681 34.8514 32.7963 35.3075 33.027 35.7129C33.2687 36.107 33.5929 36.4167 33.9994 36.642C34.4059 36.8672 34.8673 36.9798 35.3837 36.9798Z"
      class="fill-secondary"
    />
    <path
      d="M40.8037 38.0271L43.6382 33.8379L40.8037 29.6487H42.3033L44.5941 33.1115L46.9013 29.6487H48.3845L45.5499 33.8379L48.3845 38.0271H46.9013L44.5941 34.5642L42.3033 38.0271H40.8037Z"
      class="fill-secondary"
    />
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
  </svg>
</template>
