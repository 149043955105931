import Sentry from "@/plugins/sentry";
import { Logger } from "@/utils/logger";
import errorParser from "@/helpers/errorParser";
import httpService from "./httpService";

const { SentryKeys, SentryLevels } = Sentry;

const ENDPOINTS = {
  search_funnel: "/funnel",
  funnel: (targetUid) => `/funnel/${targetUid}`,
};

async function fetchParams(purchaseUid, shareUid, paymentFunnelUid, orderUid) {
  try {
    const url = ENDPOINTS.search_funnel;
    const params = {
      purchase_uid: purchaseUid,
      share_uid: shareUid,
      payment_funnel_uid: paymentFunnelUid,
      order_uid: orderUid,
    };

    return await httpService.get(url, params);
  } catch (apiResponseError) {
    return { data: {} };
  }
}

async function createParams(targetUid, params) {
  const url = ENDPOINTS.funnel(targetUid);
  try {
    return await httpService.post(url, null, {
      params,
    });
  } catch (apiResponseError) {
    Logger.error({
      id: "funnel-post-error",
      targetUid,
      params,
      error: apiResponseError,
    });

    Sentry.captureMessage(
      SentryKeys.FUNNEL_API_ERROR,
      SentryLevels.INFO,
      {
        id: "funnel_post",
        ...errorParser(apiResponseError),
        targetUid,
      },
      params
    );
    return undefined;
  }
}

async function updateParams(targetUid, params = {}) {
  try {
    const url = ENDPOINTS.funnel(targetUid);
    return await httpService.patch(url, null, { params });
  } catch (apiResponseError) {
    Logger.error({
      id: "funnel-patch-error",
      targetUid,
      params,
      error: apiResponseError,
    });

    Sentry.captureMessage(
      SentryKeys.FUNNEL_API_ERROR,
      SentryLevels.INFO,
      {
        id: "funnel_patch",
        ...errorParser(apiResponseError),
        targetUid,
      },
      params
    );
    return undefined;
  }
}

const FunnelService = {
  fetchParams,
  updateParams,
  createParams,
};

export default FunnelService;
