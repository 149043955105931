import { computed } from "vue";
import { useStore as vuexStore } from "vuex";

/* From https://github.com/vuejs/vuex/blob/main/src/helpers.js */
/**
 * Return a function expect two param contains namespace and map. it will normalize the namespace and then the param's function will handle the new namespace and the map.
 * @param {Function} fn
 * @return {Function}
 */
function normalizeNamespace(fn) {
  return (namespace_, map_) => {
    let namespace = namespace_;
    let map = map_;
    if (typeof namespace_ !== "string") {
      map = namespace_;
      namespace = "";
    } else if (namespace_.charAt(namespace_.length - 1) !== "/") {
      namespace += "/";
    }
    return fn(namespace, map);
  };
}

const _useActions = (store, namespace, actions) => {
  const res = {};
  actions.forEach((action) => {
    let path = action;
    if (namespace) {
      if (!store._modulesNamespaceMap[namespace]) {
        throw new Error(`Namespace ${namespace} does not exist`);
      } else {
        path = `${namespace}${action}`;
      }
    }
    res[action] = (args) => store.dispatch(path, args);
  });
  return res;
};

const _useGetters = (store, namespace, getters) => {
  const res = {};
  getters.forEach((getter) => {
    res[getter] = computed(() => {
      if (namespace) {
        if (!store._modulesNamespaceMap[namespace]) {
          throw new Error(`Namespace ${namespace} does not exist`);
        }
        return store.getters[`${namespace}${getter}`];
      }
      return store.getters[getter];
    });
  });
  return res;
};

export const useStore = () => {
  const store = vuexStore();

  const useGetters = normalizeNamespace((namespace, getters) => {
    return _useGetters(store, namespace, getters);
  });

  const useActions = normalizeNamespace((namespace, actions) => {
    return _useActions(store, namespace, actions);
  });

  return {
    store,
    useGetters,
    useActions,
  };
};

export default useStore;
