<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.9167V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M35.8661 38.1332C35.108 38.1332 34.4268 37.9993 33.8226 37.7315C33.2183 37.453 32.7404 37.0727 32.3888 36.5907C32.0482 36.098 31.8779 35.5302 31.8779 34.8875C31.8779 34.2019 32.0812 33.586 32.4877 33.0397C32.9052 32.4934 33.4545 32.1292 34.1357 31.9471C33.5094 31.7435 33.026 31.4168 32.6854 30.9669C32.3449 30.517 32.1746 30.0242 32.1746 29.4886C32.1746 28.9423 32.3229 28.4442 32.6195 27.9943C32.9162 27.5337 33.3392 27.1695 33.8885 26.9017C34.4378 26.6339 35.097 26.5 35.8661 26.5C36.6352 26.5 37.2944 26.6339 37.8437 26.9017C38.393 27.1695 38.816 27.5337 39.1126 27.9943C39.4093 28.4442 39.5576 28.9423 39.5576 29.4886C39.5576 30.0135 39.3873 30.5063 39.0467 30.9669C38.7061 31.4168 38.2227 31.7435 37.5965 31.9471C38.2777 32.1292 38.8215 32.4934 39.228 33.0397C39.6455 33.586 39.8542 34.2019 39.8542 34.8875C39.8542 35.5302 39.6785 36.098 39.3269 36.5907C38.9863 37.0727 38.5139 37.453 37.9096 37.7315C37.3053 37.9993 36.6242 38.1332 35.8661 38.1332ZM35.8661 31.465C36.5692 31.465 37.1241 31.2936 37.5306 30.9508C37.9481 30.5973 38.1568 30.1367 38.1568 29.569C38.1568 28.937 37.9536 28.4603 37.547 28.1389C37.1405 27.8176 36.5802 27.6569 35.8661 27.6569C35.152 27.6569 34.5916 27.8176 34.1851 28.1389C33.7896 28.4603 33.5918 28.937 33.5918 29.569C33.5918 30.1367 33.7951 30.5973 34.2016 30.9508C34.6081 31.2936 35.1629 31.465 35.8661 31.465ZM35.8661 36.9442C36.734 36.9442 37.3822 36.7514 37.8107 36.3658C38.2502 35.9694 38.4699 35.4499 38.4699 34.8072C38.4699 34.0787 38.2337 33.5271 37.7613 33.1522C37.2889 32.7772 36.6571 32.5898 35.8661 32.5898C35.075 32.5898 34.4433 32.7772 33.9709 33.1522C33.4985 33.5271 33.2622 34.0787 33.2622 34.8072C33.2622 35.4499 33.4765 35.9694 33.905 36.3658C34.3444 36.7514 34.9981 36.9442 35.8661 36.9442Z"
      class="fill-secondary"
    />
    <path
      d="M41.2114 37.9404L44.046 33.9556L41.2114 29.9707H42.7111L45.0018 33.2646L47.309 29.9707H48.7922L45.9576 33.9556L48.7922 37.9404H47.309L45.0018 34.6465L42.7111 37.9404H41.2114Z"
      class="fill-secondary"
    />
  </svg>
</template>
