import _merge from "lodash.merge";
import { normalize } from "normalizr";
import { purchaseSchema } from "@/store/schemas.js";
import * as types from "@/store/mutation-types";
import { SHARE, FUNNEL, ACCOUNT } from "@/store/namespaces";
import { formatDate } from "@/helpers/date";
import { SCORING_OR_FILTERS_KO_STATES } from "@/constants";
import MerchantService from "../../api/merchantService";
import PurchaseService from "../../api/purchaseService";

// Initial state
const state = {
  all: {},
};

// Getters
const getters = {
  allPurchases: (purchaseState) => purchaseState.all,
  purchase: (purchaseState, purchaseGetters, rootState, rootGetters) => {
    const purchase = purchaseGetters.allPurchases[rootGetters.rawPurchaseUid];
    if (purchase && purchase.deposit_amount_cents) {
      purchase.amount_cents = purchase.deposit_amount_cents;
    }
    return purchase;
  },
  purchaseLanguage: (purchaseState, purchaseGetters) =>
    purchaseGetters.purchase?.language,
  purchaseUid: (purchaseState, purchaseGetters) => purchaseGetters.purchase?.uid,
  penaltiesPercentage: (purchaseState, purchaseGetters) =>
    purchaseGetters.purchase
      ? purchaseGetters.purchase.penalties_percentage
      : state.rawPenaltiesPercentage,
  title: (purchaseState, purchaseGetters, rootState, rootGetters) => {
    // For the pledger, the title and the subtitle comes from the purchase returned by the back
    // For the leader, they come from the URL parameters
    return purchaseGetters.purchase
      ? purchaseGetters.purchase.title
      : rootGetters.rawTitle;
  },
  subtitle: (purchaseState, purchaseGetters) => purchaseGetters.purchase?.subtitle,
  purchaseCountry: (purchaseState, purchaseGetters, _, rootGetters) =>
    rootGetters.rawAddress?.country || purchaseGetters.purchase?.address_country,
  triggerable_capture: (purchaseState, purchaseGetters) =>
    purchaseGetters.purchase?.triggerable_capture,
  mustSignContract: (purchaseState, purchaseGetters) =>
    purchaseGetters.purchase?.must_sign_contract,
  purchaseIsScoringKOState: (purchaseState, purchaseGetters) =>
    SCORING_OR_FILTERS_KO_STATES.includes(purchaseGetters.purchase?.state),
  purchaseAddress: (purchaseState, purchaseGetters) => {
    const { purchase } = purchaseGetters;
    if (!purchase) return undefined;
    return {
      street: purchase.address_street,
      zipcode: purchase.address_zipcode,
      city: purchase.address_city,
      country: purchase.address_country,
    };
  },
  fullAddressAvailable: (purchaseState, purchaseGetters) => {
    const { purchaseAddress } = purchaseGetters;
    return !!(
      purchaseAddress?.street &&
      purchaseAddress?.zipcode &&
      purchaseAddress?.city &&
      purchaseAddress?.country
    );
  },
  is_eligible_for_marketing_decision: (purchaseState, purchaseGetters) =>
    purchaseGetters.purchase?.is_eligible_for_marketing_decision,
};

// Actions
const actions = {
  async createPurchaseAction(
    { commit, dispatch, rootGetters },
    { merchantUid, payment_parameters }
  ) {
    const { data } = await MerchantService.createPurchase(
      merchantUid,
      { iframe_id: rootGetters.rawIframeId, signature: rootGetters.rawSignature },
      payment_parameters
    );
    const { entities, result } = normalize(data, purchaseSchema);
    commit(types.PURCHASE_POST_SUCCESS, { entities, result });

    /**
     * On purchase creation, save all funnel input data
     * on server side. Fetch them in case of reload.
     * Here 'result' corresponds to purchase_uid
     * */
    dispatch(`${FUNNEL}/createFunnelAction`, { targetUid: result }, { root: true });

    return { entities, result };
  },
  async patchPurchaseAction({ commit }, { purchaseUid, formData }) {
    const {
      birthCity,
      birthZipcode,
      birthCountry,
      birthDate,
      birthLastName,
      civility,
    } = formData;
    const { data } = await PurchaseService.updateMissingInfos(purchaseUid, {
      birth_city: birthCity,
      birth_zipcode: birthZipcode,
      birth_country: birthCountry,
      birth_date: formatDate(birthDate),
      birth_last_name: birthLastName,
      civility,
    });

    const { entities, result } = normalize(data, purchaseSchema);
    const share_uids = entities.purchases[result].shares;
    const account_uid = entities.shares[share_uids[0]].account;
    const account_data = entities.accounts[account_uid];
    commit(
      `${ACCOUNT}/${types.SET_ACCOUNT}`,
      { account: account_data },
      { root: true }
    );
  },
  async fetchPurchaseAction(
    { commit },
    { purchaseUid, isDashboardBuyer = false, isPaymentMethodUpdate = false }
  ) {
    const { data } = await PurchaseService.fetchPurchase(purchaseUid, {
      isDashboardBuyer,
      isPaymentMethodUpdate,
    });
    const { entities, result } = normalize(data, purchaseSchema);
    commit(types.PURCHASE_GET_SUCCESS, { entities, result });
    commit(
      `${SHARE}/${types.PURCHASE_GET_SUCCESS}`,
      { entities, result },
      { root: true }
    );
    commit(types.APP_SET_PURCHASE_UID, { purchaseUid }, { root: true });

    data.shares.forEach((item) => {
      const { share_type, amount_cents, uid, account } = item;
      const { email, phone_number } = account;

      const share = {
        share_type,
        amount_cents,
        uid,
        forced_amount: true,
        account: {
          email,
          phone_number,
        },
      };
      commit(types.APP_ADD_SHARE, { share }, { root: true });
    });
  },
};

// Mutations
const mutations = {
  [types.PURCHASE_POST_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.purchases);
  },

  [types.PURCHASE_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.purchases);
  },

  [types.PURCHASE_POST_SHARES_CREATION](state, { entities, result }) {
    const { purchase } = entities.shares[result[0]];
    state.all = _merge({}, state.all, { [purchase.uid]: purchase });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
