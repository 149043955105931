<script>
export default {
  props: {},
};
</script>
<template>
  <svg width="50px" height="50px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.70833 23.5833V24.25H20.7917V23.5833H9.70833Z" class="fill-gray-900" />
    <path
      d="M29.0417 35.9167C29.4099 35.9167 29.7083 35.6182 29.7083 35.25C29.7083 34.8818 29.4099 34.5833 29.0417 34.5833V35.9167ZM42.8333 26.2083C42.8333 26.5765 43.1317 26.875 43.5 26.875C43.8683 26.875 44.1667 26.5765 44.1667 26.2083H42.8333ZM9.70833 24.25H9.04167V24.9167H9.70833V24.25ZM9.70833 23.5833V22.9167H9.04167V23.5833H9.70833ZM20.7917 23.5833H21.4583V22.9167H20.7917V23.5833ZM20.7917 24.25V24.9167H21.4583V24.25H20.7917ZM6.33333 32.9167V14H5V32.9167H6.33333ZM8 12.3333H41.1667V11H8V12.3333ZM29.0417 34.5833H8V35.9167H29.0417V34.5833ZM42.8333 14V26.2083H44.1667V14H42.8333ZM41.1667 12.3333C42.0871 12.3333 42.8333 13.0795 42.8333 14H44.1667C44.1667 12.3432 42.8233 11 41.1667 11V12.3333ZM6.33333 14C6.33333 13.0795 7.07954 12.3333 8 12.3333V11C6.34312 11 5 12.3432 5 14H6.33333ZM5 32.9167C5 34.5735 6.34312 35.9167 8 35.9167V34.5833C7.07954 34.5833 6.33333 33.8371 6.33333 32.9167H5ZM6.33333 17.1037H42.8333V15.3542H6.33333V17.1037ZM42.8333 17.1037H6.33333V19H42.8333V17.1037ZM9.70833 24.25V23.5833H9.04167V24.25H9.70833ZM9.70833 24.25H20.7917V22.9167H9.70833V24.25ZM20.7917 23.5833V24.25H21.4583V23.5833H20.7917ZM20.7917 23.5833H9.70833V24.25V24.9167H20.7917V23.5833Z"
      class="fill-gray-900"
    />
    <path
      d="M31.123 38.0107V36.9823C31.9356 36.3315 32.7024 35.675 33.4234 35.0126C34.1558 34.3386 34.8024 33.6763 35.3632 33.0255C35.9354 32.3748 36.3818 31.7356 36.7022 31.1081C37.0341 30.4806 37.2 29.8763 37.2 29.2953C37.2 28.8654 37.1257 28.4645 36.9769 28.0926C36.8395 27.7207 36.6107 27.4244 36.2902 27.2036C35.9698 26.9712 35.5349 26.855 34.9855 26.855C34.4591 26.855 34.0185 26.977 33.6637 27.2211C33.3089 27.4535 33.04 27.7672 32.8569 28.1623C32.6852 28.5574 32.5994 28.9816 32.5994 29.4348H31.2089C31.2089 28.633 31.3748 27.9473 31.7067 27.3779C32.0386 26.7969 32.4907 26.3553 33.0629 26.0532C33.6351 25.7511 34.2817 25.6 35.0027 25.6C36.067 25.6 36.9368 25.9079 37.612 26.5238C38.2987 27.1281 38.642 28.0345 38.642 29.243C38.642 29.9635 38.4647 30.6782 38.1099 31.387C37.7551 32.0842 37.303 32.764 36.7537 33.4264C36.2044 34.0772 35.615 34.6872 34.9855 35.2566C34.3675 35.8261 33.7896 36.3374 33.2517 36.7906H39.054V38.0107H31.123Z"
      class="fill-secondary"
    />
    <path
      d="M40.2419 38.0107L43.1946 33.6879L40.2419 29.365H41.8041L44.1902 32.9384L46.5936 29.365H48.1386L45.1859 33.6879L48.1386 38.0107H46.5936L44.1902 34.4374L41.8041 38.0107H40.2419Z"
      class="fill-secondary"
    />
  </svg>
</template>
